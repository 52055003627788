import React, { useState, useEffect } from 'react'
import { HiPlus } from 'react-icons/hi'
import { RiDeleteBinLine } from 'react-icons/ri'
import { useDialogContext } from 'contexts/DialogProvider'
import { RxCrossCircled } from 'react-icons/rx'
import TooltipHorizon from '../../../../components/tooltip'
import Card from 'components/card'
import appConstants from '../../../../common/config/appConstants'

function SearchTableOrders(props) {
  const { configData, loading } = props
  const [inputFields, setInputFields] = useState([{ key: '', value: '' }])
  const { LABEL } = appConstants
  const [validity, setValidity] = useState([{ key: false, value: false }])
  const [hasChanges, setHasChanges] = useState(false)
  const { openDialog } = useDialogContext()
  const configMapSkeletons = Array.from({ length: 3 })

  const handleInputChange = (index, event) => {
    const values = [...inputFields]
    const isValid = event.target.value.trim() !== ''
    if (event.target.name === 'key') {
      values[index] = { ...values[index], key: event.target.value }
    } else {
      values[index] = { ...values[index], value: event.target.value }
    }
    setInputFields(values)

    const validities = [...validity]
    validities[index] = {
      key: event.target.name === 'key' ? isValid : validities[index].key,
      value: event.target.name === 'value' ? isValid : validities[index].value,
    }
    setValidity(validities)
    setHasChanges(true)
  }

  useEffect(() => {}, [hasChanges])

  const handleDeleteField = () => {
    const values = [...inputFields]
    setInputFields(values)
    setHasChanges(true)
  }

  const handleClearKeyField = (index) => {
    const values = [...inputFields]
    values[index] = { ...values[index], key: '' }
    setInputFields(values)

    const validities = [...validity]
    validities[index] = { ...validities[index], key: false }
    setValidity(validities)
  }

  const handleClearValueField = (index) => {
    const values = [...inputFields]
    values[index] = { ...values[index], value: '' }
    setInputFields(values)

    const validities = [...validity]
    validities[index] = { ...validities[index], value: false }
    setValidity(validities)
  }

  const handleAddFields = () => {
    setInputFields([...inputFields, { key: '', value: '' }])
    setValidity([...validity, { key: false, value: false }])
  }

  useEffect(() => {
    if (configData) {
      const initialInputFields = configData.map(({ key, value }) => ({
        key,
        value,
      }))
      setInputFields(initialInputFields)
      setValidity(initialInputFields.map(() => ({ key: true, value: true })))
    } else {
      setInputFields([{ key: '', value: '' }])
      setValidity([{ key: false, value: false }])
    }
  }, [configData])

  const handleSaveChanges = () => {
    openDialog(
      <h2 className="text-3xl font-bold text-navy-700 dark:text-white">
        {LABEL.GENERIC_MODAL_TITLE}
      </h2>,
      <div className="text-lg">
        <div className="mb-4 border-b border-gray-200 dark:border-[#6a7489]"></div>
        <p>{LABEL.GENERIC_MODAL_MESSAGE}</p>
      </div>,
      {},
      'Save',
      'Cancel'
    )
  }

  return (
    <>
      {!loading ? (
        <Card extra={'h-full mx-auto pb-3'}>
          <div className="h-full w-full p-2 md:p-4">
            <div className="mt-[1%] flex items-center justify-end md:ml-4">
              <select
                className="text-lightFourth w-30 border-gray mr-4 rounded-xl border-2 border-solid p-1.5 pr-4 text-sm dark:bg-navy-800 dark:text-white"
                name=""
                id=""
                placeholderText="Select Repository"
              ></select>
            </div>

            <div className="my-1">
              <div className="mb-2 flex gap-4">
                <div className="ml-2 w-[47%] text-start font-medium">Key</div>
                <div className="ml-2 w-[47%] text-start font-medium">Value</div>
              </div>
              {inputFields.map((inputField, index) => (
                <div key={index} className="relative mb-4 flex gap-4">
                  <div className="relative flex w-1/2 flex-col">
                    <div className="relative flex w-full items-center gap-3 rounded-2xl border border-gray-200 pl-[8px] outline-none dark:!border-white/10 dark:!bg-navy-800">
                      <input
                        type="text"
                        className="placeholder:text-lightFourth md:text-md h-full w-full rounded-2xl p-3 text-sm text-navy-700 outline-none dark:!bg-navy-800 dark:text-white"
                        placeholder="Enter NAME_OF_VARIABLE"
                        name="key"
                        value={inputField.key}
                        autoComplete="off"
                        onChange={(event) => handleInputChange(index, event)}
                      />
                      {inputField.key && (
                        <button
                          className="absolute right-2 top-2 rounded-full p-1 text-gray-500 hover:text-gray-700"
                          type="button"
                          onClick={() => handleClearKeyField(index)}
                          disabled={!validity[index]?.key}
                        >
                          <RxCrossCircled />
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="relative flex w-1/2 flex-col">
                    <div className="relative flex w-full items-center gap-3 rounded-2xl border border-gray-200 pl-[8px] outline-none dark:!border-white/10 dark:!bg-navy-800">
                      <input
                        type="password"
                        className="placeholder:text-lightFourth md:text-md h-full w-full rounded-2xl p-3 text-sm text-navy-700 outline-none dark:!bg-navy-800 dark:text-white"
                        placeholder="Enter Value"
                        name="value"
                        value={inputField.value}
                        title={inputField.value}
                        autoComplete="off"
                        onMouseEnter={(e) => (e.target.type = 'text')}
                        onMouseLeave={(e) => (e.target.type = 'password')}
                        onChange={(event) => handleInputChange(index, event)}
                      />
                      {inputField.value && (
                        <button
                          className="absolute right-2 top-2 rounded-full p-1 text-gray-500 hover:text-gray-700"
                          type="button"
                          onClick={() => handleClearValueField(index)}
                          disabled={!validity[index]?.value}
                        >
                          <RxCrossCircled />
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center">
                    {inputFields?.length > 1 && (
                      <button
                        type="button"
                        onClick={() => handleDeleteField(index)}
                      >
                        <TooltipHorizon
                          extra="border border-gray-200 dark:border-gray-700"
                          trigger={
                            <p className="flex cursor-pointer items-center rounded-full border p-2 font-bold text-[#000] hover:font-medium hover:text-black dark:text-white">
                              <span>
                                <RiDeleteBinLine className="text-xl" />
                              </span>
                            </p>
                          }
                          content="Delete"
                          placement="top"
                        />
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div className="flex justify-end gap-4 pt-5">
              <button
                className="type-interface-01 text-default focus-visible:outline-focus-action flex h-10 cursor-pointer items-center rounded-xl border border-solid border-gray-800 px-3 py-2.5 outline-2 outline-offset-2 hover:bg-brand-500 hover:text-white focus-visible:outline active:bg-gray-100 dark:bg-gray-900 dark:text-white"
                type="button"
                onClick={handleAddFields}
              >
                <HiPlus className="text-xl" />
                <span>Add Row</span>
              </button>
              <button
                className="type-interface-01 text-default focus-visible:outline-focus-action flex h-10 cursor-pointer items-center rounded-xl border border-solid border-gray-800 px-3 py-2.5 outline-2 outline-offset-2 hover:bg-brand-500 hover:text-white focus-visible:outline active:bg-gray-100 dark:bg-gray-900 dark:text-white"
                type="button"
                onClick={handleSaveChanges}
                disabled={
                  !hasChanges ||
                  !inputFields.every((field) => field.key && field.value)
                }
              >
                <span>Save Changes</span>
              </button>
            </div>
          </div>
        </Card>
      ) : (
        <Card extra={'h-full mx-auto pb-3'}>
          <div className="h-full w-full p-2 md:p-4">
            <div className="grid gap-4">
              {configMapSkeletons.map((_, index) => (
                <Card
                  key={index}
                  extra="max-w-full !p-3 border border-gray-200 dark:border-gray-700"
                >
                  <div className="flex items-center justify-between">
                    <div className="skeleton-box !h-5 w-1/4 !rounded-lg"></div>
                  </div>
                  <div className="mt-4 w-full">
                    <div className="skeleton-box !h-12 w-full !rounded-lg"></div>
                  </div>
                </Card>
              ))}
            </div>
          </div>
        </Card>
      )}
    </>
  )
}

export default SearchTableOrders
