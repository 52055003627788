import Card from 'components/card/Card'
import StaticSites from 'assets/img/users/static-sites.png'
import WebServices from 'assets/img/users/web-services.png'
import BackgroundWorkers from 'assets/img/users/background-workers.png'
import PrivateServices from 'assets/img/users/private-services.png'
import Cron from 'assets/img/users/cron.png'
import PostgreSQL from 'assets/img/users/postgre-sql.png'
import Redis from 'assets/img/users/redis.png'
import Blueprints from 'assets/img/users/blue-prints.png'
import { useLocation, useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import appConstants from 'common/config/appConstants'

const Cardplace = () => {
  const location = useLocation()
  const [queryParam, setQueryParam] = useState('')
  const { id } = useParams()
  const { BUTTON_TEXT } = appConstants
  const isPrivateServiceActive = appConstants.PRIVATE_SERVICE_ACTIVE
  const isBackgroundWorkersActive = appConstants.BACKGROUND_WORKERS_ACTIVE
  const isBlueprintsActive = appConstants.BLUEPRINTS_ACTIVE
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    setQueryParam(searchParams.get('createService'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="xxl:grid-cols-3 grid h-full grid-cols-1 gap-5 xl:grid-cols-2">
      <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2">
        <div className="mb-4 mt-2 flex flex-col justify-between md:flex-row md:items-center">
          <h4 className="text-2xl font-bold text-navy-700 dark:text-white">
            {queryParam
              ? 'Create Services in minutes '
              : 'Get started in minutes'}
          </h4>
        </div>
        <div className="z-20 grid grid-cols-1 gap-5 md:grid-cols-3">
          <Card
            title="Web Services"
            author="include zero-downtime deploys, persistent storage and PR previews. Scale up and down with ease."
            page="Web Services"
            buttonText={BUTTON_TEXT.NEW_WEB_SERVICES}
            routePath={`/new-service/${id}/webApp`}
            image={WebServices}
          />
          <Card
            title="Static Sites"
            author="are automatically served over a global CDN. Add a custom domain and get free, fully-managed SSL"
            page="Static Sites"
            buttonText={BUTTON_TEXT.NEW_STATIC_SITES}
            routePath={`/new-service/${id}/frontend`}
            image={StaticSites}
          />
          {isPrivateServiceActive === 'true' && (
            <Card
              title="Private Services"
              author="are only accessible within your Render network and can speak any protocol."
              page="Private Services"
              buttonText={BUTTON_TEXT.NEW_PRIVATE_SERVICES}
              image={PrivateServices}
            />
          )}
          {isBackgroundWorkersActive === 'true' && (
            <Card
              title="Background Workers"
              author="suitable for long running processes like consumers for queues and streaming."
              page="Background Workers"
              buttonText={BUTTON_TEXT.NEW_BACKGROUND_WORKERS}
              image={BackgroundWorkers}
            />
          )}
          <Card
            title="Cron Jobs"
            author="you can schedule any command or script to run on a regular interval."
            page="Cron Jobs"
            buttonText={BUTTON_TEXT.NEW_CRON_JOBS}
            routePath={`/new-service/${id}/cron`}
            image={Cron}
          />
          <Card
            title="PostgreSQL"
            author="Fully-managed hostel with internal and external connectivity, and automated daily backups."
            page="PostgreSQL"
            buttonText={BUTTON_TEXT.NEW_POSTGRE_SQL}
            routePath={`/deploying-service/${id}/postgresql`}
            image={PostgreSQL}
          />
          <Card
            title="Redis"
            author="A cloud based in-memory key value datastore. Render offers fully managed hosted Redis instances."
            page="Redis"
            buttonText={BUTTON_TEXT.NEW_REDIS}
            routePath={`/deploying-service/${id}/redis`}
            image={Redis}
          />
          {isBlueprintsActive === 'true' && (
            <Card
              title="Blueprints"
              author="specifies your Infrastructure as Code in a single file. Use it to set up all your services at once."
              page="Blueprints"
              buttonText={BUTTON_TEXT.NEW_BLUE_PRINTS}
              // routePath={`/deploying-service/${id}/blueprints`}
              image={Blueprints}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Cardplace
