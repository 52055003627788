import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import UptimeKumaLogoSvg from 'assets/svg/UptimeKumaLogoSvg'
import { FiSearch } from 'react-icons/fi'
import debounce from 'lodash.debounce'

const ListProjects = ({ statusData }) => {
  const navigate = useNavigate()

  const [filter, setFilter] = useState('')
  const [orgStatusData, setOrgStatusData] = useState(statusData)
  const [filteredStatusData, setFilteredStatusData] = useState([])

  const debouncedFetch = debounce((value) => {
    const lowercasedValue = value.toLowerCase().trim()

    // Filter orgStatusData based on the search value (case insensitive)
    const filtered = orgStatusData.filter(
      (item) =>
        item.slug.toLowerCase().includes(lowercasedValue) ||
        item.title.toLowerCase().includes(lowercasedValue)
    )

    setFilteredStatusData(filtered)
  }, 500)

  // Effect to trigger debounced fetch whenever filter changes
  useEffect(() => {
    debouncedFetch(filter)

    // Cleanup debounce on unmount
    return () => {
      debouncedFetch.cancel()
    }
  }, [filter])

  useEffect(() => {
    setFilteredStatusData(orgStatusData)
  }, [orgStatusData])

  return (
    <div>
      <div className="flex h-full w-full flex-col items-center justify-between !pt-0 md:flex-row md:py-2">
        {/* Search input */}
        <div className="my-3 flex w-full items-center rounded-full bg-white p-[5px] shadow-2xl shadow-white dark:!bg-navy-800 dark:shadow-none md:mb-0">
          <div className="flex h-9 w-full flex-grow items-center rounded-full border-white bg-lightPrimary text-sm text-gray-600 dark:border dark:!bg-navy-900">
            <FiSearch className="mx-2 h-6 w-4 !text-gray-700 dark:!text-white" />
            <input
              type="text"
              placeholder="Search by Title or Slug"
              className="block h-full w-full rounded-full bg-lightPrimary pl-1 text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-full"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="flex w-full flex-col gap-0 hover:cursor-pointer">
        {filteredStatusData &&
          filteredStatusData?.map((project, index) => {
            return (
              <div
                key={index}
                className="flex items-center justify-start gap-4 p-4 hover:bg-gray-50 dark:hover:bg-navy-900"
                onClick={() =>
                  navigate(`/status/${project.title}/${project.slug}`)
                }
              >
                <div>
                  <UptimeKumaLogoSvg />
                </div>
                <div className="flex flex-col items-start justify-start">
                  <p className="text-lg font-bold">{project.title}</p>
                  <p className="text-base font-normal">
                    /status/{project.slug}
                  </p>
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default ListProjects
