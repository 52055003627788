const userMangementBaseUrl =
  process.env.REACT_APP_API_URL + 'confixa-api/api/management/'
const apiUrl = process.env.REACT_APP_API_URL + 'confixa-api/api/backend/'
const authAppBaseUrl = process.env.REACT_APP_API_URL + 'confixa-api/api/oauth/'
const supportTicketBaseUrl =
  process.env.REACT_APP_API_URL + 'confixa-api/api/support/'
const licenseBaseUrl =
  process.env.REACT_APP_LICENSE_API_URL + 'license-api/api/'
const thirdPartyAuthAppBaseUrl =
  process.env.REACT_APP_THIRD_PARTY_AUTH_APP_URL + 'oauth-api/api/'

const config = {
  // Application
  GET_ALL_APPLICATION: apiUrl + 'Application/getAll',
  DELETE_APPLICATION: apiUrl + 'Application/delete',

  // Project
  CREATE_PROJECT: apiUrl + 'Project/create',
  GET_ALL_PROJECTS: apiUrl + 'Project/getAllProjects',
  GET_PROJECT_DETAILS: apiUrl + 'Project/get/:id',
  EDIT_PROJECT: apiUrl + 'Project/edit/:id',

  //Create A New Web Services
  GET_PUBLIC_GIT_REPOSITORY: apiUrl + 'Repository/getAll',
  POST_PUBLIC_REPOSITORY: apiUrl + 'Repository/getPublicRepo',

  //GET ALL BRANCHES
  GET_ALL_BRANCHES:
    apiUrl + 'Branch/getAll/:organization_id/:owner_name/:repo_name',

  //DEPLOYING WEB SERVICE
  CREATE_NEW_SERVICE: apiUrl + 'Application/create',

  //WebServices
  WORK_FLOWS_GET_EVENTS:
    apiUrl + 'Workflow/getEvents?applicationId=:application_id&page=:page',
  GET_WORK_FLOWS_RUNS:
    apiUrl + 'Workflow/getRun/:application_id/:workflow_id/:page',
  GET_WORK_FLOWS_JOBS:
    apiUrl + 'Workflow/getJob/:application_id/:workflow_runs_id',

  //SETTINGS/GIT
  VERIFY_GIT_TOKEN: apiUrl + 'Token/verify/:organization_id',
  //Get Git TOken
  GET_GIT_TOKEN: apiUrl + 'Token/get/:organization_id',

  //SETTINGS/ARGOCD
  ARGO_CD: userMangementBaseUrl + 'argocd/verifyargocd',
  GET_ARGO_CD_TOKEN: userMangementBaseUrl + 'argocd/getArgocdToken',

  //SETTINGS/ArtiFactRepository
  ARTIFACT_REPOSITORY: apiUrl + 'Artifact/save/:organization_id',
  GET_ARTIFACT_DATA: apiUrl + 'Artifact/get/:organization_id',
  UPDATE_ARTIFACT_FORM: apiUrl + 'Artifact/update/:organization_id',
  DOWNLOAD_ARTIFACT_DATA: apiUrl + 'Artifact/download/:organization_id',

  //SETTINGS/Elastic
  CREATE_ELASTIC: apiUrl + 'Elastic/create',
  GET_ELASTIC_DATA: apiUrl + 'Elastic/get',
  UPDATE_ELASTIC: apiUrl + 'Elastic/update/:elasticId',

  //SETTINGS/Monitoring
  CREATE_MONITORING: apiUrl + 'Monitoring/create',
  GET_MONITORING_DATA: apiUrl + 'Monitoring/get',
  UPDATE_MONITORING: apiUrl + 'Monitoring/update/:monitoringId',

  //Sign In
  CODE_SIGN_IN: userMangementBaseUrl + 'keycloak/getKeycloakToken',
  //get User details
  GET_USER_INFO: userMangementBaseUrl + 'keycloak/userInfo',

  //Logout api
  LOGOUT_USER: userMangementBaseUrl + 'keycloak/logout',

  //Observability Tool AI Assistant
  // BotData
  CHAT_DATA: apiUrl + 'Chat/ask',

  //Previously Data
  // BotData
  PREVIOUSLY_DATA: apiUrl + 'Chat/getTopic',
  PREVIOUSLY_INSIDE_DATA: apiUrl + 'Chat/getMessage/:topic_id',
  DELETE_DATA: apiUrl + 'Chat/delete/:topic_id',

  //Check If Repo name is already taken
  CHECK_REPO: apiUrl + 'Organization/checkRepoName',

  //Create Organization
  SET_ORGANIZATION: apiUrl + 'Organization/create',

  //Edit Organization
  EDIT_ORGANIZATION: apiUrl + 'Organization/edit/:organizationId',

  //Get ENV Organization
  ORGANIZATION_ENV: apiUrl + 'Organization/getEnv',

  //Get Organization Details
  GET_ORGANIZATION_DETAILS: apiUrl + 'Organization/get',

  GET_PROJECTS_DATA: apiUrl + 'Project/checkName',

  GET_ORGANIZATION_DATA: apiUrl + 'Organization/checkName',
  //Get Application checkName
  GET_APPLICATION_CHECKNAME: apiUrl + 'Application/checkName',

  //config_table_api
  GET_CONFIG_TABLE_DATA:
    apiUrl + 'Application/getConfigMap/:application_id/:branch_id',
  UPDATE_CONFIG_TABLE_DATA:
    apiUrl + 'Application/updateConfigMap/:application_id/:branch_id',
  DELETE_CONFIG_TABLE_DATA:
    apiUrl +
    'api/Application/deleteKeysFromConfigMap/:application_id/:branch_id',

  GET_SECRET_TABLE_DATA:
    apiUrl + 'Application/getSecret/:application_id/:branch_id',
  UPDATE_SECRET_TABLE_DATA:
    apiUrl + 'Application/updateSecret/:application_id/:branch_id',

  UPDATE_VALUES_TABLE_DATA:
    apiUrl + 'Project/updateValues/:project_id/:branch_id',
  GET_VALUES_TABLE_DATA: apiUrl + 'Project/getValues/:project_id/:branch_id',

  //Issue list
  ISSUES_LISTNING: apiUrl + 'Llm/getAllIssues',

  //Add Issue
  ADD_ISSUE: apiUrl + 'Llm/getPR',

  //Update Issue
  UPDATE_ISSUE: apiUrl + 'Llm/updateIssue',

  //create branch
  CREATE_BRANCH: apiUrl + 'Branch/:type/:id',

  //get all-label
  GET_ALL_LABEL: apiUrl + 'Llm/getAllLabels',

  //create label
  CREATE_LABEL: apiUrl + 'Llm/:type',

  //delete label
  DELETE_LABEL: apiUrl + 'Llm/deleteLabel',
  // Re-run jobs
  RERUN_JOBS: apiUrl + 'Workflow/rerun/:applicationId/:jobId',

  // Cancel jobs
  CANCEL_JOBS: apiUrl + 'Workflow/cancel/:applicationId/:runId',

  // DASHBOARD
  GET_K8S_METRICS: apiUrl + 'Monitoring/getMonitoringData',
  // GET All Authors
  GET_ALL_AUTHORS: apiUrl + 'Llm/getAllAuthors',

  // check Branch
  CHECK_BRANCH_NAME: apiUrl + 'Branch/checkBranchName',

  GET_ALL_BILLING:
    licenseBaseUrl + 'subscription/getOneDetail?domainName=:domain',
  SET_ALL_BILLING: licenseBaseUrl + 'subscription/checkSubscription',

  GET_DOMAINS_LIST: apiUrl + 'Application/getDomains/:applicationId',

  GET_APM_DATA: apiUrl + 'Elastic/getAPMData?start=:startTime&end=:endTime',

  GET_ALL_APPLICATION_VERSION_DATA:
    apiUrl + 'Application/getAllVersions/:serviceType',

  GET_KUBE_COST_DATA: apiUrl + 'KubeCost/getKubeCost',

  //checkDomainName
  CHECK_DOMAINNAME: apiUrl + 'Application/checkDomainName',

  UPDATE_PORT: apiUrl + 'Application/updatePort',

  GET_APPLICATION_INFO: apiUrl + 'Application/get/:applicationId',

  GET_UPTIME_KUMA_PAGES: apiUrl + 'UptimeKuma/getStatusPages',

  GET_UPTIME_KUMA_DASHBOARD: apiUrl + 'UptimeKuma/getDashboard',

  GET_UNSELECTED_ENV: apiUrl + 'Project/getUnselectedEnv/:id',

  GET_SELECTED_ENV: apiUrl + 'Project/getSelectedEnv/:projectId',

  UPDATE_DOMAIN_ARTIFACT: apiUrl + 'Application/editDomainAndArtifact',

  GITHUB_AUTH: thirdPartyAuthAppBaseUrl + 'github/githubAuth',

  BITBUCKET_AUTH: thirdPartyAuthAppBaseUrl + 'bitbucket/bitbucketAuth',

  SUPPORT_TICKET_LIST: supportTicketBaseUrl + 'ticket/list',

  SUPPORT_TICKET_DETAILS: supportTicketBaseUrl + 'ticket/get/:ticketId',

  SUPPORT_TICKET_UPDATE: supportTicketBaseUrl + 'ticket/update/:ticketId',

  SUPPORT_TICKET_FILTER: supportTicketBaseUrl + 'ticket/filter',

  SUPPORT_TICKET_CONVERSATION:
    supportTicketBaseUrl + 'conversation/get/:ticketId',

  SUPPORT_CREATE_CONVERSATION: supportTicketBaseUrl + 'conversation/create',

  SUPPORT_CREATE_TICKET: supportTicketBaseUrl + 'ticket/create',

  // Authentication
  SIGN_IN_USER: userMangementBaseUrl + 'auth/login',

  SIGN_UP_USER: userMangementBaseUrl + 'auth/signup',

  SIGN_IN_USER_VIA_GOOGLE: userMangementBaseUrl + 'auth/google',

  FORGOT_PASSWORD: userMangementBaseUrl + 'auth/forgotPassword',

  VERIFY_PASSWORD_RESET_TOKEN: userMangementBaseUrl + 'auth/verifyResetToken',

  SEND_FORGET_MAIL: userMangementBaseUrl + 'auth/sendForgetMail',

  GET_AUTH_TOKEN: userMangementBaseUrl + 'auth/getToken/:verificationId',

  RESET_PASSWORD: userMangementBaseUrl + 'auth/resetPassword',

  // User info and actions
  USER_INFO: userMangementBaseUrl + 'users/userInfo',

  GET_ALL_USERS: userMangementBaseUrl + 'users/getAll/:page?term=:term',

  GET_USER_BY_ID: userMangementBaseUrl + 'users/get/:userId',

  DELETE_USER_BY_ID: userMangementBaseUrl + 'users/delete/:userId',

  UPDATE_USER_BY_ID: userMangementBaseUrl + 'users/update/:userId',

  CONFIXA_APP_URL: process.env.REACT_APP_CONFIXA,

  API_URL: apiUrl,

  GITHUB_OAUTH_REDIRECT_URL:
    authAppBaseUrl +
    'github/githubCallback?accessToken=:accessToken&user=:user&frontendUrl=:frontendUrl&backendUrl=:backendUrl',

  BITBUCKET_OAUTH_REDIRECT_URL:
    authAppBaseUrl +
    'bitbucket/bitbucketCallback?accessToken=:accessToken&refreshToken=:refreshToken&expiresIn=:expiresIn&user=:user&frontendUrl=:frontendUrl&backendUrl=:backendUrl',
}

export default config
