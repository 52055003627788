import defaultImage from 'assets/img/others/default-image.png'
const platformType = JSON.parse(localStorage.getItem('platformType'))
// Assuming the platformType would be either 'github' or 'bitbucket'
const platform = platformType === 'bitbucket' ? 'Bitbucket' : 'Github'

const webConstants = {
  TOAST: {
    MESSAGES: {
      ERROR: {
        fetchBranchError: 'Unable to fetch branches',
        fetchVersionError: 'Unable to fetch versions',
        fetchRepoError: 'Unable to fetch Repositories',
        cwsError: 'Web Service File already exist',
        workflowError: 'Failed to get workflow',
        getAllEventsError: 'Unable to fetch all Events',
        getDeploymentLogsError: 'Failed to get deployment logs',
        swwError: 'Something went wrong.',
        getBranchError: 'No repository selected. Aborting API call.',
        noRepoError: 'No repository selected.',
        pgrError: 'Please select any one repository',
        gitTokenError: `Invalid ${platform} token`,
        argoCdTokenError: 'Invalid argoCd token',
        artifactNotAddedError: 'No artifacts added, Please add one',
        artifactNotAddedError1: 'Please add artifact repository to proceed',
        createProjectError: 'Error occurred while creating Project',
        rerunJobsError: 'Failed to re-run job',
        cancelJobsError: 'Failed to cancel job',
        sessionExpiredError: 'Session expired, please try again',
        invalidTokenError: 'Invalid or expired token',
        resendLimitReachedError:
          'You have exceeded the maximum number of resend attempts',
        fetchTokenError: 'Error fetching token',
        userExistButNotVerifiedError: 'User exists but not verified',
        signInFailedError: 'Sign in failed or user not exists',
        passwordResetError: 'Failed to reset the password',
        samePasswordsError:
          'New password cannot be the same as the old password',
        oauthFailedError: 'Failed to authenticate user',
      },
      SUCCESS: {
        cwsSuccess: 'Web service created successfully',
        addedIssue: 'Issue added successfully',
        labelAdded: 'Label added successfully',
        updatedIssue: 'Issue updated successfully',
        artifactRepositorySuccess: 'Artifact repository created successfully',
        artifactUpdateSuccess: 'Artifact repository updated successfully',
        logoutSuccess: 'Logged out successfully',
        gitSuccess: 'Git token updated successfully',
        argoSuccess: 'ArgoCD token updated successfully',
        organizationCreated: 'Organization created successfully',
        organizationEdited: 'Organization edited successfully',
        deletedData: 'Data has been deleted successfully',
        rerunJobs: 'Job re-run successfully',
        cancelJobs: 'Job cancelled successfully',
        signInSuccess: 'Sign in successfully',
        signUpSuccess: 'Sign up successfully',
        otpVerifiedSuccess:
          'OTP verified, Please proceed to reset your password',
        otpSendSuccess: 'OTP has been sent to your email',
        userVerifySuccess: 'User verified successfully',
        passwordResetSuccess: 'Password reset successfully',
        elasticSave: 'Observability settings have been saved successfully.',
        elasticUpdate: 'Observability settings have been updated successfully.',
        monitoringSave: 'Monitoring settings have been saved successfully.',
        monitoringUpdate: 'Monitoring settings have been updated successfully.',
      },
      INFO: {
        updateMonitoringSettings:
          'No changes were made to the monitoring settings.',
        updateElasticSettings:
          'No changes were made to the observability settings.',
        editOrganization: 'No changes were made to the organization settings.',
        addBranch: 'Branch name is required.',
        openAIKeyChatbot:
          'OpenAI key is missing from the organization settings, preventing access to the chatbot.',
        openAIKeyIssues:
          'OpenAI key is missing from the organization settings, preventing issue creation on the versioning tool.',
        chatAPICall:
          'Observability settings are missing or set to false, preventing access to chatbot calls',
        applicationDataIssues:
          'Applications are not created, preventing issue creation on the versioning tool.',
      },
      WARNING: {
        openAI: 'OpenAI Key and Language Model must both be set or left empty.',
        requiredLanguageKey:
          'Language model key is required and cannot be empty.',
      },
    },
  },
  VALIDATION: {
    CHECKING_AVAILABILITY: 'Checking availability...',
    ALLOWED_KEYS_PATTERN: /^[a-zA-Z0-9-_ ]$/,
    SPACE_PATTERN: /\s+/g,
  },
  LABEL: {
    ORGANIZATION_NAME: 'Organization Name',
    USER_NAME: 'User Name',
    REPOSITORY_NAME: 'Repository Name',
    GITHUB_TOKEN: `${platform} Token`,
    GITHUB_USER: `${platform} User`,
    BRANCH_NAME: 'Branches Name',
    ENVIRONMENT_NAME: 'Environment Name',
    UPDATE_LABEL: 'Update',
    CREATE_LABEL: 'Create',
    ADD_LABEL: 'Add',
    DELETE: 'Delete',
    TITLE: 'Title',
    DESCRIPTION: 'Description',
    LABELS: 'Labels',
    NEW_LABEL: 'New Label',
    DELETE_LABEL: 'Delete Label',
    DELETE_LABEL_MESSAGE: 'Are you sure you want to delete?',
    LABEL_NAME: 'Label Name',
    COLOR: 'Color',
    DEFAULT_COLOR_TXT: 'Choose from default colors:',
    APP_NAME: 'Application Name',
    APP_NAME_SUB_LBL: 'A unique name for your service',
    BRANCH: 'Branch',
    HEAD_BRANCH: 'Head Branch',
    BRANCH_SUB_LBL: 'The Repository branch used for your service',
    PORT: 'Port',
    PORT_SUB_LBL: 'The port is allotted to every application.',
    SCHEDULE: 'Schedule',
    SCHEDULE_SUB_LBL: 'The schedule for the command as a cron expression.',
    COMMAND: 'Command',
    COMMAND_SUB_LBL: 'The command to run periodically.',
    STATUS: 'Status',
    TOTAL_DUR: 'Total duration',
    BILLABLE_TIME: 'Billable time',
    ARTIFACTS: 'Artifacts',
    KUBERNETES_CLUSTER: 'Kubernetes cluster',
    CREDENTIALS_TYPE: 'Credentials Type',
    NAME: 'Name',
    TOP_LEVEL_DOMAIN_MANAGEMENT: 'Top-level domain management',
    DNS_PROVIDER: 'DNS Provider',
    APPLICATION_KEY: 'Application Key',
    APPLICATION_SECRET: 'Application Secret',
    G_CLOUD: 'Google Cloud Platform (GCP)',
    AWS_CLOUD: 'Amazon Web Services',
    AZURE_CLOUD: 'Microsoft Azure',
    DOCKERHUB: 'Dockerhub',
    OTHER_CLOUD: 'Other',
    PROJECT_ID: 'Project ID',
    ACCESS_KEY_ID: 'Access Key ID',
    SECRET_KEY_ID: 'Secret Key ID',
    ARTIFACT_PATH: 'Artifact Path',
    GITHUB_ID: `Users ${platform} User ID`,
    REGION: 'Region',
    G_SERVICE_KEY: 'Google Service key',
    AWS_SERVICE_KEY: 'AWS Service key',
    SERVER: 'Server',
    CLUSTER_NAME: 'Cluster Name',
    DOMAIN_NAME: 'Domain Name',
    URL: 'URL',
    ARTIFACTS_REPOSITORY_NAME: 'Artifact Repository Name',
    VERSION: 'Version',
    MAPPING_HEADING: 'Mapping',
    MAPPING_SUB_LBL: 'Map your domain name with the corresponding branch.',
    MAPPING_ARTIFACT_LBL:
      'Map your domain name and artifact repository name with the corresponding branch.',
    KUBERNETES_TOKEN_LBL: 'Kubernetes Token',
    ADD_ENVIRONMENT: 'Add Environment',
    UPDATE_DOMAIN: 'Update Domain',
    ENVIRONMENT: 'Environment',
    CREATE_ENVIRONMENT: 'Create Environment',
    PROJECT_NAME: 'Project Name',
    GITHUB: 'github',
    GENERIC_MODAL_TITLE: 'Are you sure?',
    GENERIC_MODAL_MESSAGE: 'You want to do this?',
  },
  MAX_LENGTH: {
    APP_NAME: 50,
    application_maxlengthError:
      'Application name cannot be more than app_maxLength characters',
  },
  WEB_SERVICES: {
    EVENTS: 'Events',
    DEPLOYMENT_LOGS: 'Deployment Logs',
    CONFIG_MAP: 'ConfigMaps',
    SECRETS: 'Secrets',
    DOMAIN_MANAGEMENT: 'Environment Management',
  },
  Billing: {
    BILLING_DETAILS: 'Billing Information',
    BILLING_TABS_TEXT: 'Plan Details',
  },
  CONFIXA: {
    CONFIXA_HEADER: 'Confixa',
  },
  REQUIRED: {
    GIT_REQUIRED: 'Git token required',
    ARGO_CD_REQUIRED: 'ArgoCD user API token required',
    SELECT_REPOSITORY_REQUIRED: 'Please select repository',
    DOCKER_REQUIRED:
      'The Docker file is already available in this repository.If you wish to edit the file click on the cross below and replace the file.',
    branchRequired: 'At least one branch name is required',
    organizationRequired: 'Organization name is required',
    titleRequired: 'title is required',
    RepositoryRequired: 'Repository name is required',
    ENVIRONMENTS_REQUIRED: 'Environment name is required',
  },
  BUTTON_TEXT: {
    EDIT: 'Edit',
    VERIFY: 'Verify',
    RENEW: ' Renew',
    CONNECT: 'Connect',
    UPDATE: 'Update',
    DOWNLOAD: 'Download',
    REPOSITORY: 'Repository',
    CANCEL: 'Cancel',
    SAVE: 'Save',
    NEXT: 'Next',
    GITHUB: platform,
    PUBLIC: 'Public',
    PRIVATE: 'Private',
    BACK: 'Back',
    CREATE_SERVICE: 'create service',
    ADD: 'Add',
    SAVE_CHNG: 'Save Changes',
    UPDATES: 'Updates',
    RERUN_JOB: 'Re-run Job',
    CANCEL_JOB: 'Cancel Job',
    NEW_WEB_SERVICES: 'New Web Services',
    NEW_STATIC_SITES: 'New Static Sites',
    NEW_PRIVATE_SERVICES: 'New Private Services',
    NEW_BACKGROUND_WORKERS: 'New Background Workers',
    NEW_CRON_JOBS: 'New Cron Jobs',
    NEW_POSTGRE_SQL: 'New PostgreSQL',
    NEW_REDIS: 'New Redis',
    NEW_BLUE_PRINTS: 'New Blueprints',
    NEW_LABEL: 'New Label',
    SAVE_CHANGES: 'Save Changes',
    CHECK_PR: 'Check PR',
    NEW_CHAT: 'New Chat ',
    ACTIVE: 'Active',
    PREVIOUS: 'Previous',
    GITHUB_ACCOUNT: `${platform} Account`,
    ARTIFACT_REPOSITORY: 'Artifact Repository',
    COMING_SOON: 'Coming Soon',
    ORGANIZATION: 'Organization',
    USER: 'User',
    SUBMIT: 'Submit',
    RESET: 'Reset',
  },
  SIGNIN: {
    WELCOMETEXT: `Welcome to Confixa`,
    SIGNINBUTTONTEXT: 'Click here to Sign In',
    LEARNMORE: 'Learn more about Confixa on',
    DOMAIN: 'www.confixa.com',
  },
  AUTH: {
    PASSWORD_REGEX: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/,
    PASSWORD_ERROR:
      'Password must be 8+ chars with uppercase, lowercase, number, and special character',
    PASSWORD_REQUIRED_ERROR: 'Password is required',
    FORGOT_PASSWORD: 'Forgot Password?',
    RESET_PASSWORD: 'Reset Password',
    NEW_PASSWORD_REQUIRED_ERROR: 'New Password is required',
    OLD_PASSWORD_REQUIRED_ERROR: 'Old Password is required',
    CONFIRM_PASSWORD: 'Confirm Password',
    CONFIRM_PASSWORD_ERROR: 'Passwords do not match',
    EMAIL_REQUIRED_ERROR: 'Email is required',
    EMAIL_ERROR: 'Invalid email',
    EMAIL_REGEX:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    OTP_RESEND_TIMER: 180,
    FORGOT_PASSWORD_TEXT:
      "Enter your email, and we'll send you a reset token to set a new password",
    PLEASE_WAIT_TEXT: 'Please wait as we verify your details.',
    DO_NOT_NAVIGATE_TEXT: 'Do not refresh or leave this page',
    OTP_EXPIRY_TIMER: 900,
    SIGN_IN_TEXT: 'Enter your email and password to sign in!',
    SIGN_UP_TEXT: 'Enter your email and password to sign up!',
    FIRST_NAME_REQUIRED_ERROR: 'First name is required',
    LAST_NAME_REQUIRED_ERROR: 'Last name is required',
    CONFIRM_PASSWORD_REQUIRED_ERROR: 'Confirm Password is required',
    ACCEPT_TERMS_TEXT: 'You must agree to the Terms and Privacy Policy',
    CRYPTO_KEY: process.env.REACT_APP_CRYPTO_KEY,
  },
  USER_INFO: {
    UPDATE_SUCCESS: 'User updated successfully',
    UPDATE_FAILED: 'Failed to update user',
    DELETE_SUCCESS: 'User deleted successfully',
    DELETE_FAILED: 'Failed to delete user',
    DELETE_MESSAGE_FOR_MODAL: 'Are you sure you want to delete this user?',
    DELETE_TITLE_FOR_MODAL: 'Delete User',
  },
  SUBSCRIPTION: {
    PLANS: `Plan / Duration`,
    START_DATE: 'Start Date',
    END_DATE: 'End Date',
    EXPIRING: 'Expiring By',
    LICENSE_KEY: 'License Key',
    DAYS_REMAINING: 'days remaining',
    COPY: 'Copied!',
  },
  SUBSCRIPTION_EXPIRED: {
    TITLE: 'Your subscription has expired',
    END_TITLE: 'Subscription Expired',
    EXPIRED_ON: 'on',
    EXPIRED_TITLE: "We're Sorry to See You Leave",
    EXPIRED_MESSAGE:
      'Your subscription has expired, and access to our premium features has been disabled. To maintain uninterrupted access to our exclusive content, tools, and services, please renew your subscription by reaching out to us at',
    RENEW_MESSAGE: 'Renew Your Subscription Now: ',
    CONNECTED_MESSAGE:
      'Stay connected and make the most of our premium offerings.',
    NOTE: 'For any questions or help with renewing your subscription, please contact us at',
    SUBSCRIPTION_NOTE:
      'If you have any questions or need assistance with renewing your subscription,',
    SUBSCRIPTION_CONTACT_NOTE: 'please contact',
    CONTACT_EMAIL: 'contact@confixa.com',
    SUBSCRIPTION_ALERT: 'Subscription Alert',
    RENEW_TOPIC: 'Please renew to continue enjoying our services.',
    SUBSCRIPTION_END_TOPIC: 'Your subscription is about to end.',
    SUBSCRIPTION_DAYS_TOPIC: 'days to go!',
    SUBSCRIPTION_EXPIRING: 'Expiring in',
    DAYS: 'days',
  },
  EMAIL: {
    SUBJECT: 'Request for Confixa Reactivation of Expired Subscription',
    BODY: `Dear Confixa Support Team,

I hope this message finds you well. I am writing to request the activation of Confixa services for my domain "frontend.confixa.com" and to reactivate the expired subscription associated with it. My subscription, which was on the Base plan and billed annually, expired on {deactivateDate}.

Here are the details of my subscription:

Domain Name: frontend.confixa.com
Plan: Base (Annual)
Activated On: {activateDate}
Deactivated On: {deactivateDate}

I would appreciate your assistance in reactivating the subscription and ensuring that Confixa services are active for the domain. Please let me know the steps required to proceed, including any payment details or additional information you may need from my end.

Thank you for your support.`,
  },
  SUBMIT_TICKET_TYPES: [
    { label: 'Question', value: 'Question' },
    { label: 'Incident', value: 'Incident' },
    { label: 'Problem', value: 'Problem' },
    { label: 'Feature Request', value: 'Feature Request' },
    { label: 'Refund', value: 'Refund' },
  ],
  SUPPORT_PRIORITY_LIST: [
    { label: 'Low', value: 'Low', priorityValue: 1 },
    { label: 'Medium', value: 'Medium', priorityValue: 2 },
    { label: 'High', value: 'High', priorityValue: 3 },
    { label: 'Urgent', value: 'Urgent', priorityValue: 4 },
  ],
  SUPPORT_STATUS_LIST: [
    { label: 'All Status', value: 'All Status', defaultValues: true },
    { label: 'Open', value: 'Open', statusValue: 2 },
    { label: 'Pending', value: 'Pending', statusValue: 3 },
    { label: 'Resolved', value: 'Resolved', statusValue: 4 },
    { label: 'Closed', value: 'Closed', statusValue: 5 },
  ],
  SUPPORT_SORTBY_LIST: [
    { label: 'Date Created', value: 'Date Created' },
    { label: 'Last Modified', value: 'Last Modified' },
    { label: 'Priority', value: 'Priority' },
    { label: 'Status', value: 'Status' },
    { divider: true }, // Add this for the separator
    { label: 'Ascending', value: 'Ascending' },
    { label: 'Descending', value: 'Descending' },
  ],
  NEW_WEB_SERVICES: {
    SELECT_REPOSITORY_TEXT: 'Select Your Repository',
    GITHUB_HEADER_PARAGRAPH: `Connect your Confixa account to ${platform} to being using your existing repos for new services`,
    PUBLIC_REPOSITORY_HEADER_TEXT: 'Public Git Repository',
    PUBLIC_REPOSITORY_HEADER_PARAGRAPH:
      'Please provide the URL of the public repository you wish to utilize for building services with your Confixa account.',
    PRIVATE_REPOSITORY_HEADER_TEXT: 'Private Git Repository',
    PRIVATE_REPOSITORY_HEADER_PARAGRAPH:
      'Please select a private repository connected to your Confixa account for building your service.',
  },
  DEPLOYING_WEB_SERVICE: {
    DEPLOYING_WEB_SERVICE_HEADER: 'You are deploying a :service service',
  },
  HOME: {
    HOME_TEXT: 'Quick Start : Deploy in minutes',
  },
  GIT: {
    GIT_HEADER: `${platform} Token`,
    GIT_HEADER_PARAGRAPH: `A ${platform} token is a secure access key used for authenticating API requests, enabling seamless interaction with ${platform}. It allows you to access repositories, manage issues, and automate workflows. To create a non-expiring token, go to your GitHub settings, generate a personal access token, and select all necessary scopes. Always keep your token private to ensure the security of your projects and data.`,
    GIT_URL: `How to get ${platform} token`,
  },
  ARGO_CD: {
    ARGO_CD_HEADER: 'ArgoCD User Api Token',
    ARGO_CD_HEADER_PARAGRAPH:
      'Use a Public Repository by entering the URL below. Features like PR Reviews and Auto-Deploy are not available if repository has not been configured for render',
    ARGO_CD_URL: 'How to get ArgoCd token',
  },
  ARTIFACT_REPOSITORY: {
    ARTIFACT_REPOSITORY_URL: 'How to create Artifact Repositories',
  },
  APPLICATION_CREATE_STEPS: {
    FIRST_STEP: 0,
    SECOND_STEP: 1,
    THIRD_STEP: 2,
  },
  STEPPER_SERVICE: {
    NEXT_STEP: 'Next',
    PREVIOUS_STEP: 'Previous',
    KEY_LABEL: 'Key',
    VALUE_LABEL: 'value',
  },
  STEPPER_TITLE: [
    { title: 'Application' },
    { title: 'Environment Management' },
    { title: 'ConfigMaps' },
  ],
  LOADER_TEXTS: [
    'Generating response.',
    'Processing your query.',
    'Analyzing your request.',
    'Fetching relevant information.',
    'Executing command.',
    'Compiling data for your request.',
    'Processing your command.',
  ],
  NOTE: {
    KEBAB_CASE:
      'NOTE : Required name in Kebab case or it will convert automatically. (Example - "ConfixaProject convert it into confixa-project")',
    UPDATE_VALUES: 'Please update the values for the respective',
    DASHBOARD:
      ' The data shown is for demonstration purposes only. To unlock real-time insights and access more detailed metrics, please set up the required tools, such as Kubecost, Observability, Uptime Kuma, and GCP Monitoring.',
    MONITORING_METRICS_URL:
      'Note: Adding a URL will enable access to the metrics page under Observability as an alternative, applicable only when the dashboard of observability tool is disabled.',
    OPEN_AI_KEY:
      'Note : To access chatbot functionality and to generate issues on versioning tools for specific or all applications, an Language model and OpenAI key are required. Please ensure that the model and key are configured to enable these features seamlessly.',
    ELASTIC_DASHBOARD_TEXT:
      'Note : Choose to enable or disable the Observability Dashboard Status depending on the addition of the Kubernetes Agent to your cluster via observability tools.',
    MONITORING_STATUS_TEXT:
      'Note : Set up metrics to track CPU, memory, and network usage, and configure the monitoring settings through the Settings tab to track and optimize performance.',
    ELASTIC_STATUS_TEXT:
      'Note : Set up APM to monitor performance metrics and configure the observability settings through the Settings tab to track and optimize application performance.',
  },
  HEY: 'Hey,',
  NO_DATA_AVAIL: 'No Data Available',
  TABS: {
    APP: 'Applications',
    HELM: 'Helm Chart',
    ISSUES: 'Issues',
    VALUES: 'Values',
    DOMAIN_MGMT: 'Environment Management',
    EVENTS: 'Events',
    DEPLOY_LOGS: 'Deployment Logs',
    CONFIG_MAP: 'ConfigMaps',
    SECRETS: 'Secrets',
    DETAILS: 'Details',
    BRANCHES: 'Branches',
    BRANCH: 'Branch',
    HELM_CHART_TAB: 'helm_chart',
    BRANCH_DETAIL: 'branch_detail',
    BRANCH_LIST: 'branch_list',
    ENVIRONMENTS: 'Environments',
    ENVIRONMENT_LIST: 'environments_list',
  },
  TABLE_HEADER: {
    SR_NO: 'S.NO',
    ISSUES: 'ISSUES',
    AUTHOR: 'AUTHOR',
    PR_STATUS: 'PR STATUS',
    ACTION: 'ACTION',
    BRANCH_NAME: 'BRANCH NAME',
    ENVIRONMENT_NAME: 'ENVIRONMENT NAME',
    KUBERNETES_CLUSTER: 'KUBERNETES CLUSTER',
    TOP_LEVEL_DOMAIN: 'TOP-LEVEL DOMAIN',
    OPEN: 'OPEN',
    CLOSED: 'CLOSED',
  },
  NO_DATA: 'No Data Available',
  PREVIOUS_CHATS_NOT_AVAILABLE: 'Previous chats are not available.',
  PLACE_HOLDER: {
    SEND_A_MESSAGE: 'Send a message to the Assistant',
  },
  BUILD_DEPLOY_GIT_REPOSITORY: 'Build and deploy from a git repository',
  CONNECT_GITHUB_REPOSITORY: `Connect a ${platform} repository`,
  WORK_FLOW_STATUS: [
    'cancelled',
    'completed',
    'success',
    'failure',
    'timed_out',
    'skipped',
    'startup_failure',
  ],
  WORK_FLOW_PROCESS_STATUS: ['queued', 'in_progress', 'waiting'],
  BITBUCKET_WORK_FLOW_STATUS: [
    'COMPLETED',
    'FAILED',
    'ERROR',
    'STOPPED',
    'EXPIRED',
    'SUCCESSFUL',
  ],
  BITBUCKET_WORK_FLOW_PROCESS_STATUS: ['PENDING', 'IN_PROGRESS', 'PAUSED'],
  ISSUE_STATUS: {
    inProcess: 'Process',
    open: 'Open',
    close: 'Close',
    failed: 'Failed',
    merge: 'Merged',
  },
  URL_CONFIGURATION: {
    SYSTEM_HEALTH_URL: process.env.REACT_APP_UPTIME_URL
      ? process.env.REACT_APP_UPTIME_URL +
        process.env.REACT_APP_UPTIME_MANAGE_PATHNAME
      : '',
    SYSTEM_HEALTH_TITLE: 'System Health Monitoring',
    SYSTEM_HEALTH_INFO:
      'Set up system health monitoring to track uptime, response times, and resource usage. Real-time alerts help you resolve issues before they cause downtime, ensuring reliable service delivery. This tool enhances operational efficiency and keeps your systems running smoothly.',
    SYSTEM_HEALTH_IMG: process.env.REACT_APP_IMAGE_URL
      ? process.env.REACT_APP_IMAGE_URL +
        process.env.REACT_APP_SYSTEM_HEALTH_IMG
      : defaultImage,

    FINOPS_URL: process.env.REACT_APP_KUBECOST_URL,
    FINOPS_TITLE: 'FinOps for Kubernetes',
    FINOPS_INFO:
      'Implement FinOps to optimize cloud spending in Kubernetes. Gain visibility into resource usage and costs, avoid over-provisioning, and manage cloud expenses more effectively. Setting up FinOps ensures financial accountability while maximizing resource efficiency in your cloud environment.',
    FINOPS_IMG: process.env.REACT_APP_IMAGE_URL
      ? process.env.REACT_APP_IMAGE_URL + process.env.REACT_APP_KUBECOST_IMG
      : defaultImage,

    CLUSTER_URL: process.env.REACT_APP_KUBERNETES_DASHBOARD_URL
      ? process.env.REACT_APP_KUBERNETES_DASHBOARD_URL +
        process.env.REACT_APP_KUBERNETES_DASHBOARD_PATHNAME
      : '',
    CLUSTER_TITLE: 'Cluster Management Dashboard',
    CLUSTER_INFO:
      'Set up a cluster management dashboard to gain real-time insights into your Kubernetes clusters. Monitor nodes, resource usage, and workloads in one place. Simplify troubleshooting and scaling to keep your clusters running efficiently and reliably.',
    CLUSTER_IMG: process.env.REACT_APP_IMAGE_URL
      ? process.env.REACT_APP_IMAGE_URL +
        process.env.REACT_APP_CLUSTER_DASHBOARD_IMG
      : defaultImage,

    LOG_MONITORING_URL: process.env.REACT_APP_ELASTIC_URL
      ? process.env.REACT_APP_ELASTIC_URL + process.env.REACT_APP_LOGS_PATHNAME
      : process.env.REACT_APP_LOGS_PATHNAME,
    LOG_MONITORING_TITLE: 'Log Monitoring',
    LOG_MONITORING_INFO:
      'Centralize your log data to easily track errors and system events. Log monitoring helps you debug issues, ensure compliance, and maintain system reliability. Set it up for comprehensive operational visibility across your infrastructure.',
    LOG_MONITORING_IMG: process.env.REACT_APP_IMAGE_URL
      ? process.env.REACT_APP_IMAGE_URL + process.env.REACT_APP_LOGS_IMG
      : defaultImage,

    METRICS_MONITORING_URL: process.env.REACT_APP_GCP_METRIC_URL
      ? process.env.REACT_APP_GCP_METRIC_URL
      : process.env.REACT_APP_ELASTIC_URL
      ? process.env.REACT_APP_ELASTIC_URL +
        process.env.REACT_APP_METRICS_PATHNAME
      : process.env.REACT_APP_METRICS_PATHNAME,
    METRICS_MONITORING_TITLE: 'Metrics Monitoring',
    METRICS_MONITORING_INFO:
      'Set up metrics monitoring to track key performance indicators like CPU, memory, and network usage. Detect trends, optimize resource allocation, and prevent performance bottlenecks to maintain high-performing systems.',
    METRICS_MONITORING_IMG: process.env.REACT_APP_IMAGE_URL
      ? process.env.REACT_APP_IMAGE_URL + process.env.REACT_APP_METRICS_IMG
      : defaultImage,

    TRACING_FOR_SYSTEMS_URL: process.env.REACT_APP_ELASTIC_URL
      ? process.env.REACT_APP_ELASTIC_URL + process.env.REACT_APP_TRACE_PATHNAME
      : process.env.REACT_APP_TRACE_PATHNAME,
    TRACING_FOR_SYSTEMS_TITLE: 'Tracing for Distributed Systems',
    TRACING_FOR_SYSTEMS_INFO:
      'Implement tracing to visualize request flows across distributed systems. Tracing helps you identify performance bottlenecks and optimize microservices. Set it up for better troubleshooting and faster resolution of complex issues.',
    TRACING_FOR_SYSTEMS_IMG: process.env.REACT_APP_IMAGE_URL
      ? process.env.REACT_APP_IMAGE_URL + process.env.REACT_APP_TRACE_IMG
      : defaultImage,

    CONTINUOUS_DELIVERY_URL: process.env.REACT_APP_ARGOCD_URL,
    CONTINUOUS_DELIVERY_AUTH_URL: process.env.REACT_APP_ARGOCD_URL
      ? process.env.REACT_APP_ARGOCD_URL + process.env.REACT_APP_ARGOCD_PATHNAME
      : '',
    CONTINUOUS_DELIVERY_TITLE: 'Continuous Delivery for Kubernetes',
    CONTINUOUS_DELIVERY_INFO:
      'Automate your Kubernetes deployments with continuous delivery. Set it up to streamline updates, reduce manual errors, and speed up releases. Ensure faster rollbacks and version control for improved application reliability.',
    CONTINUOUS_DELIVERY_IMG: process.env.REACT_APP_IMAGE_URL
      ? process.env.REACT_APP_IMAGE_URL + process.env.REACT_APP_ARGOCD_IMG
      : defaultImage,
  },
  GITHUB_URL: 'https://github.com/',
  BITBUCKET_URL: 'https://bitbucket.org/',
  TOOLTIPS: {
    SCHEDULE_INFO: `<div class="font-bold">Specify tasks using five fields:</div>
    <div>Minute (0-59), Hour (0-23), Day of Month (1-31), Month (1-12), Day of Week (0-7, Sun=0/7).</div>
    <div class="font-bold mt-3">For example:</div>
    <div>
      <ul>
        <li>0 0 * * * - Daily at midnight</li>
        <li>30 14 1 * * - 2:30 PM on the 1st</li>
        <li>15 10 * * 1-5 - 10:15 AM, Mon-Fri</li>
      </ul>
    </div>`,
    ARTIFACTS_INFO: `
    <div class="font-bold">GCP:</div>
    <div class="font-bold">asia-south1-docker.pkg.dev/demo-rnd/demo-applications refers to a Google Cloud Platform (GCP) Artifact Registry repository:</div>
    <div>
      <ul>
        <li><b>Region:</b> asia-south1</li>
        <li><b>Service:</b> docker.pkg.dev (Docker images)</li>
        <li><b>Project:</b> demo-rnd</li>
        <li><b>Repository:</b> demo-applications</li>
      </ul>
    </div>
    <div class="font-bold mt-4">AWS:</div>
    <div class="font-bold">533267415085.dkr.ecr.eu-west-2.amazonaws.com is an AWS Elastic Container Registry (ECR) path:</div>
    <div>
      <ul>
        <li><b>Account ID:</b> 533267415085</li>
        <li><b>Service:</b> dkr.ecr (Docker ECR)</li>
        <li><b>Region:</b> eu-west-2 (Europe, London)</li>
        <li><b>Domain:</b> amazonaws.com</li>
      </ul>
    </div>
    <div class="font-bold mt-4">This path is used to store and manage Docker container images.</div>
`,
  },
  DASHBOARD: {
    KUBE_COST_URL: process.env.REACT_APP_KUBECOST_URL,
    // MONITORING_URL: process.env.REACT_APP_SHOW_MONITORING_CARD,
    UPTIME_URL: process.env.REACT_APP_UPTIME_URL
      ? process.env.REACT_APP_UPTIME_URL +
        process.env.REACT_APP_UPTIME_MANAGE_PATHNAME
      : '',
    // ELASTIC_URL: process.env.REACT_APP_ELASTIC_URL,
  },
  KEYCLOAK_INIT: process.env.REACT_APP_KEYCLOAK_INIT,
  KEYCLOAK_ADMIN_URL:
    process.env.REACT_APP_KEYCLOAK_URL +
    process.env.REACT_APP_KEYCLOAK_ADMIN_PATHNAME_1 +
    process.env.REACT_APP_KEYCLOAK_REALM +
    process.env.REACT_APP_KEYCLOAK_ADMIN_PATHNAME_2,
  UPTIME_STATUS_PAGE_URL:
    process.env.REACT_APP_UPTIME_URL +
    process.env.REACT_APP_UPTIME_STATUS_PATHNAME,
  SYSTEM_HEALTH_CHECK_TEXT: 'System health check link',
  KUBERNETES_TOKEN: process.env.REACT_APP_KUBERNETES_TOKEN,
  GITHUB_ACCESS_TOKENS_URL: process.env.REACT_APP_GITHUB_ACCESS_TOKENS_URL,
  KIBANA: {
    KIBANA_CD_PIPELINE:
      process.env.REACT_APP_KIBANA +
      process.env.REACT_APP_KIBANA_CDPIPELINE_PATHNAME,
    KIBANA_FRAME_URL:
      process.env.REACT_APP_KIBANA +
      process.env.REACT_APP_KIBANA_FRAME_PATHNAME,
    KIBANA_KUBERNETES_NEW_FRAME:
      process.env.REACT_APP_KIBANA +
      process.env.REACT_APP_KIBANA_KUBERNETSNEWFRAME_PATHNAME,
  },
  CLOUD_ARTIFACT_REGISTRY: process.env.REACT_APP_CLOUD_ARTIFACT_REGISTRY,
  ARGOCD_INVITE_URL: process.env.REACT_APP_ARGOCD_INVITE_URL,
  SHOW_CHAT_BOAT: process.env.REACT_APP_SHOW_CHAT_BOAT,
  PRIVATE_SERVICE_ACTIVE: process.env.REACT_APP_PRIVATE_SERVICE_ACTIVE,
  BACKGROUND_WORKERS_ACTIVE: process.env.REACT_APP_BACKGROUND_WORKERS_ACTIVE,
  BLUEPRINTS_ACTIVE: process.env.REACT_APP_BLUEPRINTS_ACTIVE,
  KEYCLOAK_AUTH_URL:
    process.env.REACT_APP_KEYCLOAK_URL +
    process.env.REACT_APP_KEYCLOAK_AUTH_PATHNAME_1 +
    process.env.REACT_APP_KEYCLOAK_REALM +
    process.env.REACT_APP_KEYCLOAK_AUTH_PATHNAME_2,
  RESPONSE_TYPE: process.env.REACT_APP_RESPONSE_TYPE,
  CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
  REDIRECT_URI:
    process.env.REACT_APP_REDIRECT_URL +
    process.env.REACT_APP_REDIRECT_PATHNAME,
  SCOPE: process.env.REACT_APP_SCOPE,
  SIMMMPLE_LICENSES: process.env.REACT_APP_SIMMMPLE_LICENSES,
  SIMMMPLE_TERMS_SERVICE: process.env.REACT_APP_SIMMMPLE_TERMSSERVICE,
  HORIZON_UI: process.env.REACT_APP_HORIZON_UI,
  SAMPLE_RATE_VALUE: [
    { label: '0.1', value: '0.1' },
    { label: '0.2', value: '0.2' },
    { label: '0.3', value: '0.3' },
    { label: '0.4', value: '0.4' },
    { label: '0.5', value: '0.5' },
    { label: '0.6', value: '0.6' },
    { label: '0.7', value: '0.7' },
    { label: '0.8', value: '0.8' },
    { label: '0.9', value: '0.9' },
    { label: '1.0', value: '1.0' },
  ],
  DASHBOARD_VALUE: [
    { label: 'Enable', value: 'Enable' },
    { label: 'Disable', value: 'Disable' },
  ],
  REFERENCE_DOCUMENT: {
    ELASTIC_TEXT: 'How to Configure Observability Tool',
    ELASTIC_URL: process.env.REACT_APP_OBSERVABILITY_TOOL_DOCUMENTATION_URL,
    MONITORING_TEXT: 'How to Configure Monitoring',
    MONITORING_URL: process.env.REACT_APP_MONITORING_DOCUMENTATION_URL,
    COST_OPTIMIZE_URL: process.env.REACT_APP_COST_OPTIMIZE_DOCUMENTATION_URL,
    COST_OPTIMIZE_TEXT: 'How to Configure Cost Optimization',
  },
  DISPLAY_MONITOR: process.env.REACT_APP_SHOW_MONITORING_CARD,
  LANGUAGE_MODEL_VALUE: [
    { label: 'Select Language Model', value: '' },
    { label: 'OpenAI', value: 'OpenAI' },
    { label: 'Gemini', value: 'Gemini' },
    { label: 'ChatGPT', value: 'ChatGPT' },
    { label: 'Claude', value: 'Claude' },
  ],
  UPTIME_KUMA_UI: {
    DUMMY_TICKS: {
      status: 'pending',
      date: new Date().toISOString(),
      statusCode: 2,
      isDummy: true,
    },
    EMPTY_TICKS: {
      status: 'pending',
      date: '',
      statusCode: 3,
      isEmpty: true,
    },
    TOTAL_TICKS: 48,
    NO_DATA_AVAILABLE: 'No Data Available',
    REFRESH_DATA: 'Data is unavailable, please refresh',
    REACHED_END: "You've reached the end!",
  },
}

export default webConstants
