import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import debounce from 'lodash.debounce'
import Card from 'components/card'
import { useForm } from 'react-hook-form'
import CustomEnvMultiSelect from 'common/CustomEnvMultiSelect'
import { useState, useEffect, useCallback } from 'react'
import { useToaster } from 'common/Toaster'
import { RxCrossCircled } from 'react-icons/rx'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import appConstants from 'common/config/appConstants'
import { useSpinner } from 'common/SpinnerLoader'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { toKebabCase, getOrganization } from 'common/commonFunction'
import { FaAngleDown } from 'react-icons/fa'
import { FaAngleUp } from 'react-icons/fa'
import Switch from 'components/switch'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
} from '@chakra-ui/modal'

const apiService = ApiCaller()
const { TOAST, NOTE, REQUIRED, LABEL, VALIDATION, BUTTON_TEXT } = appConstants

const AddProject = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const location = useLocation()
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      projectName: '',
      branches: '',
    },
  })
  const [flag, setFlag] = useState('')
  const [isEnvironmentFlag, setIsEnvironmentFlag] = useState('')
  const [userInteracted, setUserInteracted] = useState(false)
  const [envInteracted, setEnvInteracted] = useState(false)
  const { addToast } = useToaster()
  const { showSpinner, hideSpinner } = useSpinner()
  const [isLoading, setIsLoading] = useState(false)
  const organizationData = JSON.parse(localStorage.getItem('organizationData'))
  const [isOpen, setIsOpen] = useState(false)
  const [environment, setEnvironment] = useState('')
  const [branches, setBranches] = useState([])
  const [selectedBranch, setSelectedBranch] = useState([])
  const [errorsEnvironment, setErrorsEnvironment] = useState('')
  const [isEnvDisable, setIsEnvDisable] = useState(false)
  const [errorsEnv, setErrorsEnv] = useState('')
  const projectName = watch('projectName')
  const createProject = location.pathname.includes('create-project')
    ? true
    : false

  const getOrganizationDetails = async () => {
    try {
      const response = await getOrganization()
      if (response?.data?.code === 200) {
        const branchData = response?.data?.data?.env
        const branchList = branchData?.map((branch) => ({
          label: branch.name,
          value: branch.name,
        }))
        setBranches(branchList)
      } else {
        addToast({
          title: TOAST.MESSAGES.ERROR.fetchVersionError,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    }
  }

  const getUnselectedEnv = async (id) => {
    try {
      const apiUrl = apiConfig.GET_UNSELECTED_ENV.replace(':id', id)
      const response = await apiService.apiCall('get', apiUrl)
      if (response?.data?.code === 200) {
        const branchData = response?.data?.data
        const branchList = branchData?.map((branch) => ({
          label: branch,
          value: branch,
        }))
        setBranches(branchList)
      } else {
        addToast({
          title: TOAST.MESSAGES.ERROR.fetchVersionError,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    }
  }

  const handleProjectNameChange = async (event) => {
    setUserInteracted(true)
    const value = toKebabCase(event.target.value)
    setValue('projectName', value)
    if (value.trim() !== '') {
      setIsLoading(true)
      debouncedGetProjectData({
        projectName: value,
        ...(id && { projectId: id }),
      })
    }
  }

  const handleEnvironmentChange = (event) => {
    setEnvInteracted(true)
    const value = event.target.value
    if (!value.trim()) {
      setErrorsEnvironment(REQUIRED.ENVIRONMENTS_REQUIRED)
      setEnvironment('')
    } else {
      setIsLoading(true)
      setEnvironment(value)
      setErrorsEnvironment('')
      debouncedGetEnvironmentData({
        branchName: value,
        organizationId: organizationData?.id,
      })
    }
  }

  const debouncedGetEnvironmentData = useCallback(
    debounce(async (payload) => {
      setIsLoading(true)
      try {
        const apiUrl = apiConfig.CHECK_BRANCH_NAME
        const response = await apiService.apiCall('post', apiUrl, payload)
        if (response?.data?.code === 200) {
          setIsEnvironmentFlag(
            response?.data?.data?.isAvailableForBranchCreation
          )
        }
      } finally {
        setIsLoading(false)
      }
    }, 500),
    []
  )

  const debouncedGetProjectData = useCallback(
    debounce(async (payload) => {
      setIsLoading(true)
      try {
        const apiUrl = apiConfig.GET_PROJECTS_DATA
        const response = await apiService.apiCall('post', apiUrl, payload)
        if (response?.data?.code === 200) {
          setFlag(response?.data?.data?.isAvailableForCreation)
        }
      } finally {
        setIsLoading(false)
      }
    }, 300),
    []
  )

  const onFormSubmit = async (formValue) => {
    try {
      setIsEnvDisable(true)
      if (
        !selectedBranch.length ||
        !formValue.projectName ||
        formValue.projectName.trim() === ''
      ) {
        if (!selectedBranch.length) {
          setErrorsEnv(REQUIRED.ENVIRONMENTS_REQUIRED)
        }
        return
      }
      const envList = selectedBranch.map((env) => {
        return env.value
      })
      const envSyncList = selectedBranch.map((es, index) => {
        return { env: es.value, status: checkedStates[index] }
      })

      showSpinner()
      const apiUrl = id
        ? apiConfig.EDIT_PROJECT.replace(':id', id)
        : apiConfig.CREATE_PROJECT
      const method = id ? 'put' : 'post'
      const payloadData = id
        ? {
            env: envList,
            envSync: envSyncList,
          }
        : {
            organizationId: id ? undefined : organizationData?.id,
            name: formValue.projectName,
            env: envList,
            envSync: envSyncList,
          }

      const response = await apiService.apiCall(method, apiUrl, payloadData)
      if (response?.data?.code === 200) {
        addToast({
          title: response?.data?.msg,
          type: 'success',
        })
        navigate(`/projects`)
      } else {
        addToast({
          title:
            response?.data?.error || TOAST.MESSAGES.ERROR.createProjectError,
          type: 'error',
        })
      }
    } catch (error) {
      console.error('Error while project:', error)
    } finally {
      hideSpinner()
      setIsEnvDisable(false)
    }
  }

  const getProjectDetails = async () => {
    const apiUrl = apiConfig.GET_PROJECT_DETAILS.replace(':id', id)
    try {
      showSpinner()
      const response = await apiService.apiCall('get', apiUrl)
      if (response?.data?.code === 200 || response?.data?.code === 204) {
        const responseData = response?.data?.data
        const branchList = responseData.env?.map((branch) => ({
          label: branch,
          value: branch,
          disabled: true,
        }))
        const checkedList = responseData.envSync?.map((es) => {
          return es.status
        })
        setValue('projectName', responseData?.name)
        setCheckedStates(checkedList)
        setSelectedBranch(branchList)
      } else {
        addToast({
          title: response?.msg || TOAST.MESSAGES.ERROR.createProjectError,
          type: 'error',
        })
      }
    } catch (error) {
      console.error('Error verifying token:', error)
    } finally {
      hideSpinner()
    }
  }

  useEffect(() => {
    if (id) {
      getProjectDetails()
      getUnselectedEnv(id)
    } else {
      getOrganizationDetails()
    }
  }, [id])

  const addEnvironment = async () => {
    setIsEnvDisable(true)
    const apiUrl = apiConfig.CREATE_BRANCH.replace(
      ':type',
      'CreateBranch'
    ).replace(':id', organizationData?.id)
    const payloadData = {
      env: {
        name: environment,
        k8Cluster: {
          server: '',
          credentialType: '',
          name: `https://kubernetes.${environment?.toLowerCase()}.svc`,
        },
        topLevelDomain: {
          name: `${environment?.toLowerCase()}.com`,
          dnsProvider: '',
          applicationKey: '',
          applicationSecret: '',
        },
        envType: 'Custom',
      },
    }
    try {
      const response = await apiService.apiCall('post', apiUrl, payloadData)
      if (response.data.code === 200) {
        setIsOpen(false)
        getOrganizationDetails()
        addToast({
          title:
            response?.data?.msg || TOAST.MESSAGES.SUCCESS.organizationEdited,
          type: 'success',
        })
      } else {
        addToast({
          title: response?.data?.error,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      setIsEnvDisable(false)
    }
  }

  const handleBranchChange = (selectedOptions) => {
    const disabledBranches = selectedBranch.filter((branch) => branch.disabled)
    // Keep disabled branches in the selection
    const updatedSelection = [
      ...disabledBranches,
      ...selectedOptions.filter((option) => !option.disabled), // Add only non-disabled branches from the new selection
    ]
    const updatedCheckedStates = [...checkedStates]
    const lastSelectedValue =
      selectedOptions[selectedOptions.length - 1].value.toLowerCase()
    updatedCheckedStates.push(
      !['prod', 'production'].includes(lastSelectedValue)
    )
    setCheckedStates(updatedCheckedStates)
    setSelectedBranch(updatedSelection)
  }

  const [checkedStates, setCheckedStates] = useState(
    selectedBranch.map((branch) => branch.checked || false)
  )

  const handleToggleSwitch = (index) => {
    const updatedCheckedStates = [...checkedStates]
    updatedCheckedStates[index] = !updatedCheckedStates[index]
    setCheckedStates(updatedCheckedStates)
  }

  const handleRemoveBranch = (branchValue, event) => {
    event.preventDefault()
    const branchIndex = selectedBranch.findIndex(
      (branch) => branch.value === branchValue
    )
    const updatedCheckedStates = checkedStates.filter(
      (_, index) => index !== branchIndex
    )
    const updatedBranches = selectedBranch.filter(
      (branch) => branch.value !== branchValue
    )
    setCheckedStates(updatedCheckedStates)
    setSelectedBranch(updatedBranches)
  }

  const [visibleCount, setVisibleCount] = useState(10)
  const [isExpanded, setIsExpanded] = useState(false)

  const handleShowMore = (event) => {
    event.preventDefault() // Prevent form submission
    setIsExpanded(!isExpanded)
    setVisibleCount(isExpanded ? 10 : selectedBranch.length) // Show more or show less
  }

  const createEnvironment = (event) => {
    event.preventDefault()
    setIsOpen(true)
    setIsEnvDisable(false)
    setEnvironment('')
  }

  return (
    <>
      <Card extra="w-full h-full pb-[35px] pt-[3px]">
        <Modal
          closeOnOverlayClick={false}
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(false)
          }}
          className="!z-[1010]"
        >
          <ModalOverlay className="bg-[#000] !opacity-30" />
          <ModalContent className="!z-[1004] !m-auto max-h-[550px] max-w-[450px] rounded-[20px] bg-white bg-clip-border pb-[18px] pt-[16px] dark:!bg-navy-800 dark:text-white">
            <div className="mb-[4px] flex w-full items-center justify-between px-[30px]">
              <ModalHeader className="!grow text-2xl font-bold ">
                {LABEL.ADD_ENVIRONMENT}
              </ModalHeader>
              <ModalCloseButton />
            </div>
            <div className="my-4 border-b"></div>
            <ModalBody>
              <div className="px-[30px]">
                <div className="mb-2">
                  {LABEL.NAME} <span className="text-red-500">*</span>
                </div>
                <div className="flex w-full items-center gap-3 rounded-2xl border border-gray-200 bg-lightPrimary outline-none dark:!border-white/10 dark:!bg-navy-800">
                  <input
                    type="text"
                    id="environment"
                    autoComplete="off"
                    placeholder="Enter Environment Name"
                    className="placeholder:text-lightFourth md:text-md h-full w-full rounded-2xl p-3 text-sm text-navy-700 outline-none dark:!bg-navy-800 dark:text-white"
                    value={environment}
                    onChange={handleEnvironmentChange}
                  />
                  {envInteracted && environment.trim() !== '' && (
                    <span className="absolute right-8 mr-2 mt-8 -translate-y-1/2 transform text-xl">
                      {isLoading ? (
                        <span className="logs-loader mt-1 text-xl"></span>
                      ) : isEnvironmentFlag ? (
                        <BsFillCheckCircleFill className="mb-2 text-xl text-green-500" />
                      ) : (
                        <RxCrossCircled className="mb-2 text-xl text-red-500" />
                      )}
                    </span>
                  )}
                </div>
                {errorsEnvironment && (
                  <span className="error-message">{errorsEnvironment}</span>
                )}
                {envInteracted && environment.trim() !== '' && (
                  <span className="ml-2">
                    {isLoading ? (
                      <span className="text-sm text-gray-250">
                        {VALIDATION.CHECKING_AVAILABILITY}
                      </span>
                    ) : isEnvironmentFlag ? (
                      <span className="text-sm text-green-500">
                        {environment} is available.
                      </span>
                    ) : (
                      <span className="text-sm text-red-500">
                        This environment name {environment} already exists.
                      </span>
                    )}
                  </span>
                )}
                <div className="mt-7 grid md:flex lg:flex">
                  <div className="flex w-full items-center justify-end">
                    <button
                      disabled={
                        !environment || !isEnvironmentFlag || isEnvDisable
                      }
                      className={`rounded-xl bg-blueSecondary px-8 py-2 text-sm font-medium capitalize text-white transition duration-200 dark:bg-brand-400 md:ml-2 ${
                        !environment || !isEnvironmentFlag || isEnvDisable
                          ? 'cursor-not-allowed opacity-50'
                          : 'hover:bg-brand-800 active:bg-brand-700 dark:hover:bg-brand-300'
                      } `}
                      onClick={addEnvironment}
                    >
                      {BUTTON_TEXT.ADD}
                    </button>
                  </div>
                </div>
              </div>
            </ModalBody>
          </ModalContent>
        </Modal>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <div className="pl-[33px] pr-[28px]">
            <div className="mb-2 mt-[31px]">
              {LABEL.PROJECT_NAME} <span className="text-red-500">*</span>
            </div>
            <div className="flex w-full items-center gap-3 rounded-2xl border border-gray-200 bg-lightPrimary outline-none dark:!border-white/10 dark:!bg-navy-800">
              <input
                type="text"
                id="projectName"
                disabled={!createProject}
                autoComplete="off"
                placeholder="Enter Project Name"
                className="placeholder:text-lightFourth md:text-md h-full w-full rounded-2xl p-3 text-sm text-navy-700 outline-none dark:!bg-navy-800 dark:text-white"
                value={projectName}
                {...register('projectName', {
                  required: 'Project name is required',
                  onChange: handleProjectNameChange,
                  onBlur: () => setUserInteracted(true),
                })}
              />
              {userInteracted && projectName.trim() !== '' && (
                <span className="absolute right-8 mr-2 mt-8 -translate-y-1/2 transform text-xl">
                  {isLoading ? (
                    <span className="logs-loader relative text-xl"></span>
                  ) : flag ? (
                    <BsFillCheckCircleFill className="mb-2 text-xl text-green-500" />
                  ) : (
                    <RxCrossCircled className="mb-2 text-xl text-red-500" />
                  )}
                </span>
              )}
            </div>
            {errors.projectName && (
              <span className="error-message">
                {errors.projectName.message}
              </span>
            )}
            {userInteracted && projectName.trim() !== '' && (
              <span className="ml-2">
                {isLoading ? (
                  <span className="text-sm text-gray-250">
                    {VALIDATION.CHECKING_AVAILABILITY}
                  </span>
                ) : flag ? (
                  <span className="text-sm text-green-500">
                    {projectName} is available.
                  </span>
                ) : (
                  <span className="text-sm text-red-500">
                    This project name {projectName} already exists.
                  </span>
                )}
              </span>
            )}
            <div className="mt-2">
              <div className="mt-2 text-xs font-medium text-gray-20 dark:text-gray-600">
                {NOTE.KEBAB_CASE}
              </div>
            </div>
          </div>

          <div className="my-8 border-b"></div>

          {/* Environment */}

          <div className="pl-[33px] pr-[28px]">
            <div className="flex justify-between">
              <div className="flex-shrink-0">
                <div className="font-bold text-blueSecondary">
                  {LABEL.ENVIRONMENT}
                </div>
              </div>
              <button
                onClick={(e) => createEnvironment(e)}
                className="flex items-center justify-center rounded-2xl border border-brand-500 px-3 py-2 text-sm text-brand-500 transition duration-200 hover:cursor-pointer hover:bg-brand-600 hover:text-white active:bg-brand-700 dark:border-none dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 md:text-base"
              >
                <span>{LABEL.CREATE_ENVIRONMENT}</span>
              </button>
            </div>

            <div>
              <div className="mb-2 mt-[31px]">
                {LABEL.ENVIRONMENT_NAME}
                <span className="text-red-500"> *</span>
              </div>
              <div
                className={`items-center rounded-2xl border border-gray-200 outline-none dark:!border-white/10 dark:!bg-navy-800 ${
                  errors.flavor
                    ? 'border-red-500 dark:!border-red-400'
                    : 'border-gray-200 dark:!border-white/10'
                }`}
              >
                <CustomEnvMultiSelect
                  options={branches}
                  onChange={handleBranchChange}
                  placeholderText="Select environment"
                  isMultiValue={true}
                  value={selectedBranch} // Pass the actual selected branches here
                />
              </div>
              {!selectedBranch.length && errorsEnv && (
                <span className="error-message">{errorsEnv}</span>
              )}
              <div>
                {selectedBranch.length > 0 && (
                  <div className="add-project relative mt-4 rounded-xl border border-gray-200 p-2">
                    <div className="flex justify-between">
                      <div className="flex flex-wrap gap-3">
                        {selectedBranch
                          .slice(0, visibleCount)
                          .map((branch, index) => (
                            <div
                              key={index}
                              //                               className={`flex items-center whitespace-nowrap rounded-full px-3 py-1 text-sm text-black  ${
                              //                                 branch.disabled
                              //                                   ? 'bg-gray-100 text-gray-700 dark:text-black'
                              //                                   : 'border border-gray-200 dark:text-white'
                              className={`flex items-center whitespace-nowrap rounded-full border border-gray-200 px-3 py-1 text-sm ${
                                branch.disabled
                                  ? 'text-gray-700'
                                  : 'text-black dark:text-white'
                              }`}
                            >
                              <div className="flex flex-col items-center text-xs font-medium">
                                <div
                                  className={`pb-1 ${
                                    branch.disabled
                                      ? 'text-black dark:text-white'
                                      : ''
                                  }`}
                                >
                                  ArgoCD Sync
                                </div>
                                <Switch
                                  checked={checkedStates[index]}
                                  onChange={() => handleToggleSwitch(index)}
                                  color="primary"
                                  extra="w-16 dark:check"
                                />
                              </div>
                              <div className="mx-2 inline-block h-[40px] min-h-[1em] w-0.5 bg-gray-200"></div>
                              <div className="flex items-center">
                                <div className="text-base font-medium">
                                  {branch.label}
                                </div>
                                {!branch.disabled && (
                                  <button
                                    onClick={(e) =>
                                      handleRemoveBranch(branch.value, e)
                                    }
                                    className="ml-2 text-2xl text-red-500 hover:text-red-700"
                                  >
                                    &times;
                                  </button>
                                )}
                              </div>
                            </div>
                          ))}
                      </div>
                      <div>
                        {selectedBranch.length > 10 && (
                          <div className="w-[120px] rounded bg-white px-2">
                            <button
                              onClick={(e) => handleShowMore(e)}
                              className="flex font-bold text-blueSecondary"
                            >
                              <div>
                                {isExpanded ? 'Show less' : 'Show more'}
                              </div>
                              <div className="mt-[5px]">
                                {isExpanded ? <FaAngleUp /> : <FaAngleDown />}
                              </div>
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="mt-7 grid md:flex lg:flex">
              <div className="flex w-full items-center justify-end">
                <div className="mt-8 flex justify-end">
                  <button
                    onClick={() => navigate('/projects')}
                    className="flex w-28 items-center justify-center rounded-2xl border border-brand-400 px-4 py-2 text-sm text-brand-500 transition duration-200 hover:cursor-pointer hover:bg-brand-600 hover:text-white active:bg-brand-700 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 md:text-base"
                  >
                    <span>{BUTTON_TEXT.BACK}</span>
                  </button>
                  <button
                    type="submit"
                    disabled={isEnvDisable}
                    className={`type="submit ml-2 flex w-28 items-center justify-center rounded-2xl bg-brand-500 px-4 py-2 text-sm text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 md:ml-2  md:text-base ${
                      isEnvDisable
                        ? 'cursor-not-allowed opacity-50'
                        : 'hover:bg-brand-800 active:bg-brand-700 dark:hover:bg-brand-300'
                    }`}
                  >
                    <span>{createProject ? 'Save' : 'Update'}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Card>
    </>
  )
}

export default AddProject
