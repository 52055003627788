import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import { IoLogoGithub } from 'react-icons/io'
import React, { useState, useEffect } from 'react'
import Card from 'components/card'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useToaster } from 'common/Toaster'
import { useSpinner } from 'common/SpinnerLoader'
import appConstants from 'common/config/appConstants'
import {
  getArtifactDetails,
  convertToOptions,
  setRepoName,
} from 'common/commonFunction'
import CustomSelect from 'common/CustomSelect'

const apiService = ApiCaller()
const { TOAST, NEW_WEB_SERVICES, BUTTON_TEXT, REQUIRED } = appConstants

/**
 * Invokes useEffect to fetch an invite and set up form data for creating a repository.
 *
 * @return {JSX.Element} The card component with conditional rendering based on isGitToken.
 */
const Invite = () => {
  const { showSpinner, hideSpinner } = useSpinner()
  const [inputValue, setInputValue] = useState('')
  const [checkArtifactExists, setCheckArtifactExists] = useState(false)
  const { id, applicationType } = useParams()
  const [fetchingPrivateRepositories, setFetchingPrivateRepositories] =
    useState(false)

  const { addToast } = useToaster()
  useEffect(() => {
    getInvite('private')
    getArtifactData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const {
    formState: { errors },
  } = useForm()
  const [privateRepositories, setPrivateRepositories] = useState([])
  const [selectedPrivateRepo, setPrivateSelectedRepo] = useState('')
  const [isPublic, setIsPublic] = useState(true)

  /**
   * Checks if the artifact exists and executes the provided callback.
   *
   * @param {function} callback - The function to execute if the artifact exists.
   * @return {boolean} Returns false if the artifact does not exist.
   */
  const checkArtifactAdded = (callback) => {
    if (!checkArtifactExists) {
      addToast({
        title: TOAST.MESSAGES.ERROR.artifactNotAddedError1,
        type: 'error',
      })
      return false
    }
    callback()
  }

  /**
   * Function to handle form submission.
   *
   * @param {type} data - description of the data parameter
   * @param {type} type - description of the type parameter
   * @return {type} description of the return value
   */
  const onSubmit = () => {
    setRepoName(selectedPrivateRepo)
    navigate(`/deploying-service/${id}/${applicationType}`)
  }
  const organizationData = JSON.parse(localStorage.getItem('organizationData'))
  const isGitToken = organizationData?.github_token
  let navigate = useNavigate()

  const handleInputChange = (event) => {
    setInputValue(event.target.value)
  }
  /**
   * A description of the entire function.
   *
   */
  const handleVerify = async () => {
    try {
      showSpinner()
      if (!inputValue) {
        return
      }
      const payloadData = {
        githubUrl: inputValue,
      }
      const response = await apiService.apiCall(
        'post',
        apiConfig.POST_PUBLIC_REPOSITORY,
        payloadData
      )
      if (response.status === 200 && response?.data?.code === 200) {
        const repositoryFullName = getRepositoryFullName(inputValue)
        setRepoName(repositoryFullName)
        addToast({
          title: response?.data?.msg || TOAST.MESSAGES.SUCCESS.gitSuccess,
          type: 'success',
        })
        navigate(`/deploying-service/${id}/${applicationType}`)
        setInputValue('')
      } else {
        addToast({
          title: response?.data?.data?.msg || TOAST.MESSAGES.ERROR.swwError,
          type: 'error',
        })
      }
    } catch (error) {
      console.error('Error verifying token:', error)
    } finally {
      hideSpinner()
    }
  }

  /**
   * A description of the entire function.
   *
   * @param {type} selectedOption - description of parameter
   * @return {type} description of return value
   */
  const handleRepoChange = (selectedOption) => {
    setPrivateSelectedRepo(selectedOption.value)
  }

  /**
   * A function to fetch invite data based on the type provided.
   *
   * @param {type} type - The type of invite data to fetch
   * @return {Promise<void>} This function does not return anything directly
   */
  const getInvite = async (type) => {
    const organizationData = JSON.parse(
      localStorage.getItem('organizationData')
    )

    try {
      showSpinner()
      setFetchingPrivateRepositories(true)
      const response = await apiService.apiCall(
        'get',
        `${apiConfig.GET_PUBLIC_GIT_REPOSITORY}/${organizationData.id}/private`
      )

      if (response?.data?.code === 200) {
        setPrivateRepositories(convertToOptions(response?.data?.data), 'here')
      } else {
        addToast({
          title: TOAST.MESSAGES.ERROR.fetchRepoError,
          type: 'error',
        })
      }
    } catch (error) {
      console.error('Error fetching repositories:', error)
    } finally {
      hideSpinner()
      setFetchingPrivateRepositories(false)
    }
  }
  /**
   * Extracts the username and repository name from a GitHub URL.
   *
   * @param {string} url - The GitHub URL to extract the information from.
   * @return {string|null} The concatenated username and repository name, or null if the URL format is incorrect.
   */
  const getRepositoryFullName = (url) => {
    const regex = /https:\/\/github.com\/([^/]+)\/([^/]+)/
    const match = url.match(regex)
    if (match && match.length === 3) {
      const username = match[1]
      const repositoryName = match[2]
      return `${username}/${repositoryName}`
    }
    return null
  }

  const getArtifactData = async () => {
    try {
      showSpinner()
      const response = await getArtifactDetails()
      if (response.data.code === 200) {
        setCheckArtifactExists(true)
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      hideSpinner()
    }
  }

  return (
    <>
      {isGitToken && checkArtifactExists ? (
        ''
      ) : (
        <div className="mb-2 ml-2 flex items-center pb-5 dark:text-white ">
          <span className="">
            Connect to{' '}
            {!isGitToken && (
              <>
                <span>
                  <a
                    href="/settings/git"
                    rel="noopener noreferrer"
                    className="text-brand-500 dark:text-brand-400"
                  >
                    {BUTTON_TEXT.GITHUB_ACCOUNT}{' '}
                  </a>
                </span>
                {!checkArtifactExists && ' and '}
              </>
            )}
            {!checkArtifactExists && (
              <>
                <span>
                  <a
                    href="/settings/artifact-repository"
                    rel="noopener noreferrer"
                    className="text-brand-500 dark:text-brand-400"
                  >
                    {BUTTON_TEXT.ARTIFACT_REPOSITORY}{' '}
                  </a>
                </span>
              </>
            )}
          </span>
        </div>
      )}

      <Card extra={'w-full h-full pb-[35px] pt-[3px] pr-[28px] pl-[33px]'}>
        {/* create repository  */}
        {!isGitToken ? (
          <div>
            <h4 className="my-3 pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
              {BUTTON_TEXT.CONNECT} Account
            </h4>
            <Card extra="w-full h-full my-5 rounded-[20px] px-8 py-20 border border-solid border-purple-200 items-center">
              <div className="grid content-center md:w-96">
                <div className="text-md text-center text-gray-10 dark:text-white">
                  {NEW_WEB_SERVICES.GITHUB_HEADER_PARAGRAPH}
                </div>
                <div className="my-8 grid justify-center gap-4 md:flex lg:flex xl:flex">
                  <button
                    className="flex w-48 items-center justify-center rounded-md border border-solid border-purple-200 px-1 py-3 dark:text-white dark:hover:bg-brand-300 md:mx-4"
                    onClick={() => navigate('/settings/git')}
                  >
                    <IoLogoGithub className="mr-2 text-xl" />{' '}
                    <span className="font-bold">{BUTTON_TEXT.GITHUB}</span>
                  </button>
                  {/* upcoming gitlab part */}
                  {/* <button className="flex w-48 items-center justify-center rounded-md border border-solid border-purple-200 px-1 py-3 dark:text-white dark:hover:bg-brand-300" onClick={() => navigate('/settings/git')}>
                    <img src={gitlab} alt="" className=" mr-2 text-xl" />
                    <span
                      className="font-bold"

                    >
                      Gitlab
                    </span>
                  </button> */}
                </div>
              </div>
            </Card>
          </div>
        ) : (
          <>
            <div className="mb-2 p-2.5 text-xl font-bold text-navy-700 dark:text-white">
              {NEW_WEB_SERVICES.SELECT_REPOSITORY_TEXT}
            </div>
            <div className="mb-2 flex justify-start space-x-1">
              <div
                className={`mb-2 flex w-full cursor-pointer items-center justify-center rounded-full border ${
                  isPublic
                    ? 'bg-blueSecondary text-white dark:bg-brand-400'
                    : ''
                } py-3 text-sm dark:text-white md:mx-4 md:w-40 lg:w-40`}
                onClick={() => setIsPublic(true)}
              >
                {BUTTON_TEXT.PUBLIC}
              </div>
              <div
                className={`mb-2 flex w-full cursor-pointer items-center justify-center rounded-full border ${
                  !isPublic
                    ? 'bg-blueSecondary text-white dark:bg-brand-400'
                    : ''
                } py-3 text-sm dark:text-white md:mx-4 md:w-40 lg:w-40`}
                onClick={() => setIsPublic(false)}
              >
                {BUTTON_TEXT.PRIVATE}
              </div>
            </div>
            {isPublic ? (
              <Card extra="w-full h-full pb-[35px] pt-[3px] pr-[28px] pl-[33px] border border-solid border-purple-200">
                <div className="mt-[25px]">
                  <p className="text-xl font-bold text-navy-700 dark:text-white">
                    {NEW_WEB_SERVICES.PUBLIC_REPOSITORY_HEADER_TEXT}
                  </p>
                  <p className="text-md mt-3 text-gray-10 dark:text-white">
                    {NEW_WEB_SERVICES.PUBLIC_REPOSITORY_HEADER_PARAGRAPH}
                    <br />
                    (e.g. https://github.com/username/repository_name)
                  </p>
                </div>
                <div>
                  <div className="mt-7 flex flex-col md:flex-row md:space-x-4">
                    <div className="text-Black relative w-full rounded-xl border border-gray-200 p-3 text-sm font-normal outline-none dark:!border-white/10 dark:!bg-navy-800 sm:w-full md:w-full">
                      <input
                        type="text"
                        id="username"
                        placeholder="https://github.com/username/repository_name"
                        className="placeholder:text-lightFourth bg-whitetext-sm h-full w-full text-navy-700 outline-none dark:!bg-navy-800 dark:text-white"
                        value={inputValue}
                        autoComplete="off"
                        onChange={handleInputChange}
                      />
                    </div>
                    <button
                      onClick={() => checkArtifactAdded(handleVerify)}
                      type="submit"
                      className={`mt-3 flex w-full items-center justify-center rounded-2xl p-3 text-base text-white transition duration-200  dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 md:mt-0 md:w-40 lg:w-40 ${
                        !inputValue
                          ? 'cursor-not-allowed bg-gray-300'
                          : 'bg-brand-500'
                      }`}
                    >
                      <span>{BUTTON_TEXT.NEXT}</span>
                    </button>
                  </div>
                </div>
              </Card>
            ) : (
              <Card extra="w-full h-full pb-[35px] pt-[3px] pr-[28px] pl-[33px] border border-solid border-purple-200">
                <div className="mt-[25px]">
                  <p className="text-xl font-bold text-navy-700 dark:text-white">
                    {NEW_WEB_SERVICES.PRIVATE_REPOSITORY_HEADER_TEXT}
                  </p>
                  <p className="mt-3 text-base leading-6 text-gray-10 dark:text-white">
                    {NEW_WEB_SERVICES.PRIVATE_REPOSITORY_HEADER_PARAGRAPH}
                  </p>
                </div>
                <div>
                  <div className="mt-7 flex flex-col md:flex-row md:space-x-4">
                    <div className="text-Black relative flex w-full items-center rounded-xl border border-gray-200 p-0 text-sm font-normal outline-none dark:!border-white/10 dark:!bg-navy-800 sm:w-full md:w-full">
                      {fetchingPrivateRepositories ? (
                        <div className="flex w-full items-center pl-4 hover:cursor-not-allowed">
                          <p className="mr-2 text-base font-medium">
                            Loading Repositories
                          </p>
                          <span className="logs-loader -translate-y-1/2 transform text-xl"></span>
                        </div>
                      ) : (
                        <CustomSelect
                          options={privateRepositories}
                          onChange={handleRepoChange}
                          placeholderText="Select Private git Repository"
                        />
                      )}
                    </div>
                    <button
                      type="submit"
                      onClick={() => checkArtifactAdded(onSubmit)}
                      disabled={!selectedPrivateRepo}
                      className={`mt-3 flex w-full items-center justify-center rounded-2xl p-3 text-base text-white transition duration-200 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 md:mt-0 md:w-40 lg:w-40 ${
                        !selectedPrivateRepo
                          ? 'cursor-not-allowed bg-gray-300'
                          : 'bg-brand-500'
                      }`}
                    >
                      {BUTTON_TEXT.NEXT}
                    </button>
                  </div>
                  {errors.repoRequired && (
                    <span className="error-message">
                      {REQUIRED.SELECT_REPOSITORY_REQUIRED}
                    </span>
                  )}
                </div>
              </Card>
            )}
          </>
        )}
      </Card>
    </>
  )
}

export default Invite
