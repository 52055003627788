import React, { useState, useEffect } from 'react'
import RadioServiceCard from 'components/radioServiceCard'
import CloudFlareSvg from 'assets/svg/cloudflare-icon.svg'
import AmazonRoute53Svg from 'assets/svg/amazon-route-53.svg'
import AzureDnsSvg from 'assets/svg/azure-dns.svg'
import GcpCloudDnsSvg from 'assets/svg/gcp-cloud-dns.svg'
import AkamaiSvg from 'assets/svg/akamai-icon.svg'

const RadioServices = ({ setParentSelectedValue, previousDomain }) => {
  const [selectedValue, setSelectedValue] = useState('')

  const [isDisabled, setIsDisabled] = useState({
    cloudflare: true, // Default values to avoid null errors
    amazonRoute: true,
    gcpCloudDns: true,
    azureDns: true,
    akamai: true,
  })

  const handleRadioChange = (value) => {
    setSelectedValue(value)
    setParentSelectedValue(value)
  }

  useEffect(() => {
    const defaultService = previousDomain || 'cloudflare'
    setSelectedValue(defaultService === 'none' ? 'cloudflare' : defaultService)
    setParentSelectedValue(
      defaultService === 'none' ? 'cloudflare' : defaultService
    )

    const updatedDisabledState =
      defaultService === 'none'
        ? {
            cloudflare: false,
            amazonRoute: false,
            gcpCloudDns: false,
            azureDns: false,
            akamai: false,
          }
        : {
            cloudflare: defaultService !== 'cloudflare',
            amazonRoute: defaultService !== 'amazonRoute',
            gcpCloudDns: defaultService !== 'gcpCloudDns',
            azureDns: defaultService !== 'azureDns',
            akamai: defaultService !== 'akamai',
          }

    setIsDisabled(updatedDisabledState)
  }, [previousDomain])

  const services = [
    {
      value: 'cloudflare',
      imageSrc: CloudFlareSvg,
      label: 'CloudFlare',
    },
    {
      value: 'amazonRoute',
      imageSrc: AmazonRoute53Svg,
      label: 'Route 53 (AWS)',
    },
    {
      value: 'gcpCloudDns',
      imageSrc: GcpCloudDnsSvg,
      label: 'Cloud DNS (GCP)',
    },
    {
      value: 'akamai',
      imageSrc: AkamaiSvg,
      label: 'Akamai',
    },
    {
      value: 'azureDns',
      imageSrc: AzureDnsSvg,
      label: 'Azure DNS',
    },
  ]

  return (
    <div className="">
      <p className="text-xl font-bold text-navy-900 dark:text-white">
        Choose Your Service <span className="text-sm text-red-500">*</span>
      </p>
      <div className="col-span-2 my-3 flex flex-wrap md:gap-4 lg:col-span-1">
        {services.map((service) => (
          <RadioServiceCard
            key={service.value}
            isDisabled={isDisabled[service.value]}
            selectedValue={selectedValue}
            value={service.value}
            onRadioChange={handleRadioChange}
            imageSrc={service.imageSrc}
            label={service.label}
          />
        ))}
      </div>
    </div>
  )
}

export default RadioServices
