import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import InputField from 'components/fields/InputField'
import Switch from 'components/switch'
import { RxCross2 } from 'react-icons/rx'
import appConstants from 'common/config/appConstants'

const ModalForUserDetails = ({ data, onClose, onUpdate }) => {
  const { AUTH } = appConstants
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      emailId: data?.emailId || '',
      firstName: data?.firstName || '',
      lastName: data?.lastName || '',
    },
    mode: 'onChange',
  })

  const onSubmit = (formData) => {
    const objToSend = { ...formData, isEmailVerified: checkedState }
    onUpdate(objToSend)
  }

  const [checkedState, setCheckedStates] = useState(data?.isEmailVerified)

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="fixed inset-0 bg-black !opacity-30"></div>
      <div className="z-10 flex w-full max-w-[550px] flex-col rounded-[20px] bg-white py-6 shadow-lg dark:bg-navy-800">
        <div className="flex justify-between px-8 pb-2">
          <h2 className="text-3xl font-bold text-navy-700 dark:text-white">
            Edit User Details
          </h2>
          <span className="flex items-center justify-center">
            <RxCross2
              size={30}
              className="text-black hover:cursor-pointer dark:text-white"
              onClick={onClose}
            />
          </span>
        </div>
        <div className="my-4 border-b border-gray-200 dark:border-[#6a7489]"></div>
        <div className="px-8">
          <form
            className="flex flex-col items-center justify-center gap-4"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="w-full">
              <InputField
                label="Email"
                placeholder={data?.emailId}
                id="emailId"
                type="email"
                disabled={true}
              />
            </div>
            <div className="flex w-full flex-col items-center justify-center">
              <div className="flex w-full items-center justify-center gap-4">
                <div className="w-1/2">
                  <InputField
                    label="First Name"
                    placeholder="John"
                    id="firstName"
                    type="text"
                    isFieldRequired={true}
                    registrationProps={register('firstName', {
                      required: AUTH.FIRST_NAME_REQUIRED_ERROR,
                    })}
                    // state={errors.firstName && 'error'}
                  />
                </div>
                <div className="w-1/2">
                  <InputField
                    label="Last Name"
                    placeholder="Doe"
                    id="lastname"
                    type="text"
                    isFieldRequired={true}
                    registrationProps={register('lastName', {
                      required: AUTH.LAST_NAME_REQUIRED_ERROR,
                    })}
                    // state={errors.lastName && 'error'}
                  />
                </div>
              </div>
              <div className="flex w-full items-center justify-center gap-4">
                <div className="w-1/2">
                  {errors.firstName && (
                    <span className="text-sm text-red-500">
                      {errors.firstName.message}
                    </span>
                  )}
                </div>
                <div className="w-1/2">
                  {errors.lastName && (
                    <span className="text-sm text-red-500">
                      {errors.lastName.message}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="flex w-full justify-start gap-4">
              <label className="text-sm font-bold text-navy-700 dark:text-white">
                Email Verified
              </label>
              <Switch
                checked={checkedState}
                onChange={() => setCheckedStates((prevState) => !prevState)}
                color="primary"
                extra="w-10 dark:check"
              />
            </div>
            <div className="flex w-full justify-end gap-4">
              <button
                type="button"
                className="rounded-xl bg-gray-200 px-4 py-2 text-base font-medium text-black transition duration-200 hover:bg-gray-600 active:bg-gray-700 dark:bg-gray-400 dark:hover:bg-gray-400 dark:active:bg-gray-200"
                onClick={onClose}
              >
                Close
              </button>
              <button
                type="submit"
                className="rounded-xl bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              >
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ModalForUserDetails
