import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
// import { FcGoogle } from 'react-icons/fc'
import InputField from '../../../components/fields/InputField'
import { useToaster } from '../../../common/Toaster'
import appConstants from '../../../common/config/appConstants'
import ApiCaller from '../../../common/services/apiServices'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import apiConfig from 'common/config/apiConfig'
import WelcomeCard from 'common/auth/WelcomeCard'
import { encryptPassword } from '../../../common/commonFunction'

const SignUp = ({
  toggleView,
  handleGoogleClick,
  setLoader,
  setDarkmode,
  darkmode,
}) => {
  const { TOAST, AUTH } = appConstants
  const apiService = ApiCaller()
  const { addToast } = useToaster()

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    mode: 'onSubmit', // Validates on change
  })

  const onSubmit = async (data, event) => {
    event.preventDefault()

    try {
      setLoader(true)
      const { encryptedPassword, ivBase64 } = encryptPassword(data.password)

      if (!encryptedPassword || !ivBase64) {
        addToast({
          title: TOAST.MESSAGES.ERROR.swwError,
          type: 'error',
        })
        return
      }

      const payload = {
        emailId: data.email,
        password: encryptedPassword,
        firstName: data.firstName,
        lastName: data.lastName,
        ivBase64: ivBase64,
      }
      const apiUrl = apiConfig.SIGN_UP_USER
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.code === 200) {
        addToast({
          title: TOAST.MESSAGES.SUCCESS.signUpSuccess,
          type: 'success',
        })
        toggleView('signin')
      } else {
        addToast({
          title: response?.data.error || TOAST.MESSAGES.ERROR.swwError,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({ title: TOAST.MESSAGES.ERROR.swwError, type: 'error' })
    } finally {
      setLoader(false)
    }
  }

  const password = watch('password', '')

  return (
    <div className="relative flex">
      <div className="mx-auto flex min-h-full w-full flex-col justify-start pt-12 md:max-w-[75%] lg:max-w-[1013px] lg:px-8 lg:pt-0  xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
        <div className="mb-auto flex flex-col pl-5 pr-5 md:pl-12 md:pr-0 lg:max-w-[48%] lg:pl-0 xl:max-w-full">
          <div>
            <div className="mb-16 flex h-full w-full items-center justify-center overflow-y-scroll px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-start lg:justify-start">
              <div className="mt-[10vh] w-full max-w-full flex-col md:pl-4 lg:pl-0 xl:max-w-[420px]">
                <h3 className="text-4xl font-bold text-navy-700 dark:text-white">
                  Sign Up
                </h3>
                <p className="mb-3 ml-1 mt-[10px] pb-4 text-base text-gray-600">
                  {AUTH.SIGN_UP_TEXT}
                </p>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="flex w-full items-center justify-center gap-4">
                    <div className="w-1/2">
                      <InputField
                        variant="auth"
                        label="First Name"
                        placeholder="John"
                        id="firstname"
                        type="text"
                        isFieldRequired={true}
                        registrationProps={register('firstName', {
                          required: AUTH.FIRST_NAME_REQUIRED_ERROR,
                        })}
                        // state={errors.firstName && 'error'}
                      />
                    </div>

                    <div className="w-1/2">
                      <InputField
                        variant="auth"
                        label="Last Name"
                        placeholder="Doe"
                        id="lastname"
                        type="text"
                        isFieldRequired={true}
                        registrationProps={register('lastName', {
                          required: AUTH.LAST_NAME_REQUIRED_ERROR,
                        })}
                        // state={errors.lastName && 'error'}
                      />
                    </div>
                  </div>
                  <div className="flex w-full items-center justify-center gap-4">
                    <div className="w-1/2">
                      {errors.firstName && (
                        <span className="text-sm text-red-500">
                          {errors.firstName.message}
                        </span>
                      )}
                    </div>
                    <div className="w-1/2">
                      {errors.lastName && (
                        <span className="text-sm text-red-500">
                          {errors.lastName.message}
                        </span>
                      )}
                    </div>
                  </div>

                  <InputField
                    variant="auth"
                    extra="mt-3"
                    label="Email"
                    placeholder="mail@simmmple.com"
                    id="email"
                    type="email"
                    isFieldRequired={true}
                    registrationProps={register('email', {
                      required: AUTH.EMAIL_REQUIRED_ERROR,
                      pattern: {
                        value: AUTH.EMAIL_REGEX,
                        message: AUTH.EMAIL_ERROR,
                      },
                    })}
                    // state={errors.email && 'error'}
                  />
                  {errors.email && (
                    <span className="text-sm text-red-500">
                      {errors.email.message}
                    </span>
                  )}

                  <div className="relative">
                    <InputField
                      variant="auth"
                      extra="mt-3"
                      label="Password"
                      placeholder="Min 8 characters"
                      id="password"
                      type={showPassword ? 'text' : 'password'}
                      isFieldRequired={true}
                      registrationProps={register('password', {
                        required: AUTH.PASSWORD_REQUIRED_ERROR,
                        pattern: {
                          value: AUTH.PASSWORD_REGEX,
                          message: AUTH.PASSWORD_ERROR,
                        },
                      })}
                      // state={errors.password && 'error'}
                    />
                    <div
                      className="absolute right-4 top-11 cursor-pointer text-black dark:text-white"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <AiOutlineEyeInvisible />
                      ) : (
                        <AiOutlineEye />
                      )}
                    </div>
                  </div>
                  {errors.password && (
                    <span className="text-sm text-red-500">
                      {errors.password.message}
                    </span>
                  )}

                  <div className="relative">
                    <InputField
                      variant="auth"
                      extra="mt-3"
                      label="Confirm Password"
                      placeholder="Confirm Password"
                      id="confirm-password"
                      type={showConfirmPassword ? 'text' : 'password'}
                      isFieldRequired={true}
                      registrationProps={register('confirmPassword', {
                        required: AUTH.CONFIRM_PASSWORD_REQUIRED_ERROR,
                        validate: (value) =>
                          value === password || AUTH.CONFIRM_PASSWORD_ERROR,
                      })}
                      // state={errors.confirmPassword && 'error'}
                    />
                    <div
                      className="absolute right-4 top-11 cursor-pointer text-black dark:text-white"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    >
                      {showConfirmPassword ? (
                        <AiOutlineEyeInvisible />
                      ) : (
                        <AiOutlineEye />
                      )}
                    </div>
                  </div>
                  {errors.confirmPassword && (
                    <span className="text-sm text-red-500">
                      {errors.confirmPassword.message}
                    </span>
                  )}

                  <div className="mt-4 flex items-center justify-between px-2">
                    <input
                      type="checkbox"
                      id="termsAndConditions"
                      {...register('termsAndConditions', {
                        required: AUTH.ACCEPT_TERMS_TEXT,
                      })}
                      className="defaultCheckbox relative flex h-[20px] min-h-[20px] w-[20px] min-w-[20px] appearance-none items-center justify-center rounded-md border border-gray-300 text-white/0 outline-none transition duration-[0.2s] checked:border-none checked:bg-brand-500 checked:text-white hover:cursor-pointer
                      dark:border-white/50 dark:checked:bg-brand-400"
                    />
                    <label
                      htmlFor="termsAndConditions"
                      className="ml-2 text-sm text-navy-700 dark:text-gray-500"
                    >
                      By creating an account, you agree to
                      <a
                        className="pl-1 text-sm font-medium text-brand-500 no-underline hover:cursor-pointer hover:text-brand-600 dark:text-white"
                        href="https://www.confixa.com/terms-and-conditions"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms and Conditions
                      </a>
                      , and
                      <a
                        className="pl-1 text-sm font-medium text-brand-500 no-underline hover:cursor-pointer hover:text-brand-600 dark:text-white"
                        href="https://www.confixa.com/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy Policy
                      </a>
                    </label>
                  </div>
                  {errors.termsAndConditions && (
                    <span className="text-sm text-red-500">
                      {errors.termsAndConditions.message}
                    </span>
                  )}

                  <button
                    type="submit"
                    className="mt-4 w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  >
                    Create my account
                  </button>
                </form>

                <div className="mt-3">
                  <span className="text-sm font-medium text-navy-700 dark:text-gray-500">
                    Already a member?
                  </span>
                  <a
                    onClick={toggleView}
                    className="ml-1 text-sm font-medium text-brand-500 hover:cursor-pointer hover:text-brand-600 dark:text-white"
                  >
                    Sign In
                  </a>
                </div>

                {/* <div className="mb-4 mt-6 flex items-center gap-3">
                  <div className="h-px w-full bg-gray-200 dark:!bg-navy-700" />
                  <p className="text-base font-medium text-gray-600"> or </p>
                  <div className="h-px w-full bg-gray-200 dark:!bg-navy-700" />
                </div> */}

                {/* <div
                  onClick={() => handleGoogleClick()}
                  className="mt-9 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-gray-300 hover:cursor-pointer"
                >
                  <div className="rounded-full text-xl">
                    <FcGoogle />
                  </div>
                  <p className="text-sm font-medium text-navy-700">
                    Sign In with Google
                  </p>
                </div> */}
              </div>
            </div>
          </div>
          <div className="fixed right-0 hidden h-full min-h-screen md:block lg:w-[49vw]">
            <WelcomeCard darkmode={darkmode} setDarkmode={setDarkmode} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignUp
