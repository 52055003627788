import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import { columnsDataOrders } from './variables/columnsDataOrders'
import { helmChartDataOrders } from './variables/helmChartDataOrders'
import SearchTableOrders from './components/ApplicationsTable'
import SearchHelmTableOrders from './components/HelmChart'
import ValuesPage from './components/Values'
import Card from 'components/card'
import { getAllApplicationData, getOrganization } from 'common/commonFunction'
import { useState, useEffect } from 'react'
import { useToaster } from 'common/Toaster'
import { useSpinner } from 'common/SpinnerLoader'
import appConstants from 'common/config/appConstants'
import { useParams } from 'react-router-dom'
import ApplicationIssuesList from './components/ApplicationIssuesList'
const apiService = ApiCaller()

const { TOAST, TABS, GITHUB_URL, BITBUCKET_URL, URL_CONFIGURATION } =
  appConstants
const OrderList = () => {
  const { id } = useParams()
  const projectId = id
  const [selectedTableRows, handleSelectedRows] = useState('')
  const [valuesRepoName, setValuesRepoName] = useState('')
  const [toggleState, setToggleState] = useState('Applications')
  const [valuesData, setValuesData] = useState('')
  const [allAppData, setApplicationData] = useState([])
  const { addToast } = useToaster()
  const { showSpinner, hideSpinner } = useSpinner()
  const [currentPage, setCurrentPage] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const itemsPerPage = 10
  const [helmTableDataManagement, setHelmTableData] = useState()
  const [isApplicationLoading, setApplicationLoading] = useState(false)
  const [isHelmLoading, setHelmLoading] = useState(false)
  const [valuesLoading, setValuesLoading] = useState(false)

  useEffect(() => {
    if (valuesRepoName) {
      getValuesTableData()
    }
    setValuesData(null)

    //eslint-disable-next-line
  }, [valuesRepoName])
  useEffect(() => {
    if (totalCount) {
      setTotalCount(totalCount)
    }
  }, [totalCount])

  useEffect(() => {
    /**
     * Fetches data from the server and updates the application state.
     *
     * @return {Promise<void>} - A promise that resolves when the data is fetched and the state is updated.
     */
    const fetchData = async () => {
      setApplicationLoading(true)
      showSpinner()
      const response = await getAllApplicationData(
        projectId,
        currentPage === 0 ? 1 : currentPage
      )
      setApplicationLoading(false)
      if (response?.data?.code === 200) {
        setApplicationData(response?.data?.data?.results)
        setTotalCount(response?.data?.data?.count)
      } else {
        addToast({
          title: response?.data?.msg || TOAST.MESSAGES.ERROR.swwError,
          type: 'error',
        })
      }
      hideSpinner()
    }
    fetchData()
    setHelmData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  /**
   * Updates the current page number to the provided page number.
   *
   * @param {number} pageNumber - The new page number to set.
   * @return {void} This function does not return anything.
   */
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  /**
   * A function to toggle a tab with the specified value.
   *
   * @param {any} value - The value to set for toggling the tab.
   * @return {void} This function does not return anything.
   */
  const toggleTab = (value) => {
    setHelmLoading(true)
    setToggleState(value)
  }
  /**
   * Handles the verification process by updating values table data.
   *
   * @return {Promise<void>} A promise that resolves when the verification is complete.
   */
  const handleVerify = async () => {
    const apiUrl = apiConfig.UPDATE_VALUES_TABLE_DATA.replace(
      ':project_id',
      projectId
    ).replace(':branch_id', valuesRepoName)
    const payloadData = selectedTableRows.map(({ id, ...rest }) => rest)
    try {
      const response = await apiService.apiCall('put', apiUrl, payloadData)
      if (response?.data?.code === 200) {
        addToast({
          title: response?.data?.msg,
          type: 'success',
        })
        getValuesTableData()
      } else {
        addToast({
          title: response?.data?.msg,
          type: 'error',
        })
      }
    } catch (error) {
      console.error('Error verifying token:', error)
    } finally {
      // hideSpinner();
    }
  }
  /**
   * A function to fetch values table data asynchronously.
   *
   * @return {Promise<void>} A promise that resolves when the data is fetched.
   */
  const getValuesTableData = async () => {
    const apiUrl = apiConfig.GET_VALUES_TABLE_DATA.replace(
      ':project_id',
      projectId
    ).replace(':branch_id', valuesRepoName)
    try {
      setValuesLoading(true)
      const response = await apiService.apiCall('get', apiUrl)
      if (response?.data?.code === 200) {
        const responseData = response?.data?.data
        if (Array.isArray(responseData)) {
          setValuesData(responseData)
        } else {
          console.error('Data returned from API is not an array:', responseData)
        }
      } else {
        console.error('Error response from API:', response)
      }
    } catch (error) {
      console.error('Error fetching config table data:', error)
    } finally {
      setValuesLoading(false)
    }
  }
  /**
   * Sets the Helm data by fetching the organization and constructing the environment names with their respective Helm and Argo URLs.
   *
   * @return {void} This function does not return anything.
   */
  const setHelmData = async () => {
    const response = getOrganization()
    const projectResponse = await getSelectedEnv()
    const projectName = localStorage.getItem('projectName')
    setHelmLoading(true)

    response.then((res) => {
      if (res.data.code === 200) {
        setHelmLoading(false)
        const selectedEnvs = res?.data?.data?.env?.filter((env) =>
          projectResponse?.data?.data.includes(env.name)
        )
        const platformType = res?.data?.data?.platformType
        const envNames = selectedEnvs.map((env) => ({
          env: env.name,
          helmUrl: helmUrl(platformType, res, env, projectName),
          argoUrl: URL_CONFIGURATION.CONTINUOUS_DELIVERY_URL
            ? `${URL_CONFIGURATION.CONTINUOUS_DELIVERY_URL}applications/argocd/${projectName}-${env.name}`
            : '',
        }))
        setHelmTableData(envNames)
      }
    })
  }

  const getSelectedEnv = async () => {
    try {
      const apiUrl = apiConfig.GET_SELECTED_ENV.replace(':projectId', id)
      const response = await apiService.apiCall('get', apiUrl)
      return response
    } catch (error) {
      console.log(error)
    }
  }

  const helmUrl = (platformType, res, env, projectName) => {
    let url = ''
    if (platformType === 'bitbucket') {
      url = `${BITBUCKET_URL}${res?.data?.data?.name}/${res?.data?.data?.repoName}/src/${env.name}/${projectName}`
    } else if (platformType === 'github') {
      url = `${GITHUB_URL}${res?.data?.data?.name}/${res?.data?.data?.repoName}/tree/${env.name}/${projectName}`
    }
    return url
  }

  return (
    <>
      <div className="mb-1 flex w-full justify-start gap-8 overflow-hidden text-3xl">
        <div
          className={
            toggleState === `${TABS.APP}`
              ? 'flex items-center gap-3 border-b-[4px] border-brand-500 pb-1 hover:cursor-pointer dark:border-brand-400'
              : 'flex items-center gap-3 border-b-[4px] border-none pb-1 hover:cursor-pointer dark:!border-navy-800'
          }
          onClick={() => toggleTab(`${TABS.APP}`)}
        >
          <p
            className={
              toggleState === `${TABS.APP}`
                ? 'text-[18px] font-bold text-navy-700 dark:text-white'
                : 'text-[18px] text-disableGray dark:text-white'
            }
          >
            {TABS.APP}
          </p>
        </div>

        <div
          className={
            toggleState === `${TABS.HELM_CHART_TAB}`
              ? 'flex items-center gap-3 border-b-[4px] border-brand-500 pb-1 hover:cursor-pointer dark:border-brand-400'
              : 'flex items-center gap-3 border-b-[4px] border-none pb-1 hover:cursor-pointer dark:!border-navy-800'
          }
          onClick={() => toggleTab(`${TABS.HELM_CHART_TAB}`)}
        >
          <p
            className={
              toggleState === `${TABS.HELM_CHART_TAB}`
                ? 'text-[18px] font-bold text-navy-700 dark:text-white'
                : 'text-[18px] text-disableGray dark:text-white'
            }
          >
            {TABS.HELM}
          </p>
        </div>

        <div
          className={
            toggleState === `${TABS.ISSUES}`
              ? 'flex items-center gap-3 border-b-[4px] border-brand-500 pb-1 hover:cursor-pointer dark:border-brand-400'
              : 'flex items-center gap-3 border-b-[4px] border-none pb-1 hover:cursor-pointer dark:!border-navy-800'
          }
          onClick={() => toggleTab(`${TABS.ISSUES}`)}
        >
          <p
            className={
              toggleState === `${TABS.ISSUES}`
                ? 'text-[18px] font-bold text-navy-700 dark:text-white'
                : 'text-[18px] text-disableGray dark:text-white'
            }
          >
            {TABS.ISSUES}
          </p>
        </div>

        {/* <div
          className={
            toggleState === `${TABS.VALUES}`
              ? 'flex items-center gap-3 border-b-[4px] border-brand-500 pb-1 hover:cursor-pointer dark:border-brand-400'
              : 'flex items-center gap-3 border-b-[4px] border-none pb-1 hover:cursor-pointer dark:!border-navy-800'
          }
          onClick={() => toggleTab(`${TABS.VALUES}`)}
        >
          <p
            className={
              toggleState === `${TABS.VALUES}`
                ? 'text-[18px] font-bold text-navy-700 dark:text-white'
                : 'text-[18px] text-disableGray dark:text-white'
            }
          >
            {TABS.VALUES}
          </p>
        </div> */}
      </div>
      {toggleState === `${TABS.APP}` && (
        <Card extra={'w-full h-full bg-white mt-3'}>
          <SearchTableOrders
            projectId={projectId}
            tableData={allAppData}
            columnsData={columnsDataOrders}
            totalCount={totalCount}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            onPageChange={handlePageChange}
            isApplicationLoading={isApplicationLoading}
            setApplicationLoading={setApplicationLoading}
          />
        </Card>
      )}
      {toggleState === `${TABS.HELM_CHART_TAB}` && (
        <Card extra={'w-full h-full bg-white mt-3'}>
          <SearchHelmTableOrders
            toggleState={toggleState}
            setToggleState={setToggleState}
            tableData={helmTableDataManagement}
            columnsData={helmChartDataOrders}
            isHelmLoading={isHelmLoading}
            setHelmLoading={setHelmLoading}
          />
        </Card>
      )}
      {toggleState === `${TABS.ISSUES}` && (
        <ApplicationIssuesList appData={allAppData.length} />
      )}
      {toggleState === `${TABS.VALUES}` && (
        <Card extra={'w-full h-full bg-white mt-3'}>
          <ValuesPage
            toggleState={toggleState}
            setToggleState={setToggleState}
            onRepoNameChange={setValuesRepoName}
            handleVerify={handleVerify}
            onSelectedRowsChange={handleSelectedRows}
            valuesData={valuesData}
            loading={valuesLoading}
            setLoader={setValuesLoading}
          />
        </Card>
      )}
    </>
  )
}

export default OrderList
