import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { cn } from 'utils/cn'
import TooltipHorizon from 'components/tooltip'
import { timeAgo, formatDate } from 'common/uptimeKumaCommonFunctions'
import appConstants from 'common/config/appConstants'

const InfiniteScrollComponent = ({
  result,
  uptimeData,
  fetchMoreData,
  errorOccurred,
  uptimeKumaSkeletons,
  processedArray,
  isLoadingMoreInitialData,
}) => {
  const { UPTIME_KUMA_UI } = appConstants

  return (
    <>
      <div
        id="infiniteScrollable"
        className="h-[480px] overflow-auto 3xl:h-[680px]"
      >
        <InfiniteScroll
          dataLength={result?.length || 0}
          next={() => fetchMoreData(uptimeData?.options?.currentPage + 1)}
          hasMore={
            uptimeData?.options?.currentPage < uptimeData?.options?.totalPages
          }
          scrollableTarget="infiniteScrollable"
          loader={
            !errorOccurred &&
            (uptimeKumaSkeletons?.length > 0 ? (
              uptimeKumaSkeletons.map((_data, index) => (
                <div
                  key={index}
                  className="flex h-16 animate-pulse items-center gap-3 pb-4"
                >
                  <div className="h-6 w-28 rounded-xl bg-gray-200 dark:bg-gray-800"></div>
                  <div className="h-6 w-28 rounded-xl bg-gray-200 dark:bg-gray-800"></div>
                  <div className="ml-auto h-6 w-56 rounded-xl bg-gray-200 dark:bg-gray-800"></div>
                </div>
              ))
            ) : (
              <div className="text-center text-sm text-gray-500">
                <p>Loading...</p>
              </div>
            ))
          }
          scrollThreshold={0.8} // Load more when 80% scrolled
          endMessage={
            <p
              className={`text-md mt-2 text-center text-gray-600 ${
                result?.length > 8 ? '' : 'hidden'
              }`}
            >
              {UPTIME_KUMA_UI.REACHED_END}
            </p>
          }
        >
          {/* Render the data */}
          {processedArray?.map((item, index) =>
            item?.data?.length > 0 ? (
              <div className="flex h-16 items-center gap-3" key={index}>
                <div
                  className={cn(
                    'h-fit min-w-14 whitespace-nowrap rounded-[7px] px-2 text-center text-sm font-medium',
                    {
                      'bg-[#5CDD8B] text-[#020B05]':
                        item?.data[item.data.length - 1]?.status === 'up',
                      'bg-[#DC3545] text-white':
                        item?.data[item.data.length - 1]?.status === 'down',
                      'bg-[#F8A306] text-white':
                        item?.data[item.data.length - 1]?.status === 'pending',
                      'bg-gray-250 text-white': item?.isActive === false,
                    }
                  )}
                >
                  {`${item?.uptimePercentage || 0} %`}
                </div>

                <div
                  className={cn('min-w-72 grow whitespace-nowrap font-bold', {
                    'text-[#2B3674] dark:text-white': item?.isActive === true,
                    'text-gray-250': item?.isActive === false,
                  })}
                >
                  {item?.serviceName || 'Unknown Service'}
                </div>

                <div className="border-1 flex w-48p flex-col pr-6">
                  <div className="flex w-full justify-around gap-1">
                    {item?.data?.map((tick, tickIndex) => (
                      <TooltipHorizon
                        key={tickIndex}
                        content={!tick?.isEmpty && formatDate(tick?.date)}
                        placement="top"
                        extra={`border ${
                          tick?.status === 'up'
                            ? 'border-[#5CDD8B]'
                            : tick?.status === 'down'
                            ? 'border-[#DC3545]'
                            : 'border-[#F8A306]'
                        } ${item?.isActive === false && 'border-gray-250'} ${
                          item?.isActive &&
                          tick?.isEmpty &&
                          'border-black dark:border-white'
                        }`}
                        trigger={
                          <div
                            className={cn('h-6 w-2 rounded-sm', {
                              'bg-[#5CDD8B] hover:bg-[#5CDD8B]/60':
                                tick?.status === 'up',
                              'bg-[#DC3545] hover:bg-[#DC3545]/60':
                                tick?.status === 'down',
                              'bg-[#F8A306] hover:bg-[#F8A306]/60':
                                tick?.status === 'pending',
                              'bg-gray-250 hover:bg-gray-250':
                                item?.isActive === false || tick?.isDummy,
                              'border-[.5px] border-solid border-[#55565919] bg-[#55565919] hover:bg-[#55565919] dark:border-[#55565939] dark:bg-[#55565939] dark:hover:bg-[#55565939]':
                                item?.isActive && tick?.isEmpty,
                              'cursor-pointer':
                                item?.isActive && !tick?.isEmpty,
                            })}
                          />
                        }
                      />
                    ))}
                  </div>

                  <div className="flex justify-between">
                    <div className="text-xs font-medium text-[#A3AED0]">
                      {timeAgo(item?.data?.[0]?.date)}
                    </div>
                    <div className="text-xs font-medium text-[#A3AED0]">
                      {timeAgo(item?.data[item.data.length - 1]?.date)}
                    </div>
                  </div>
                </div>
              </div>
            ) : null
          )}
        </InfiniteScroll>
        {isLoadingMoreInitialData &&
          uptimeKumaSkeletons.map((_data, index) => (
            <div
              key={index}
              className="mr-8 flex h-16 animate-pulse items-center gap-3 pb-4"
            >
              <div className="h-6 w-28 rounded-xl bg-gray-200 dark:bg-gray-800"></div>
              <div className="h-6 w-28 rounded-xl bg-gray-200 dark:bg-gray-800"></div>
              <div className="ml-auto h-6 w-56 rounded-xl bg-gray-200 dark:bg-gray-800"></div>
            </div>
          ))}
      </div>
    </>
  )
}

export default InfiniteScrollComponent
