import React, { useState, useEffect } from 'react'
import AuthSignIn from 'views/auth/signIn/SignIn.jsx'
import SignUp from 'views/auth/signUp/SignUp'
import { useSpinner } from 'common/SpinnerLoader'
import apiConfig from 'common/config/apiConfig'
import SignInDefault from 'views/signIn/SignInDefault'
import appConstants from 'common/config/appConstants'

/**
 * Renders the SignIn component.
 *
 * @return {JSX.Element} The rendered SignIn component.
 */
export default function SignIn() {
  const { KEYCLOAK_INIT } = appConstants

  const { showSpinner, hideSpinner } = useSpinner()
  const [isSignIn, setIsSignIn] = useState(true)
  const [toGoogle, setToGoogle] = useState(false)
  const [loader, setLoader] = useState(false)
  const [darkmode, setDarkmode] = useState(localStorage.darkMode === 'true')

  // set the saved state of toGoogle to false to localStorage when the component mounts
  useEffect(() => {
    localStorage.setItem('toGoogle', 'false')
    setToGoogle(false)
  }, [])

  useEffect(() => {
    if (darkmode) document.body.classList.add('dark')
    else document.body.classList.remove('dark')
  }, [darkmode])

  // Save the toGoogle state to localStorage whenever it changes
  useEffect(() => {
    if (toGoogle) {
      localStorage.setItem('toGoogle', 'true')
    } else {
      localStorage.removeItem('toGoogle', 'false')
    }
  }, [toGoogle])

  const toggleView = () => {
    setIsSignIn(!isSignIn)
  }

  const handleGoogleClick = async () => {
    try {
      const apiUrl = apiConfig.SIGN_IN_USER_VIA_GOOGLE // API endpoint for Google OAuth
      window.location.href = apiUrl
      setToGoogle(true) // Set toGoogle sign-in mode
    } catch (error) {
      setToGoogle(false)
    }
  }

  const handleSetDarkmode = () => {
    const temp = !darkmode
    localStorage.setItem('darkMode', temp)
    setDarkmode(!darkmode)
  }

  return (
    <div>
      <div className="relative float-right h-full min-h-screen w-full dark:!bg-navy-900">
        <main className="mx-auto min-h-screen">
          {KEYCLOAK_INIT === 'true' ? (
            <SignInDefault
              setLoader={setLoader}
              setDarkmode={handleSetDarkmode}
              darkmode={darkmode}
            />
          ) : isSignIn ? (
            <AuthSignIn
              toggleView={toggleView}
              handleGoogleClick={handleGoogleClick}
              setLoader={setLoader}
              setDarkmode={handleSetDarkmode}
              darkmode={darkmode}
            />
          ) : (
            <SignUp
              toggleView={toggleView}
              handleGoogleClick={handleGoogleClick}
              setLoader={setLoader}
              setDarkmode={handleSetDarkmode}
              darkmode={darkmode}
            />
          )}
          {toGoogle || loader ? showSpinner() : hideSpinner()}
        </main>
      </div>
    </div>
  )
}
