import { useNavigate } from 'react-router-dom'
import { useToaster } from 'common/Toaster'
import appConstants from 'common/config/appConstants'
import ApiCaller from 'common/services/apiServices'
import apiConfig from 'common/config/apiConfig'
import { useEffect, useState, useCallback } from 'react'
import { getOrganization, handleBillingApiCall } from 'common/commonFunction'
import WelcomeCard from 'common/auth/WelcomeCard'

const { TOAST, SIGNIN } = appConstants
const apiService = ApiCaller()

/**
 * Function for signing in with Keycloak.
 *
 * @return {JSX.Element} The sign in component.
 */
function SignInDefault({ setLoader, setDarkmode, darkmode }) {
  const [myCode, setMyCode] = useState('')
  const [ignore, setIgnore] = useState(false)
  const { addToast } = useToaster()

  let navigate = useNavigate()

  /**
   * Asynchronously checks the license and handles the sign-in or navigation to the subscription expired page.
   *
   * @return {Promise<void>} Promise that resolves when the function completes.
   */
  const checkLicense = async () => {
    try {
      setLoader(true)
      const licenseAPIData = await handleBillingApiCall()
      if (licenseAPIData && licenseAPIData === 'Valid') {
        handleSignIn()
      } else if (licenseAPIData && licenseAPIData !== 'Valid') {
        navigate('/subscription-expired')
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      setLoader(false)
    }
  }

  const handleToken = useCallback(async () => {
    try {
      setLoader(true)
      let payload = {}
      payload = {
        code: myCode,
      }
      const apiUrl = apiConfig.CODE_SIGN_IN
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (
        response?.data?.code === 200 &&
        response?.data?.data?.token?.token !== ''
      ) {
        setIgnore(true)
        localStorage.setItem('bearerToken', response?.data?.data?.token?.token)
        const theme = localStorage.getItem('darkMode') || false
        localStorage.setItem('darkMode', theme)
        navigate('/')
        getOrganizationData()
        addToast({
          title: TOAST.MESSAGES.SUCCESS.signInSuccess,
          type: 'success',
        })
      } else {
        addToast({
          title: response?.data?.error || TOAST.MESSAGES.ERROR.swwError,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      setLoader(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myCode])

  const getOrganizationData = async () => {
    try {
      setLoader(true)
      const response = await getOrganization('auth')
      if (response?.data?.code === 200) {
        const responseData = response?.data?.data
        const org_Data = {
          org_name: responseData?.name,
          repo_name: responseData?.repoName,
          branch_name: responseData?.env?.map((env) => env.name),
          id: responseData?._id,
          github_token: responseData?.githubToken,
          cloud_type: responseData?.cloudType,
        }
        localStorage.setItem('organizationData', JSON.stringify(org_Data))
        localStorage.setItem(
          'platformType',
          JSON.stringify(responseData?.platformType)
        )
      } else {
        navigate(`/`)
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      setLoader(false)
    }
  }

  /**
   * A function that handles the sign in process.
   *
   * @param {} - no parameters
   * @return {} - no return value
   */
  const handleSignIn = () => {
    const keycloak_auth_url = appConstants.KEYCLOAK_AUTH_URL
    const response_type = appConstants.RESPONSE_TYPE
    const client_id = appConstants.CLIENT_ID
    const redirect_uri = appConstants.REDIRECT_URI
    const scope = appConstants.SCOPE
    const anchor = document.createElement('a')
    anchor.href = `${keycloak_auth_url}?response_type=${response_type}&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=${scope}`
    anchor.click()
  }

  useEffect(() => {
    const keyCloakUrl = window.location.href
    const myUrl = new URL(keyCloakUrl)
    const code = myUrl.searchParams.get('code')
    setMyCode(code)
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (myCode && !ignore) {
          const checkLicenseData = await handleBillingApiCall()
          if (checkLicenseData && checkLicenseData === 'Valid') {
            await handleToken() // Assuming handleToken is an async function
          } else if (checkLicenseData && checkLicenseData !== 'Valid') {
            navigate('/subscription-expired')
          }
        }
      } catch (error) {
        console.error('Error:', error)
      }
    }
    if (myCode !== null) {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myCode, ignore])

  return (
    <div className="h-screen bg-white dark:bg-navy-900 md:flex">
      <div className="hidden h-full w-full items-center justify-center bg-white py-10 dark:bg-navy-900 md:flex md:w-1/2">
        <div className="bg-white pb-40 dark:bg-navy-900">
          <div className="mb-18 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
            <div className="mt-[10vh]  w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
              <h3 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
                {SIGNIN.WELCOMETEXT}
              </h3>
              <button
                onClick={() => checkLicense()}
                className="w-full rounded-xl bg-brand-500 p-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700"
              >
                {SIGNIN.SIGNINBUTTONTEXT}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="relative flex h-full items-center justify-around overflow-hidden p-5 md:w-1/2">
        <WelcomeCard darkmode={darkmode} setDarkmode={setDarkmode} />
      </div>
    </div>
  )
}

export default SignInDefault
