import React, { useState } from 'react'
import Card from '../../../components/card'
import RadioServices from './components/RadioServices'
import appConstants from 'common/config/appConstants'
import { PiArrowSquareOutBold } from 'react-icons/pi'
import Switch from '../../../components/switch'

const CostOptimize = () => {
  const { REFERENCE_DOCUMENT } = appConstants

  const [previousCostOps, setPreviousCostOps] = useState('')
  const [selectedCostOps, setSelectedCostOps] = useState('')
  const [isSwitchEnabled, setIsSwitchEnabled] = useState(true)
  const [isNew, setIsNew] = useState(true)

  const handleConfigureClick = () => {
    window.open(REFERENCE_DOCUMENT.COST_OPTIMIZE_URL, '_blank')
  }

  const handleToggleSwitch = async () => {
    setIsSwitchEnabled(!isSwitchEnabled)
    // await callApi({ status: !isSwitchEnabled })
  }
  return (
    <>
      {REFERENCE_DOCUMENT.COST_OPTIMIZE_URL && (
        <div className="flex items-center">
          <p
            className="flex items-center text-brand-500 hover:cursor-pointer dark:text-brand-400"
            onClick={handleConfigureClick}
          >
            <span className="mx-2 my-3 text-brand-500 dark:text-brand-400">
              {REFERENCE_DOCUMENT.COST_OPTIMIZE_TEXT}
            </span>
            <span className="">
              <PiArrowSquareOutBold />
            </span>
          </p>
        </div>
      )}
      <Card extra="h-full mx-auto pb-3">
        <div className="flex h-full w-full flex-col gap-4 px-3 pt-6 md:px-8">
          {!isNew && (
            <div className="flex w-full items-center justify-end gap-4">
              <label className="text-sm font-bold text-navy-700 dark:text-white">
                Status
              </label>
              <Switch
                checked={isSwitchEnabled}
                onChange={() => handleToggleSwitch(isSwitchEnabled)}
              />
            </div>
          )}
          <RadioServices
            setParentSelectedValue={setSelectedCostOps}
            previousCostOps={previousCostOps}
          />
        </div>
      </Card>
    </>
  )
}

export default CostOptimize
