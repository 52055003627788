import React, { useState, useEffect } from 'react'
import RadioServiceCard from 'components/radioServiceCard'
import CloudMonitoringSvg from 'assets/svg/cloud-monitoring.svg'
import CloudWatchSvg from 'assets/svg/cloud-watch.svg'
import AzureMonitorSvg from 'assets/svg/azure-monitor.svg'

const RadioServices = ({
  setParentSelectedValue,
  previousMonitoringService,
}) => {
  const [selectedValue, setSelectedValue] = useState('')
  const [isDisabled, setIsDisabled] = useState({
    cloudMonitoringGcp: true,
    amazonCloudWatch: true,
    azureMonitor: true,
  })

  const handleRadioChange = (value) => {
    setSelectedValue(value)
    setParentSelectedValue(value)
  }

  useEffect(() => {
    const defaultService = previousMonitoringService || 'cloudMonitoringGcp'
    setSelectedValue(
      defaultService === 'none' ? 'cloudMonitoringGcp' : defaultService
    )
    setParentSelectedValue(
      defaultService === 'none' ? 'cloudMonitoringGcp' : defaultService
    )

    // Dynamically update disabled state
    const updatedDisabledState =
      defaultService === 'none'
        ? {
            cloudMonitoringGcp: false,
            amazonCloudWatch: false,
            azureMonitor: false,
          }
        : {
            cloudMonitoringGcp: defaultService !== 'cloudMonitoringGcp',
            amazonCloudWatch: defaultService !== 'amazonCloudWatch',
            azureMonitor: defaultService !== 'azureMonitor',
          }

    setIsDisabled(updatedDisabledState)
  }, [previousMonitoringService, setParentSelectedValue])

  const services = [
    {
      value: 'cloudMonitoringGcp',
      imageSrc: CloudMonitoringSvg,
      label: 'Cloud Monitoring (GCP)',
    },
    {
      value: 'amazonCloudWatch',
      imageSrc: CloudWatchSvg,
      label: 'CloudWatch (AWS)',
    },
    {
      value: 'azureMonitor',
      imageSrc: AzureMonitorSvg,
      label: 'Azure Monitor',
    },
  ]

  return (
    <div>
      <p className="text-xl font-bold text-navy-900 dark:text-white">
        Choose Your Service <span className="text-sm text-red-500">*</span>
      </p>
      <div className="col-span-2 my-3 flex flex-wrap md:gap-4 lg:col-span-1">
        {services.map((service) => (
          <RadioServiceCard
            key={service.value}
            isDisabled={isDisabled[service.value]}
            selectedValue={selectedValue}
            value={service.value}
            onRadioChange={handleRadioChange}
            imageSrc={service.imageSrc}
            label={service.label}
          />
        ))}
      </div>
    </div>
  )
}

export default RadioServices
