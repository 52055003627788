import React from 'react'

const Pagination = ({ usersData, paginationData, onPageChange }) => {
  const { limit, pageNumber, totalUsers, totalPages } = paginationData

  const changePage = (newPage) => {
    onPageChange(newPage)
  }

  return (
    <div>
      {usersData?.length > 0 && (
        <div className="mb-2 mt-2 flex h-12 w-full items-center justify-between px-6 sm-max:h-fit sm-max:flex-col sm-max:gap-4">
          <div className="text-sm text-gray-700">
            Showing {(pageNumber - 1) * limit + 1} to{' '}
            {totalUsers < (pageNumber - 1) * limit + usersData?.length
              ? totalUsers
              : (pageNumber - 1) * limit + usersData?.length}{' '}
            of {totalUsers} entries
          </div>
          <div className="flex items-center">
            <div className="ml-3 flex">
              <button
                className={`ml-3 h-10 w-10 rounded-full bg-brand-500 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
                  pageNumber === 1 ? 'cursor-not-allowed opacity-50' : ''
                }`}
                onClick={() => changePage(1)}
                disabled={pageNumber === 1}
              >
                {'<<'}
              </button>

              <button
                className={`ml-3 h-10 w-10 rounded-full bg-brand-500 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
                  pageNumber === 1 ? 'cursor-not-allowed opacity-50' : ''
                }`}
                onClick={() => changePage(pageNumber - 1)}
                disabled={pageNumber === 1}
              >
                {'<'}
              </button>

              <span
                className={`ml-3 flex h-10 w-10 items-center justify-center rounded-full bg-brand-500 text-lg text-white active:bg-brand-700 dark:bg-brand-400 dark:text-white ${
                  totalPages === 1 ? 'opacity-50' : ''
                }`}
              >
                {pageNumber}
              </span>

              <button
                className={`ml-3 h-10 w-10 rounded-full bg-brand-500 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
                  pageNumber >= totalPages
                    ? 'cursor-not-allowed opacity-50'
                    : ''
                }`}
                onClick={() => changePage(pageNumber + 1)}
                disabled={pageNumber >= totalPages}
              >
                {'>'}
              </button>

              <button
                className={`ml-3 h-10 w-10 rounded-full bg-brand-500 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
                  pageNumber >= totalPages
                    ? 'cursor-not-allowed opacity-50'
                    : ''
                }`}
                onClick={() => changePage(totalPages)}
                disabled={pageNumber >= totalPages}
              >
                {'>>'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Pagination
