import React, { useState, useEffect } from 'react'
import InputField from 'components/fields/InputField'
import DropZone from 'views/application/deployingWebService/components/DropZone'
import { useForm } from 'react-hook-form'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import { useToaster } from 'common/Toaster'
import appConstants from 'common/config/appConstants'
import { useSpinner } from 'common/SpinnerLoader'
import RadioServiceCard from 'components/radioServiceCard'
import { getArtifactDetails } from 'common/commonFunction'
import { IoMdCloudDownload } from 'react-icons/io'
import { IoMdEye } from 'react-icons/io'
import TooltipHorizon from '../../../../components/tooltip'
import GoogleCloudSvg from 'assets/svg/google-cloud.svg'
import AwsSvg from 'assets/svg/aws-logo.svg'
import AzureCloudSvg from 'assets/svg/azure-cloud.svg'
import DockerSvg from 'assets/svg/docker-icon.svg'
import OtherCloudSvg from 'assets/svg/other-cloud.svg'

const apiService = ApiCaller()
const { TOAST, BUTTON_TEXT, LABEL, TOOLTIPS } = appConstants
const artifactSkeletons = Array.from({ length: 2 })

/**
 * A form component for artifact repository creation.
 *
 * @param {type} paramName - description of parameter
 * @return {type} description of return value
 */
const AritfactRepoForm = () => {
  const { addToast } = useToaster()
  const { showSpinner, hideSpinner } = useSpinner()
  const [artifactExists, setArtifactExists] = useState(true)
  const [artifactData, setArtifactData] = useState('')
  const [isVisible, setIsVisible] = useState(false)
  const [selectedOption, setSelectedOption] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const organizationData = JSON.parse(localStorage.getItem('organizationData'))
  const [isAPILoading, setAPILoading] = useState(false)
  const [selectedCloud, setSelectedCloud] = useState('')
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
    reset,
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      G_PROJECT_ID: '',
      G_ARTIFACT_PATH: '',
      GIT_HUB_ID: '',
      ACCESS_KEY_ID: '',
      REGION: '',
      G_SERVICE_KEY: [],
    },
  })

  const [isDisabled, setIsDisabled] = useState({
    gcp: true, // Default values to avoid null errors
    aws: true,
    azure: true,
    dockerhub: true,
    custom: true,
  })

  useEffect(() => {
    const defaultService = selectedOption || 'gcp'
    setSelectedOption(defaultService)

    const updatedDisabledState =
      defaultService === 'none'
        ? {
            gcp: false,
            aws: false,
            azure: false,
            dockerhub: false,
            custom: false,
          }
        : {
            gcp: defaultService !== 'gcp',
            aws: defaultService !== 'aws',
            azure: defaultService !== 'azure',
            dockerhub: defaultService !== 'dockerhub',
            custom: defaultService !== 'custom',
          }

    setIsDisabled(updatedDisabledState)
  }, [artifactExists, selectedOption])

  /**
   * Toggles the visibility state by setting it to the opposite value.
   *
   * @return {void}
   */
  const handleRadioChange = (value) => {
    if (selectedCloud === value) {
      setValue('G_PROJECT_ID', artifactData?.projectId || '')
      setValue('G_ARTIFACT_PATH', artifactData?.artifactRepo || '')
      setValue('GIT_HUB_ID', artifactData?.ghUser || '')
      setValue('REGION', artifactData?.region || '')
      setValue('ACCESS_KEY_ID', artifactData?.accessKeyId || '')
      setValue('SECRET_KEY_ID', artifactData?.secretKeyId || '')
    } else {
      reset()
      setIsVisible(false)
    }
    setSelectedOption(value)
  }

  const handleToggleVisibility = () => {
    setIsVisible(!isVisible)
  }

  watch('G_SERVICE_KEY')

  useEffect(() => {
    getArtifactData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    switch (selectedCloud) {
      case 'aws':
        setSelectedOption('aws')
        break
      case 'gcp':
        setSelectedOption('gcp')
        break
      case 'azure':
        setSelectedOption('azure')
        break
      case 'dockerhub':
        setSelectedOption('dockerhub')
        break
      case 'custom':
        setSelectedOption('custom')
        break
      default:
        setSelectedOption('gcp') // Default to 'gcp' if no match
        break
    }
  }, [selectedCloud])

  useEffect(() => {
    if (artifactExists) {
      setValue('G_PROJECT_ID', artifactData?.projectId || '')
      setValue('G_ARTIFACT_PATH', artifactData?.artifactRepo || '')
      setValue('GIT_HUB_ID', artifactData?.ghUser || '')
      setValue('REGION', artifactData?.region || '')
      setValue('ACCESS_KEY_ID', artifactData?.accessKeyId || '')
      setValue('SECRET_KEY_ID', artifactData?.secretKeyId || '')
    }
  }, [artifactExists, artifactData, setValue])

  const handleGoogleServiceKeyDrop = (files) => {
    setValue('G_SERVICE_KEY', files)
  }

  const removeImage = (event) => {
    event.preventDefault()
    setValue('G_SERVICE_KEY', [])
  }

  /**
   * Creates a new FormData object and appends various form values to it.
   *
   * @param {object} formValue - The form values to be appended to the FormData object.
   * @return {FormData} The FormData object with the appended form values.
   */
  const createFormData = (formValue) => {
    const data = new FormData()
    data.append('projectId', formValue.G_PROJECT_ID)
    data.append('artifactRepo', formValue.G_ARTIFACT_PATH)
    data.append('ghUser', formValue.GIT_HUB_ID)
    data.append('region', formValue.REGION)
    data.append('cloud', selectedOption)

    if (selectedOption === 'gcp' && formValue.G_SERVICE_KEY.length > 0) {
      data.append('googleApplicationCredentials', formValue.G_SERVICE_KEY[0])
    }
    if (selectedOption === 'aws') {
      data.append('accessKeyId', formValue.ACCESS_KEY_ID)
      data.append('secretKeyId', formValue.SECRET_KEY_ID)
    }

    return data
  }

  /**
   * This function handles form submission by resetting the form submission state, creating form data, and creating an artifact repository.
   *
   * @param {type} formValue - the value of the form
   * @return {type} undefined
   */
  const onFormSubmit = (formValue) => {
    let data = createFormData(formValue)
    createArtifactRepository(data)
  }

  const downloadArtifactData = async () => {
    try {
      showSpinner()
      const apiUrl = apiConfig.DOWNLOAD_ARTIFACT_DATA.replace(
        ':organization_id',
        organizationData?.id
      )
      const response = await apiService.apiCall('get', apiUrl, {
        responseType: 'blob',
      })
      if (response.status === 200) {
        const blob = new Blob([response.data], { type: 'application/json' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'google-service-key.json')
        document.body.appendChild(link)
        link.click()
        link.remove()
        const file = new File([blob], 'google-service-key.json', {
          type: 'application/json',
        })
        setValue('G_SERVICE_KEY', [file])
      } else {
        addToast({
          title: 'Unable to fetch Artifact Data',
          type: 'error',
        })
      }
    } catch (error) {
      console.error('Error downloading artifact data:', error)
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      hideSpinner()
    }
  }

  const getArtifactData = async () => {
    try {
      setAPILoading(true)
      const response = await getArtifactDetails()
      if (response?.data?.code === 200) {
        setArtifactExists(true)
        setArtifactData(response?.data?.data)
        setSelectedCloud(response?.data?.data.cloud)
        setIsVisible(true) // Show data when successful
      } else if (response?.data?.code === 500) {
        setArtifactExists(false)
        setIsVisible(false)
        addToast({
          title: response?.data?.msg,
          type: 'error',
        })
      } else {
        setArtifactExists(false)
        setIsVisible(false)
      }
    } catch (error) {
      setArtifactExists(false)
      setIsVisible(false)
    } finally {
      setAPILoading(false)
    }
  }

  /**
   * Asynchronous function to create an artifact repository.
   *
   * @param {Object} formData - the data to be sent to the API
   * @return {Promise} a Promise representing the result of the API call
   */
  const createArtifactRepository = async (formData) => {
    try {
      showSpinner()
      const apiUrl = apiConfig.ARTIFACT_REPOSITORY.replace(
        ':organization_id',
        organizationData?.id
      )
      const response = await apiService.apiCall('post', apiUrl, formData)
      if (response?.data?.code === 200) {
        reset() // Reset Form
        addToast({
          title:
            response?.data?.msg ||
            TOAST.MESSAGES.SUCCESS.artifactRepositorySuccess,
          type: 'success',
        })
        getArtifactData()
      } else {
        addToast({
          title: TOAST.MESSAGES.ERROR.swwError,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      hideSpinner()
    }
  }

  const updateArtifactForm = async (formData) => {
    try {
      showSpinner()
      const apiUrl = apiConfig.UPDATE_ARTIFACT_FORM.replace(
        ':organization_id',
        organizationData?.id
      )
      const response = await apiService.apiCall('put', apiUrl, formData)
      if (response?.data?.code === 200) {
        addToast({
          title: TOAST.MESSAGES.SUCCESS.artifactUpdateSuccess,
          type: 'success',
        })
        getArtifactData()
      } else {
        addToast({
          title: TOAST.MESSAGES.ERROR.swwError,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      hideSpinner()
    }
  }

  const services = [
    {
      value: 'gcp',
      imageSrc: GoogleCloudSvg,
      label: LABEL.G_CLOUD,
    },
    {
      value: 'aws',
      imageSrc: AwsSvg,
      label: LABEL.AWS_CLOUD,
    },
    {
      value: 'azure',
      imageSrc: AzureCloudSvg,
      label: LABEL.AZURE_CLOUD,
    },
    {
      value: 'dockerhub',
      imageSrc: DockerSvg,
      label: LABEL.DOCKERHUB,
    },
    {
      value: 'custom',
      imageSrc: OtherCloudSvg,
      label: LABEL.OTHER_CLOUD,
    },
  ]

  return (
    <div className="h-full w-full rounded-[20px] px-3 pt-0 md:px-8">
      {isAPILoading ? (
        <div className="flex animate-pulse flex-col gap-y-8 p-6">
          <div className="grid grid-cols-2 gap-1">
            <div className="h-16 w-32 rounded-xl bg-gray-200 dark:bg-gray-800"></div>
            <div className="h-16 w-32 rounded-xl bg-gray-200 dark:bg-gray-800"></div>
          </div>
          <div className="flex flex-col gap-y-4">
            {artifactSkeletons.map((_item, index) => (
              <div key={index} className="flex gap-x-4">
                <div className="flex w-full flex-col gap-y-2">
                  <div className="h-2 w-1/4 rounded bg-gray-200 dark:bg-gray-800"></div>
                  <div className="h-8 rounded-xl bg-gray-200 dark:bg-gray-800"></div>
                </div>
                <div className="flex w-full flex-col gap-y-2">
                  <div className="h-2 w-1/4 rounded bg-gray-200 dark:bg-gray-800"></div>
                  <div className="h-8 rounded-xl bg-gray-200 dark:bg-gray-800"></div>
                </div>
              </div>
            ))}
          </div>
          <div className="flex w-full flex-col gap-y-2">
            <div className="h-2 w-1/4 rounded bg-gray-200 dark:bg-gray-800"></div>
            <div className="h-8 rounded-xl bg-gray-200 dark:bg-gray-800"></div>
          </div>
          <div className="h-8 w-32 self-end rounded-xl bg-gray-200 dark:bg-gray-800"></div>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <div className="mt-4 text-start">
            <p className="text-xl font-bold text-navy-900 dark:text-white">
              Choose Your Service{' '}
              <span className="text-sm text-red-500">*</span>
            </p>
            <div className="col-span-2 mb-10 mt-3 flex flex-wrap md:gap-4 lg:col-span-1">
              {services.map((service) => (
                <RadioServiceCard
                  key={service.value}
                  isDisabled={isDisabled[service.value]}
                  selectedValue={selectedOption}
                  value={service.value}
                  onRadioChange={handleRadioChange}
                  imageSrc={service.imageSrc}
                  label={service.label}
                />
              ))}
            </div>
            <div className="my-2 grid grid-cols-2 gap-1">
              <div className="col-span-2 lg:col-span-1">
                <InputField
                  extra="mb-4 w-full"
                  label={LABEL.PROJECT_ID}
                  id="gProjectID"
                  type="text"
                  placeholder="Enter Project ID"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  registrationProps={register('G_PROJECT_ID', {
                    required: 'Project ID is required',
                  })}
                  isFieldRequired={true}
                  state={errors.G_PROJECT_ID && 'error'}
                />
                <div className="error-message text-right">
                  {errors.G_PROJECT_ID?.message}
                </div>
              </div>

              <div className="col-span-2 lg:col-span-1">
                <InputField
                  extra="mb-4 w-full"
                  id="gitHubId"
                  type="text"
                  label={LABEL.ARTIFACT_PATH}
                  isFieldRequired={true}
                  infoIcon={true}
                  infoData={TOOLTIPS.ARTIFACTS_INFO}
                  placeholder="Enter Artifact Path"
                  registrationProps={register('G_ARTIFACT_PATH', {
                    required: 'Artifact path is required',
                  })}
                  state={errors.G_ARTIFACT_PATH && 'error'}
                />
                <div className="error-message text-right">
                  {errors.G_ARTIFACT_PATH?.message}
                </div>
              </div>
              <div className="col-span-2 lg:col-span-1">
                <InputField
                  extra="mb-4 w-full"
                  id="gitUserId"
                  type="text"
                  label={LABEL.GITHUB_ID}
                  isFieldRequired={true}
                  placeholder="Enter User's Github ID"
                  registrationProps={register('GIT_HUB_ID', {
                    required: 'Users Github User ID is required',
                  })}
                  state={errors.GIT_HUB_ID && 'error'}
                />
                <div className="error-message text-right">
                  {errors.GIT_HUB_ID?.message}
                </div>
              </div>
              <div className="col-span-2 lg:col-span-1">
                <InputField
                  extra="mb-4 w-full"
                  id="REGION"
                  type="text"
                  label={LABEL.REGION}
                  placeholder="Enter Region"
                  isFieldRequired={true}
                  registrationProps={register('REGION', {
                    required: 'Region is required',
                  })}
                  state={errors.REGION && 'error'}
                />
                <div className="error-message text-right">
                  {errors.REGION?.message}
                </div>
              </div>
            </div>

            {selectedOption === 'gcp' && (
              <div className="relative col-span-2 lg:col-span-1">
                <div className="mb-3 flex items-center justify-between">
                  <div className="text-sm font-bold">
                    {LABEL.G_SERVICE_KEY}{' '}
                    <span className="text-red-500">*</span>
                  </div>
                  {isVisible && (
                    <button
                      className="mr-4 flex w-28 cursor-pointer items-center rounded-xl bg-blueSecondary px-4 py-2 text-sm font-medium capitalize text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                      onClick={() => downloadArtifactData()}
                      type="button"
                    >
                      {BUTTON_TEXT.DOWNLOAD}
                      <IoMdCloudDownload className="ml-1" />
                    </button>
                  )}
                </div>
                {isVisible && artifactExists && (
                  <div className="relative flex flex-col overflow-hidden rounded-[20px] bg-white bg-clip-border px-[38px] py-[25px] shadow-3xl shadow-shadow-100 dark:bg-navy-700 dark:text-white dark:shadow-none">
                    <div className="flex w-full items-center justify-between text-blueSecondary dark:text-brand-400">
                      <p className="text-md dark:text-white">
                        {artifactData?.googleApplicationCredentials?.fileName}
                      </p>
                      <button onClick={handleToggleVisibility}>
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 24 24"
                          className="text-xl"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                )}
                {!isVisible && (
                  <DropZone
                    message="Drag & drop your Google Service key files"
                    isArtifactForm={false}
                    onDrop={handleGoogleServiceKeyDrop}
                    selectedFile={getValues('G_SERVICE_KEY')}
                    removeFile={removeImage}
                    errors={errors.G_SERVICE_KEY}
                    registrationProps={register('G_SERVICE_KEY', {
                      validate: (value) =>
                        value?.length || 'Google Service Key File is required',
                    })}
                    accept={{ 'application/json': ['.json'] }}
                    multiple={false}
                  />
                )}
                {!isVisible && (
                  <div className="error-message mb-2 mt-4 text-end">
                    {!getValues('G_SERVICE_KEY')?.length &&
                      errors.G_SERVICE_KEY?.message}
                  </div>
                )}
              </div>
            )}

            {selectedOption === 'aws' && (
              <div className="my-2 grid grid-cols-2 gap-1">
                <div className="col-span-2 lg:col-span-1">
                  <InputField
                    extra="mb-4 w-full"
                    label={LABEL.ACCESS_KEY_ID}
                    id="gProjectID"
                    type="text"
                    placeholder="Enter Access Key ID"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    registrationProps={register('ACCESS_KEY_ID', {
                      required: 'Access Key ID is required',
                    })}
                    isFieldRequired={true}
                    state={errors.ACCESS_KEY_ID && 'error'}
                  />
                  <div className="error-message text-right">
                    {errors.ACCESS_KEY_ID?.message}
                  </div>
                </div>

                <div className="relative col-span-2 lg:col-span-1">
                  <InputField
                    extra="mb-4 w-full"
                    id="gitHubId"
                    type={showPassword ? 'text' : 'password'}
                    label={LABEL.SECRET_KEY_ID}
                    isFieldRequired={true}
                    placeholder="Enter Secret Key ID"
                    registrationProps={register('SECRET_KEY_ID', {
                      required: 'Secret Key ID is required',
                    })}
                    state={errors.SECRET_KEY_ID && 'error'}
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 flex items-center pr-[2%] pt-[3%]"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <TooltipHorizon
                      extra="border border-gray-200 dark:border-gray-700"
                      trigger={
                        <p className="flex cursor-pointer items-center rounded-full border p-1 font-bold text-[#000] hover:font-medium hover:text-black dark:text-white">
                          <span>
                            <IoMdEye size={20} />
                          </span>
                        </p>
                      }
                      content="View"
                      placement="top"
                    />
                  </button>
                  <div className="error-message text-right">
                    {errors.SECRET_KEY_ID?.message}
                  </div>
                </div>
              </div>
            )}

            {selectedOption === 'azure' && (
              <div className="my-2 grid grid-cols-2 gap-1">
                <div className="col-span-2 lg:col-span-1">
                  <InputField
                    extra="mb-4 w-full"
                    label={LABEL.ACCESS_KEY_ID}
                    id="gProjectID"
                    type="text"
                    placeholder="Enter Access Key ID"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    registrationProps={register('ACCESS_KEY_ID', {
                      required: 'Access Key ID is required',
                    })}
                    isFieldRequired={true}
                    state={errors.ACCESS_KEY_ID && 'error'}
                  />
                  <div className="error-message text-right">
                    {errors.ACCESS_KEY_ID?.message}
                  </div>
                </div>

                <div className="relative col-span-2 lg:col-span-1">
                  <InputField
                    extra="mb-4 w-full"
                    id="gitHubId"
                    type={showPassword ? 'text' : 'password'}
                    label={LABEL.SECRET_KEY_ID}
                    isFieldRequired={true}
                    placeholder="Enter Secret Key ID"
                    registrationProps={register('SECRET_KEY_ID', {
                      required: 'Secret Key ID is required',
                    })}
                    state={errors.SECRET_KEY_ID && 'error'}
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 flex items-center pr-[2%] pt-[3%]"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <TooltipHorizon
                      extra="border border-gray-200 dark:border-gray-700"
                      trigger={
                        <p className="flex cursor-pointer items-center rounded-full border p-1 font-bold text-[#000] hover:font-medium hover:text-black dark:text-white">
                          <span>
                            <IoMdEye size={20} />
                          </span>
                        </p>
                      }
                      content="View"
                      placement="top"
                    />
                  </button>
                  <div className="error-message text-right">
                    {errors.SECRET_KEY_ID?.message}
                  </div>
                </div>
              </div>
            )}

            {selectedOption === 'dockerhub' && (
              <div className="my-2 grid grid-cols-2 gap-1">
                <div className="col-span-2 lg:col-span-1">
                  <InputField
                    extra="mb-4 w-full"
                    label={LABEL.ACCESS_KEY_ID}
                    id="gProjectID"
                    type="text"
                    placeholder="Enter Access Key ID"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    registrationProps={register('ACCESS_KEY_ID', {
                      required: 'Access Key ID is required',
                    })}
                    isFieldRequired={true}
                    state={errors.ACCESS_KEY_ID && 'error'}
                  />
                  <div className="error-message text-right">
                    {errors.ACCESS_KEY_ID?.message}
                  </div>
                </div>

                <div className="relative col-span-2 lg:col-span-1">
                  <InputField
                    extra="mb-4 w-full"
                    id="gitHubId"
                    type={showPassword ? 'text' : 'password'}
                    label={LABEL.SECRET_KEY_ID}
                    isFieldRequired={true}
                    placeholder="Enter Secret Key ID"
                    registrationProps={register('SECRET_KEY_ID', {
                      required: 'Secret Key ID is required',
                    })}
                    state={errors.SECRET_KEY_ID && 'error'}
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 flex items-center pr-[2%] pt-[3%]"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <TooltipHorizon
                      extra="border border-gray-200 dark:border-gray-700"
                      trigger={
                        <p className="flex cursor-pointer items-center rounded-full border p-1 font-bold text-[#000] hover:font-medium hover:text-black dark:text-white">
                          <span>
                            <IoMdEye size={20} />
                          </span>
                        </p>
                      }
                      content="View"
                      placement="top"
                    />
                  </button>
                  <div className="error-message text-right">
                    {errors.SECRET_KEY_ID?.message}
                  </div>
                </div>
              </div>
            )}

            {selectedOption === 'custom' && (
              <div className="my-2 grid grid-cols-2 gap-1">
                <div className="col-span-2 lg:col-span-1">
                  <InputField
                    extra="mb-4 w-full"
                    label={LABEL.ACCESS_KEY_ID}
                    id="gProjectID"
                    type="text"
                    placeholder="Enter Access Key ID"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    registrationProps={register('ACCESS_KEY_ID', {
                      required: 'Access Key ID is required',
                    })}
                    isFieldRequired={true}
                    state={errors.ACCESS_KEY_ID && 'error'}
                  />
                  <div className="error-message text-right">
                    {errors.ACCESS_KEY_ID?.message}
                  </div>
                </div>

                <div className="relative col-span-2 lg:col-span-1">
                  <InputField
                    extra="mb-4 w-full"
                    id="gitHubId"
                    type={showPassword ? 'text' : 'password'}
                    label={LABEL.SECRET_KEY_ID}
                    isFieldRequired={true}
                    placeholder="Enter Secret Key ID"
                    registrationProps={register('SECRET_KEY_ID', {
                      required: 'Secret Key ID is required',
                    })}
                    state={errors.SECRET_KEY_ID && 'error'}
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 flex items-center pr-[2%] pt-[3%]"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <TooltipHorizon
                      extra="border border-gray-200 dark:border-gray-700"
                      trigger={
                        <p className="flex cursor-pointer items-center rounded-full border p-1 font-bold text-[#000] hover:font-medium hover:text-black dark:text-white">
                          <span>
                            <IoMdEye size={20} />
                          </span>
                        </p>
                      }
                      content="View"
                      placement="top"
                    />
                  </button>
                  <div className="error-message text-right">
                    {errors.SECRET_KEY_ID?.message}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="mb-3 flex w-full items-center justify-end pt-5">
            {artifactExists ? (
              <button
                className="cursor-pointer rounded-xl bg-blueSecondary px-4 py-2 text-sm font-medium capitalize text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
                onClick={() => updateArtifactForm(createFormData(getValues()))}
                type="button"
              >
                {BUTTON_TEXT.UPDATE} {BUTTON_TEXT.REPOSITORY}
              </button>
            ) : (
              <button
                className="cursor-pointer rounded-xl bg-blueSecondary px-4 py-2 text-sm font-medium capitalize text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
                type="submit"
              >
                {BUTTON_TEXT.CONNECT} {BUTTON_TEXT.REPOSITORY}
              </button>
            )}
          </div>
        </form>
      )}
    </div>
  )
}

export default AritfactRepoForm
