import { io } from 'socket.io-client'
const SOCKET_URL = process.env.REACT_APP_API_URL.replace(/\/$/, '')
let socket

export const getSocket = () => {
  if (!socket) {
    socket = io(SOCKET_URL, { path: '/confixa-api/socket.io' })
  }
  return socket
}
