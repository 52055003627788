import React, { useState, useEffect } from 'react'
import RadioServiceCard from 'components/radioServiceCard'
import GithubSvg from 'assets/svg/github-logo.svg'
import BitbucketSvg from 'assets/svg/bitbucket.svg'
import GitlabSvg from 'assets/svg/gitlab-logo.svg'

const RadioForVersionControl = ({}) => {
  const [selectedValue, setSelectedValue] = useState('')

  const [isDisabled, setIsDisabled] = useState({
    github: true, // Default values to avoid null errors
    bitbucket: true,
    gitlab: true,
  })

  const [theme, setTheme] = useState(
    () => JSON.parse(localStorage.getItem('darkMode')) || false
  )

  const [initialPlatformType, setInitialPlatformType] = useState(() =>
    JSON.parse(localStorage.getItem('platformType'))
  )

  // Listen for custom event to update theme
  useEffect(() => {
    const updateTheme = () => {
      const darkModeValue = JSON.parse(localStorage.getItem('darkMode'))
      setTheme(darkModeValue)
    }

    // Listen for custom events (same tab)
    window.addEventListener('darkModeChange', updateTheme)

    // Cleanup listeners
    return () => {
      window.removeEventListener('darkModeChange', updateTheme)
    }
  }, [])

  const handleRadioChange = (value) => {
    setSelectedValue(value)
  }

  useEffect(() => {
    const defaultService = initialPlatformType || 'github'
    setSelectedValue(defaultService === 'none' ? 'github' : defaultService)

    const updatedDisabledState =
      defaultService === 'none'
        ? {
            github: false,
            bitbucket: false,
            gitlab: false,
          }
        : {
            github: defaultService !== 'github',
            bitbucket: defaultService !== 'bitbucket',
            gitlab: defaultService !== 'gitlab',
          }

    setIsDisabled(updatedDisabledState)
  }, [initialPlatformType])

  const services = [
    {
      value: 'github',
      imageSrc: GithubSvg,
      label: 'GitHub',
    },
    {
      value: 'bitbucket',
      imageSrc: BitbucketSvg,
      label: 'Bitbucket',
    },
    {
      value: 'gitlab',
      imageSrc: GitlabSvg,
      label: 'GitLab',
    },
  ]

  return (
    <div className="">
      <p className="text-navy-900 dark:text-white">Choose Your Service</p>
      <div className="col-span-2 my-3 flex flex-wrap md:gap-4 lg:col-span-1">
        {services.map((service) => (
          <RadioServiceCard
            key={service.value}
            isDisabled={isDisabled[service.value]}
            selectedValue={selectedValue}
            value={service.value}
            onRadioChange={handleRadioChange}
            imageSrc={service.imageSrc}
            label={service.label}
          />
        ))}
      </div>
    </div>
  )
}

export default RadioForVersionControl
