import React, { useState, useEffect } from 'react'
import InputField from '../../../../components/fields/InputField'
import { VscSend } from 'react-icons/vsc'
import OtpInput from './OtpInput'
import { useToaster } from '../../../../common/Toaster'
import ApiCaller from '../../../../common/services/apiServices'
import apiConfig from '../../../../common/config/apiConfig'
import { RxCross2 } from 'react-icons/rx'
import appConstants from '../../../../common/config/appConstants'

const ModalForVerifyUser = ({
  onClose,
  setIsVerified,
  setLoader,
  onVerfied,
}) => {
  const apiService = ApiCaller()
  const { TOAST, AUTH } = appConstants
  const { addToast } = useToaster()

  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [isResendEnabled, setIsResendEnabled] = useState(false)
  const [otp, setOtp] = useState(false)
  const [otpResetKey, setOtpResetKey] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isSendingOtp, setIsSendingOtp] = useState(false)
  const [emailSend, setEmailSend] = useState(false)
  const [isTimerActive, setIsTimerActive] = useState(false)
  const [timer, setTimer] = useState(180) // Set to 180 seconds (3 minutes)
  const [timeOtpResend, setTimeOtpResend] = useState(0)
  const [isOtpTimerActive, setIsOtpTimerActive] = useState(false)
  const [otpExpireTimer, setOtpExpireTimer] = useState(900) // Set to 900 seconds (15 minutes)

  const handleOtpComplete = async (rec_otp) => {
    try {
      setIsLoading(true)
      setLoader(true)

      const payload = { email, token: rec_otp }
      const apiUrl = apiConfig.VERIFY_PASSWORD_RESET_TOKEN
      const response = await apiService.apiCall('post', apiUrl, payload)

      if (response?.data?.code === 200) {
        setIsVerified(true)
        addToast({
          title:
            response?.data?.msg || TOAST.MESSAGES.SUCCESS.otpVerifiedSuccess,
          type: 'success',
        })
        onVerfied({
          emailAndToken: {
            email,
            token: rec_otp,
          },
          timer: {
            otpExpireTimer,
          },
        })
      } else {
        setOtpResetKey((prev) => prev + 1)
        addToast({
          title:
            response?.data?.error || TOAST.MESSAGES.ERROR.invalidTokenError,
          type: 'error',
        })
      }
    } catch (error) {
      console.error('Error verifying OTP:', error)
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      setLoader(false)
      setIsLoading(false)
    }
  }

  const handleEmailSubmit = async () => {
    if (email === '') {
      setEmailError(AUTH.EMAIL_REQUIRED_ERROR)
      return
    }

    const emailRegex = AUTH.EMAIL_REGEX

    if (!emailRegex.test(email)) {
      setEmailError(AUTH.EMAIL_ERROR)
      return
    }

    setEmailError('')

    try {
      setIsSendingOtp(true)
      setLoader(true)

      const payload = { emailId: email }
      const apiUrl = apiConfig.SEND_FORGET_MAIL
      const response = await apiService.apiCall('post', apiUrl, payload)

      if (response?.data?.code === 200) {
        setOtp(true)
        setEmailSend(true)
        setIsResendEnabled(false)
        setIsTimerActive(true)
        setTimer(AUTH.OTP_RESEND_TIMER)
        setIsOtpTimerActive(true)
        setOtpExpireTimer(AUTH.OTP_EXPIRY_TIMER)
        addToast({
          title: response?.data?.msg || TOAST.MESSAGES.SUCCESS.otpSendSuccess,
          type: 'success',
        })
      } else {
        addToast({
          title: response?.data?.error,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      setLoader(false)
      setIsSendingOtp(false)
    }
  }

  const handleResendOtp = async () => {
    if (!emailSend || timeOtpResend >= 3) {
      addToast({
        title: TOAST.MESSAGES.ERROR.resendLimitReachedError,
        type: 'error',
      })
      return
    }
    setTimeOtpResend((prev) => prev + 1)
    setIsResendEnabled(false)
    setIsTimerActive(true)
    setTimer(AUTH.OTP_RESEND_TIMER)
    handleEmailSubmit()
  }

  useEffect(() => {
    let interval

    if (
      (isTimerActive && timer > 0) ||
      (isOtpTimerActive && otpExpireTimer > 0)
    ) {
      interval = setInterval(() => {
        setTimer((prev) => (isTimerActive && prev > 0 ? prev - 1 : prev))
        setOtpExpireTimer((prev) =>
          isOtpTimerActive && prev > 0 ? prev - 1 : prev
        )
      }, 1000)
    }

    if (timer === 0) {
      setIsResendEnabled(true)
      setIsTimerActive(false)
    }

    if (otpExpireTimer === 0) {
      onClose()
      addToast({
        title: TOAST.MESSAGES.ERROR.sessionExpiredError,
        type: 'error',
      })
    }

    return () => clearInterval(interval)
  }, [isTimerActive, timer, isOtpTimerActive, otpExpireTimer])

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="fixed inset-0 bg-black !opacity-30"></div>
      <div className="z-10 flex w-full max-w-[550px] flex-col rounded-[20px] bg-white py-6 shadow-lg dark:bg-navy-900">
        <div className="flex justify-between px-8 pb-2">
          <p className="text-3xl font-bold text-navy-700 dark:text-white">
            Forgot Your Password?
          </p>
          <span className="flex items-center justify-center">
            <RxCross2
              size={30}
              className="text-black hover:cursor-pointer dark:text-white"
              onClick={onClose}
            />
          </span>
        </div>
        <div className="my-4 border-b border-gray-200 dark:border-[#6a7489]"></div>
        <div className="px-8">
          <p className="mb-4 ml-1 mt-[10px] text-base text-gray-600">
            {AUTH.FORGOT_PASSWORD_TEXT}
          </p>
          <div className="relative">
            <InputField
              variant="auth"
              extra="dark:text-white"
              label="Email"
              placeholder="mail@simmmple.com"
              id="email"
              type="email"
              value={email}
              isFieldRequired={!emailSend}
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={(e) => {
                if (
                  (e.key === 'Enter' || e.key === 'Return') &&
                  !isSendingOtp
                ) {
                  handleEmailSubmit()
                }
              }}
              disabled={isSendingOtp || emailSend}
            />
            {!emailSend && (
              <VscSend
                size={20}
                className={`absolute right-4 top-11 font-black text-brand-500 hover:cursor-pointer hover:text-brand-600 dark:text-white/80 dark:hover:text-white
              ${
                isSendingOtp || emailSend
                  ? 'pointer-events-none opacity-50'
                  : 'enabled'
              }`}
                onClick={!isSendingOtp ? handleEmailSubmit : undefined}
              />
            )}
            {emailError && (
              <span className="text-sm text-red-500">{emailError}</span>
            )}
            {emailSend ? (
              isResendEnabled ? (
                <span
                  className="float-right text-sm font-medium text-brand-500 hover:cursor-pointer hover:text-brand-600 dark:text-white"
                  onClick={!isSendingOtp ? handleResendOtp : undefined}
                >
                  Resend OTP
                </span>
              ) : (
                <span className="float-right text-sm font-medium text-gray-500">
                  Resend OTP in {Math.floor(timer / 60)}:
                  {('0' + (timer % 60)).slice(-2)}
                </span>
              )
            ) : null}
          </div>
          {otp && (
            <div className="mt-12 flex w-full flex-col items-center justify-center px-6">
              <OtpInput
                onComplete={handleOtpComplete}
                resetKey={otpResetKey}
                isLoading={isLoading}
              />
              {isOtpTimerActive && (
                <span className="float-right text-sm font-medium text-gray-500">
                  Seession Expires in {Math.floor(otpExpireTimer / 60)}:
                  {('0' + (otpExpireTimer % 60)).slice(-2)}
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ModalForVerifyUser
