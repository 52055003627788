import React, { useState } from 'react'
import RadioServices from './components/RadioServices'
import Card from '../../../components/card'

const DomainManagement = () => {
  const [previousDomain, setPreviousDomain] = useState('')
  const [selectedDomain, setSelectedDomain] = useState('')
  return (
    <>
      <Card extra="h-full mx-auto pb-3">
        <div className="flex h-full w-full flex-col gap-4 px-3 pt-6 md:px-8">
          <RadioServices
            setParentSelectedValue={setSelectedDomain}
            previousDomain={previousDomain}
          />
        </div>
      </Card>
    </>
  )
}

export default DomainManagement
