import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import Card from '../../../components/card'
import Switch from '../../../components/switch'
import { PiArrowSquareOutBold } from 'react-icons/pi'
import appConstants from 'common/config/appConstants'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import { useToaster } from 'common/Toaster'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import CustomSelect from '../../dashboards/default/components/CustomSelect'
import InputField from '../../../components/fields/InputField'
import RadioServices from './components/RadioServices'

const Observability = () => {
  const {
    TOAST,
    BUTTON_TEXT,
    SAMPLE_RATE_VALUE,
    DASHBOARD_VALUE,
    REFERENCE_DOCUMENT,
    NOTE,
  } = appConstants
  const apiService = ApiCaller()
  const { addToast } = useToaster()
  const [isSwitchEnabled, setIsSwitchEnabled] = useState(true)
  const [isNew, setIsNew] = useState(true)
  const [elasticData, setElasticData] = useState({})
  const [elasticId, setElasticId] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showApiKey, setShowApiKey] = useState(false)
  const [loader, setLoader] = useState(false)
  const [previousService, setPreviousService] = useState('')
  const [selectedService, setSelectedService] = useState('')

  const handleConfigureClick = () => {
    window.open(REFERENCE_DOCUMENT.ELASTIC_URL, '_blank')
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
    getValues,
    watch,
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      secrets: '',
      serverUrl: '',
      elasticSearchUrl: '',
      kibanaUrl: '',
      apiKey: '',
      password: '',
      sampleRate: '',
      isDashboard: '',
    },
  })

  const getElasticSettings = async () => {
    try {
      const apiUrl = apiConfig.GET_ELASTIC_DATA
      const response = await apiService.apiCall('get', apiUrl)
      if (response.data.code === 200) {
        response.data.data.sampleRate = {
          label: response.data.data.sampleRate,
          value: response.data.data.sampleRate,
        }
        response.data.data.isDashboard = {
          label: response.data.data.isDashboard,
          value: response.data.data.isDashboard,
        }
        reset(response.data.data)
        setElasticData(response.data.data)
        setElasticId(response.data.data._id)
        setIsSwitchEnabled(response.data.data.status)
        localStorage.setItem('isElastic', response.data.data.status)
        localStorage.setItem('kibanaUrl', response.data.data.kibanaUrl)
        localStorage.setItem(
          'isDashboard',
          response.data.data.isDashboard.value === 'Disable' ? false : true
        )
        localStorage.setItem('elasticId', response.data.data._id)
        setPreviousService(response.data.data?.service)
        setSelectedService(response.data.data?.service)
        setIsNew(false)
      } else {
        setIsNew(true)
      }
      return response
    } catch (error) {
      setIsNew(true)
    }
  }
  useEffect(() => {
    getElasticSettings()
  }, [])

  const sampleRateValues = watch('sampleRate')

  const isDashboardValues = watch('isDashboard')

  useEffect(() => {}, [sampleRateValues, isDashboardValues])

  const callApi = async (formValue, type) => {
    try {
      setLoader(true)

      if (type) {
        if (elasticData && elasticData._id) {
          // Prepare the comparison data
          const fieldsToCompare = [
            'secrets',
            'serverUrl',
            'sampleRate.value',
            'elasticSearchUrl',
            'apiKey',
            'password',
            'kibanaUrl',
            'isDashboard.value',
          ]

          // Compare formValue and elasticData by checking if they are equal for each field
          const isEqual = fieldsToCompare.every((field) => {
            const fieldPath = field.split('.') // In case of nested properties like 'sampleRate.value'
            const elasticFieldValue = fieldPath.reduce(
              (obj, key) => obj && obj[key],
              elasticData
            )
            const formFieldValue = fieldPath.reduce(
              (obj, key) => obj && obj[key],
              formValue
            )
            return elasticFieldValue === formFieldValue
          })

          if (isEqual) {
            addToast({
              title: TOAST.MESSAGES.INFO.updateElasticSettings,
              type: 'info',
            })
            return
          }
        }
        formValue.sampleRate = formValue.sampleRate.value
        formValue.isDashboard = formValue.isDashboard.value

        // adding service to formValue
        formValue.service = selectedService
      }

      const apiUrl = isNew
        ? apiConfig.CREATE_ELASTIC
        : apiConfig.UPDATE_ELASTIC.replace(':elasticId', elasticId)
      const method = isNew ? 'post' : 'patch'
      if (!isNew) delete formValue._id
      const response = await apiService.apiCall(method, apiUrl, formValue)

      if (response?.data?.code === 200) {
        addToast({
          title: isNew
            ? TOAST.MESSAGES.SUCCESS.elasticSave
            : TOAST.MESSAGES.SUCCESS.elasticUpdate,
          type: 'success',
        })
      } else {
        addToast({
          title: response?.data.error || TOAST.MESSAGES.ERROR.swwError,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({ title: TOAST.MESSAGES.ERROR.swwError, type: 'error' })
    } finally {
      setLoader(false)
      getElasticSettings()
    }
  }

  const onFormSubmit = async (formValue, event) => {
    event.preventDefault()
    await callApi(formValue, true)
  }

  const handleChange = (selectedOption) => {
    setValue('sampleRate', selectedOption)
  }
  const handleIsDashboardChange = (selectedOption) => {
    setValue('isDashboard', selectedOption)
  }
  const handleToggleSwitch = async () => {
    setIsSwitchEnabled(!isSwitchEnabled)
    await callApi({ status: !isSwitchEnabled })
  }

  return (
    <>
      {REFERENCE_DOCUMENT.ELASTIC_URL && (
        <div className="flex items-center">
          <div
            className="flex items-center text-brand-500 hover:cursor-pointer dark:text-brand-400"
            onClick={handleConfigureClick}
          >
            <span className="mx-2 my-3 text-brand-500 dark:text-brand-400">
              {REFERENCE_DOCUMENT.ELASTIC_TEXT}
            </span>
            <span className="">
              <PiArrowSquareOutBold />
            </span>
          </div>
        </div>
      )}
      <Card extra="h-full mx-auto pb-3">
        <div className="flex h-full w-full flex-col gap-4 rounded-[20px] px-3 pt-6 md:px-8">
          {!isNew && (
            <div className="flex w-full items-center justify-end gap-4">
              <label className="text-sm font-bold text-navy-700 dark:text-white">
                Status
              </label>
              <Switch
                checked={isSwitchEnabled}
                onChange={() => handleToggleSwitch(isSwitchEnabled)}
              />
            </div>
          )}
          <div>
            {!loader && (
              <div>
                <RadioServices
                  setParentSelectedValue={setSelectedService}
                  previousService={previousService}
                />
                <form onSubmit={handleSubmit(onFormSubmit)}>
                  <div className="mt-12">
                    <div className="flex justify-between">
                      <span
                        className="mb-4 text-left text-xl font-bold uppercase text-brand-500 dark:text-brand-400"
                        flex="1"
                      >
                        APM
                      </span>
                    </div>

                    <div>
                      <div className="flex gap-4">
                        <div className="w-1/2">
                          <InputField
                            label="Secrets"
                            type="text"
                            isFieldRequired={true}
                            id="secrets"
                            placeholder="Enter Secrets"
                            registrationProps={register('secrets', {
                              required: 'Secrets is required',
                            })}
                          />
                          {errors.secrets && (
                            <div className="text-sm text-red-500">
                              {errors.secrets?.message}
                            </div>
                          )}
                        </div>
                        <div className="h-full w-1/2">
                          <label htmlFor="" className="text-sm font-bold">
                            Sample Rate
                            <span className="ml-1 text-sm text-red-500">*</span>
                          </label>
                          <div className="text-lightFourth mt-1 w-full rounded-xl border border-gray-200 px-1.5 py-1 text-sm dark:!border-white/25 dark:bg-navy-800 dark:text-white">
                            <CustomSelect
                              options={SAMPLE_RATE_VALUE}
                              onChange={handleChange}
                              placeholderText="Select Sample Rate"
                              value={getValues('sampleRate')}
                              registrationProps={register('sampleRate', {
                                required: 'Sample Rate is required',
                              })}
                            />
                          </div>
                          {errors.sampleRate && (
                            <div className="text-sm text-red-500">
                              {errors.sampleRate.message}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="mt-3 w-full">
                        <InputField
                          label="Server Url"
                          type="text"
                          isFieldRequired={true}
                          id="serverUrl"
                          placeholder="Enter Server Url"
                          registrationProps={register('serverUrl', {
                            required: 'Server Url is required',
                          })}
                        />
                        {errors.serverUrl && (
                          <div className="text-sm text-red-500">
                            {errors.serverUrl?.message}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="my-12">
                    <div className="flex justify-between">
                      <span
                        className="mb-4 text-left text-xl font-bold uppercase text-brand-500 dark:text-brand-400"
                        flex="1"
                      >
                        Elastic
                      </span>
                    </div>
                    <div>
                      <div className="flex gap-4">
                        <div className="w-1/2">
                          <InputField
                            label="Elastic Search Url"
                            type="text"
                            isFieldRequired={true}
                            id="elasticSearchUrl"
                            placeholder="Enter Elastic Search Url"
                            registrationProps={register('elasticSearchUrl', {
                              required: 'Elastic Search Url is required',
                            })}
                          />
                          {errors.elasticSearchUrl && (
                            <div className="text-sm text-red-500">
                              {errors.elasticSearchUrl?.message}
                            </div>
                          )}
                        </div>
                        <div className="w-1/2">
                          <InputField
                            label="Kibana Url"
                            type="text"
                            isFieldRequired={true}
                            id="kibanaUrl"
                            placeholder="Enter Kibana Url"
                            registrationProps={register('kibanaUrl', {
                              required: 'Kibana Url is required',
                            })}
                          />
                          {errors.kibanaUrl && (
                            <div className="text-sm text-red-500">
                              {errors.kibanaUrl?.message}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="mt-3 flex gap-4">
                        <div className="w-1/2">
                          <div className="relative">
                            <InputField
                              label="API Key"
                              type={showApiKey ? 'text' : 'password'}
                              isFieldRequired={true}
                              id="apiKey"
                              placeholder="Enter API Key"
                              registrationProps={register('apiKey', {
                                required: 'API Key is required',
                              })}
                            />
                            <div
                              className="absolute right-4 top-11 cursor-pointer text-black dark:text-white"
                              onClick={() => setShowApiKey(!showApiKey)}
                            >
                              {showApiKey ? (
                                <AiOutlineEyeInvisible />
                              ) : (
                                <AiOutlineEye />
                              )}
                            </div>
                          </div>
                          {errors.apiKey && (
                            <div className="text-sm text-red-500">
                              {errors.apiKey?.message}
                            </div>
                          )}
                        </div>
                        <div className="w-1/2">
                          <div className="relative">
                            <InputField
                              label="Password"
                              type={showPassword ? 'text' : 'password'}
                              isFieldRequired={true}
                              id="password"
                              placeholder="Enter Password"
                              registrationProps={register('password', {
                                required: 'Password is required',
                              })}
                            />
                            <div
                              className="absolute right-4 top-11 cursor-pointer text-black dark:text-white"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? (
                                <AiOutlineEyeInvisible />
                              ) : (
                                <AiOutlineEye />
                              )}
                            </div>
                          </div>
                          {errors.password && (
                            <div className="text-sm text-red-500">
                              {errors.password?.message}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="mt-3 flex gap-4">
                        <div className="h-full w-1/2">
                          <label htmlFor="" className="text-sm font-bold">
                            Elastic Dashboard Status
                            <span className="ml-1 text-sm text-red-500">*</span>
                          </label>
                          <div className="text-lightFourth mt-1 w-full rounded-xl border border-gray-200 px-1.5 py-1 text-sm dark:!border-white/25 dark:bg-navy-800 dark:text-white">
                            <CustomSelect
                              options={DASHBOARD_VALUE}
                              onChange={handleIsDashboardChange}
                              placeholderText="Select Dashboard Status"
                              value={getValues('isDashboard')}
                              registrationProps={register('isDashboard', {
                                required:
                                  'Elastic dashboard status is required',
                              })}
                            />
                          </div>
                          <div className="text-xs font-medium text-gray-20 dark:text-gray-600">
                            {NOTE.ELASTIC_DASHBOARD_TEXT}
                          </div>
                          {errors.isDashboard && (
                            <div className="text-sm text-red-500">
                              {errors.isDashboard.message}
                            </div>
                          )}
                        </div>
                        <div className="w-1/2"></div>
                      </div>
                    </div>
                  </div>
                  {/* Submit & Reset Buttons  */}
                  <div className="mb-3 flex items-center justify-end">
                    {isNew ? (
                      <>
                        <button
                          type="reset"
                          onClick={() => reset()}
                          className="active:bg-gray-300dark:bg-gray-400 cursor-pointer rounded-xl bg-gray-300 px-7 py-3 text-sm font-medium capitalize text-black transition duration-200 hover:bg-gray-400 dark:hover:bg-gray-400 dark:active:opacity-90 md:ml-2"
                        >
                          {BUTTON_TEXT.RESET}
                        </button>
                        <button
                          type="submit"
                          className="cursor-pointer rounded-xl bg-blueSecondary px-7 py-3 text-sm font-medium capitalize text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
                        >
                          {BUTTON_TEXT.SAVE}
                        </button>
                      </>
                    ) : (
                      <button
                        type="submit"
                        className="cursor-pointer rounded-xl bg-blueSecondary px-7 py-3 text-sm font-medium capitalize text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
                      >
                        {BUTTON_TEXT.UPDATE}
                      </button>
                    )}
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </Card>
    </>
  )
}

export default Observability
