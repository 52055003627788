import { Routes, Route, useLocation, useNavigate } from 'react-router-dom'
import SignInLayout from 'layouts/signIn'
import 'assets/css/Plugins.css'
import { useState, useEffect } from 'react'
import ScrollToTop from 'common/ScrollToTop'
import SignInGuard from 'common/guard/signInGuard'
import AfterLoginLayout from 'layouts/afterLogin'
import { useTheme } from './contexts/ThemeContext'
import RoutesComponent from './routes'
import { getOrganization, handleBillingApiCall } from 'common/commonFunction'
import { useSpinner } from 'common/SpinnerLoader'
import { useToaster } from 'common/Toaster'
import appConstants from 'common/config/appConstants'
import SubscriptionPage from 'layouts/subscription'
import UserVerify from 'common/auth/userVerify'

/**
 * Create a new context and set up the theme state. When the theme state changes, this effect will update the CSS variables in the document's root element.
 *
 * @return {JSX.Element} The JSX for the entire component
 */
const App = () => {
  const routes = RoutesComponent()
  const { themeApp } = useTheme()
  const [mini, setMini] = useState(false)
  const location = useLocation() // Get the current location
  const navigate = useNavigate()
  const { addToast } = useToaster()
  const { TOAST, KEYCLOAK_INIT } = appConstants
  const { showSpinner, hideSpinner } = useSpinner()

  function doesPathExist(data, targetPath) {
    if (!data || data?.length === 0) {
      return true
    }

    for (const item of data) {
      if (item.path === targetPath) {
        return true // Path found
      }
      if (item.items && Array.isArray(item.items)) {
        if (doesPathExist(item.items, targetPath)) {
          return true // Path found in nested items
        }
      }
    }

    const allowedPaths = [
      '/continuous-delivery',
      '/system-health',
      '/observability-logs',
      '/observability-metrics',
      '/observability-traces',
    ]

    const dynamicPathPrefixes = [
      '/ticket-details',
      '/chatbot',
      '/new-service',
      '/application',
      '/deploying-service',
      '/web-services',
      '/applications',
      '/edit-project',
      '/status',
    ]

    if (allowedPaths.includes(targetPath)) {
      return true
    }

    if (dynamicPathPrefixes.some((prefix) => targetPath.startsWith(prefix))) {
      return true
    }

    return false
  }

  useEffect(() => {
    /**
     * Asynchronously fetches data and performs actions based on the response and error handling.
     *
     */
    const fetchData = async () => {
      try {
        showSpinner()
        if (
          location.pathname !== '/' &&
          location.pathname !== '/settings/kubernete-token' &&
          location.pathname !== '/ticket-create' &&
          location.pathname !== '/ticket-list' &&
          !location.pathname.startsWith('/ticket-details') &&
          location.pathname !== '/sign-in' &&
          location.pathname !== '/subscription-expired' &&
          location.pathname !== '/settings/organization' &&
          location.pathname !== '/oauthRedirect' &&
          !location.pathname.includes('web-services')
        ) {
          const licenseAPIData = await handleBillingApiCall()
          if (licenseAPIData && licenseAPIData === 'Valid') {
            const pathExists = doesPathExist(routes, location.pathname)
            if (pathExists) {
              const isOrganizationSet = getOrganizationDetails()
              isOrganizationSet.then((res) => {
                if (res?.data?.code !== 200) {
                  const restrictedPaths = [
                    '/finOps',
                    'continuous-delivery',
                    'system-health',
                    '/clusterDashboard',
                  ]

                  if (
                    restrictedPaths.some((path) =>
                      location.pathname.includes(path)
                    )
                  ) {
                    navigate('/')
                  } else if (location.pathname === '/iam') {
                    if (KEYCLOAK_INIT === 'true') {
                      navigate('/')
                    } else {
                      // Do nothing, stay on the same page
                    }
                  } else if (
                    location.pathname === '/not-found' ||
                    location.pathname.includes('observability')
                  ) {
                    // Do nothing, stay on the same page
                  } else {
                    navigate(`/settings/organization`)
                  }
                }
              })
            } else {
              navigate('/not-found')
            }
          } else if (licenseAPIData && licenseAPIData !== 'Valid') {
            const theme = localStorage.getItem('darkMode') || false
            localStorage.clear()
            localStorage.setItem('darkMode', theme)
            navigate('/subscription-expired')
          }
        }
      } catch (error) {
        addToast({
          title: TOAST.MESSAGES.ERROR.swwError,
          type: 'error',
        })
      } finally {
        hideSpinner()
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, addToast])

  // When the theme state changes, this effect will update the CSS variables in the document's root element
  useEffect(() => {
    let color
    for (color in themeApp) {
      document.documentElement.style.setProperty(color, themeApp[color])
    }
    //eslint-disable-next-line
  }, [themeApp])

  /**
   * Retrieves organization details asynchronously.
   *
   * @return {Promise} A promise that resolves to the organization details.
   */
  const getOrganizationDetails = async () => {
    try {
      const response = await getOrganization()
      return response
    } catch (error) {
      console.error('error-', error)
    }
  }

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={
            <AfterLoginLayout setMini={setMini} mini={mini} theme={themeApp} />
          }
        >
          {routes &&
            routes.map((route, index) => {
              if (route.collapse) {
                return route.items.map((nestedRoute, nestedIndex) => {
                  return (
                    <Route
                      key={nestedIndex}
                      path={nestedRoute.path}
                      element={nestedRoute.component}
                    />
                  )
                })
              } else {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.component}
                  />
                )
              }
            })}
        </Route>
        <Route path="/subscription-expired" element={<SubscriptionPage />} />
        <Route
          path="sign-in"
          element={
            <SignInGuard from="sign-in">
              <SignInLayout />
            </SignInGuard>
          }
        />
        <Route path="userVerify" element={<UserVerify />} />
      </Routes>
    </>
  )
}

export default App
