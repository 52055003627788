import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { PiArrowSquareOutBold } from 'react-icons/pi'
import { IoMdCloudDownload } from 'react-icons/io'
import appConstants from 'common/config/appConstants'
import Card from '../../../components/card'
import Switch from '../../../components/switch'
import InputField from '../../../components/fields/InputField'
import DropZone from '../../application/deployingWebService/components/DropZone'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import { useToaster } from 'common/Toaster'
import RadioServices from './components/RadioServices'

const Monitoring = () => {
  const { BUTTON_TEXT, TOAST, REFERENCE_DOCUMENT, NOTE, DISPLAY_MONITOR } =
    appConstants
  const apiService = ApiCaller()
  const { addToast } = useToaster()
  const navigate = useNavigate()
  const [isSwitchEnabled, setIsSwitchEnabled] = useState(true)
  const [isNew, setIsNew] = useState(true)
  const [monitoringData, setMonitoringData] = useState({})
  const [monitoringId, setMonitoringId] = useState('')
  const [loader, setLoader] = useState(false)
  const [previousMonitoringService, setPreviousMonitoringService] = useState('')
  const [selectedMonitoringService, setSelectedMonitoringService] = useState('')

  const handleConfigureClick = () => {
    window.open(REFERENCE_DOCUMENT.MONITORING_URL, '_blank')
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    getValues,
    setValue,
    watch,
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      GCP_Project_ID: '',
      GCP_Metrics_Url: '',
      M_SERVICE_KEY: [],
    },
  })

  const removeImage = (event) => {
    event.preventDefault()
    console.log('Removing image function is called')
    setValue('M_SERVICE_KEY', [])
    setMonitoringData((prev) => ({
      ...prev,
      serviceKey: '',
    }))
  }

  const handleServiceKeyDrop = (files) => {
    setValue('M_SERVICE_KEY', files)
  }

  const serviceKeyFile = watch('M_SERVICE_KEY')

  const getMonitoringSettings = async () => {
    try {
      const apiUrl = apiConfig.GET_MONITORING_DATA
      const response = await apiService.apiCall('get', apiUrl)
      if (response.data.code === 200) {
        const blobContent = new Blob(
          [JSON.parse(response.data.data.serviceKey)],
          { type: 'application/json' }
        )
        const fileContent = new File(
          [blobContent],
          response.data.data.fileName,
          {
            type: 'application/json',
          }
        )

        const setObj = {
          GCP_Metrics_Url: response.data.data.metricUrl,
          GCP_Project_ID: response.data.data.projectId,
          M_SERVICE_KEY: [fileContent],
        }
        response.data.data.fileObj = [fileContent]
        reset(setObj)
        setMonitoringData(response.data.data)
        setMonitoringId(response.data.data._id)
        setIsSwitchEnabled(response.data.data.status)
        localStorage.setItem('isMonitoring', response.data.data.status)
        localStorage.setItem('monitoringId', response.data.data._id)
        if (response.data.data.metricUrl) {
          localStorage.setItem('metricUrl', response.data.data.metricUrl)
        } else {
          if (localStorage.getItem('metricUrl')) {
            localStorage.setItem('metricUrl', null)
          }
        }

        if (response.data.data?.service) {
          setPreviousMonitoringService(response.data.data.service)
          setSelectedMonitoringService(response.data.data.service)
        }

        setIsNew(false)
      } else {
        setIsNew(true)
      }
      return response
    } catch (error) {
      setIsNew(true)
    }
  }

  useEffect(() => {
    if (DISPLAY_MONITOR === 'false') {
      navigate('/')
    }
    getMonitoringSettings()
  }, [])

  const callApi = async (formValue, type) => {
    try {
      setLoader(true)

      const requestObj = new FormData()
      if (type) {
        if (monitoringData && monitoringData._id) {
          let oneChanged = false
          if (formValue.GCP_Project_ID !== monitoringData.projectId) {
            oneChanged = true
            requestObj.append('projectId', formValue.GCP_Project_ID)
          }
          if (
            monitoringData.metricUrl !== '' &&
            monitoringData.metricUrl !== undefined &&
            monitoringData.metricUrl !== null
          ) {
            if (formValue.GCP_Metrics_Url !== monitoringData.metricUrl) {
              oneChanged = true
              requestObj.append('metricUrl', formValue.GCP_Metrics_Url)
            }
          } else {
            if (
              formValue.GCP_Metrics_Url !== '' &&
              formValue.GCP_Metrics_Url !== undefined &&
              formValue.GCP_Metrics_Url !== null
            ) {
              oneChanged = true
              requestObj.append('metricUrl', formValue.GCP_Metrics_Url)
            }
          }
          if (
            formValue.M_SERVICE_KEY[0].lastModified !==
            monitoringData.fileObj[0].lastModified
          ) {
            oneChanged = true
            requestObj.append('serviceKey', formValue.M_SERVICE_KEY[0])
          }
          if (!oneChanged) {
            addToast({
              title: TOAST.MESSAGES.INFO.updateMonitoringSettings,
              type: 'info',
            })
            return
          }
        } else {
          requestObj.append('serviceKey', formValue.M_SERVICE_KEY[0])
          requestObj.append('projectId', formValue.GCP_Project_ID)
          if (formValue.GCP_Metrics_Url) {
            requestObj.append('metricUrl', formValue.GCP_Metrics_Url)
          }
        }
      } else {
        requestObj.append('status', formValue.status)
      }

      // adding service to requestObj
      requestObj.append('service', selectedMonitoringService)

      const apiUrl = isNew
        ? apiConfig.CREATE_MONITORING
        : apiConfig.UPDATE_MONITORING.replace(':monitoringId', monitoringId)
      const method = isNew ? 'post' : 'patch'
      const response = await apiService.apiCall(method, apiUrl, requestObj)

      if (response?.data?.code === 200) {
        addToast({
          title: isNew
            ? TOAST.MESSAGES.SUCCESS.monitoringSave
            : TOAST.MESSAGES.SUCCESS.monitoringUpdate,
          type: 'success',
        })
      } else {
        addToast({
          title: response?.data.error || TOAST.MESSAGES.ERROR.swwError,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({ title: TOAST.MESSAGES.ERROR.swwError, type: 'error' })
    } finally {
      setLoader(false)
      getMonitoringSettings()
    }
  }

  const onFormSubmit = async (formValue, event) => {
    event.preventDefault()
    await callApi(formValue, true)
  }

  const handleToggleSwitch = async () => {
    setIsSwitchEnabled(!isSwitchEnabled)
    await callApi({ status: !isSwitchEnabled })
  }

  const downloadServiceKey = () => {
    if (!monitoringData.serviceKey) {
      return
    }
    try {
      setLoader(true)
      const downloadKey = JSON.parse(monitoringData.serviceKey)
      const blob = new Blob([downloadKey], { type: 'application/json' })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'monitoring-service-key.json')
      document.body.appendChild(link)
      link.click()
      link.remove()
    } catch (error) {
      console.error('Error downloading service key:', error)
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      setLoader(false)
    }
  }

  return (
    <>
      {REFERENCE_DOCUMENT.MONITORING_URL && (
        <div className="flex items-center">
          <p
            className="flex items-center text-brand-500 hover:cursor-pointer dark:text-brand-400"
            onClick={handleConfigureClick}
          >
            <span className="mx-2 my-3 text-brand-500 dark:text-brand-400">
              {REFERENCE_DOCUMENT.MONITORING_TEXT}
            </span>
            <span className="">
              <PiArrowSquareOutBold />
            </span>
          </p>
        </div>
      )}
      <Card extra="h-full mx-auto pb-3">
        <div className="flex h-full w-full flex-col gap-4 rounded-[20px] px-3 pt-6 md:px-8">
          {!isNew && (
            <div className="flex w-full items-center justify-end gap-4">
              <label className="text-sm font-bold text-navy-700 dark:text-white">
                Status
              </label>
              <Switch
                checked={isSwitchEnabled}
                onChange={() => handleToggleSwitch(isSwitchEnabled)}
              />
            </div>
          )}
          <RadioServices
            setParentSelectedValue={setSelectedMonitoringService}
            previousMonitoringService={previousMonitoringService}
          />
          {!loader && (
            <div className="h-full w-full pb-3">
              <form
                onSubmit={handleSubmit(onFormSubmit)}
                className="flex flex-col gap-4"
              >
                {/* GCP_Project_ID */}
                <div>
                  <InputField
                    isFieldRequired={true}
                    label="Project ID"
                    type="text"
                    id="GCP_Project_ID"
                    placeholder="Enter Project ID"
                    registrationProps={register('GCP_Project_ID', {
                      required: 'Project ID is required.',
                    })}
                  />
                  {errors.GCP_Project_ID && (
                    <div className="text-sm text-red-500">
                      {errors.GCP_Project_ID?.message}
                    </div>
                  )}
                </div>
                {/* Monitoring Service Key */}
                <div className="flex w-full flex-col gap-0">
                  <div className="flex items-center justify-between">
                    <div className="text-sm font-bold">
                      Monitoring Service Key
                      <span className="ml-1 text-sm text-red-500">*</span>
                    </div>
                    {!isNew && monitoringData?.serviceKey !== '' && (
                      <button
                        className="mr-4 flex w-28 cursor-pointer items-center rounded-xl bg-blueSecondary px-4 py-2 text-sm font-medium capitalize text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                        onClick={() => downloadServiceKey()}
                        type="button"
                      >
                        {BUTTON_TEXT.DOWNLOAD}
                        <IoMdCloudDownload className="ml-1" />
                      </button>
                    )}
                  </div>
                  <div className="">
                    {serviceKeyFile && serviceKeyFile.length === 0 ? (
                      <>
                        <DropZone
                          message="Drag and drop your Monitoring Service Key JSON file here to upload it."
                          isArtifactForm={false}
                          onDrop={handleServiceKeyDrop}
                          selectedFile={getValues('M_SERVICE_KEY')}
                          removeFile={removeImage}
                          errors={errors.M_SERVICE_KEY}
                          registrationProps={register('M_SERVICE_KEY', {
                            validate: (value) =>
                              value?.length ||
                              'Monitoring service key is required.',
                          })}
                          accept={{ 'application/json': ['.json'] }}
                          multiple={false}
                        />
                        {!getValues('M_SERVICE_KEY')?.length && (
                          <div className="text-sm text-red-500">
                            {errors?.M_SERVICE_KEY?.message}
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="relative mt-2 flex flex-col overflow-hidden rounded-[20px] bg-white bg-clip-border px-[38px] py-[25px] shadow-3xl shadow-shadow-100 dark:bg-navy-700 dark:text-white dark:shadow-none">
                        <div className="flex w-full items-center justify-between text-blueSecondary dark:text-brand-400">
                          <p className="text-md dark:text-white">
                            {getValues('M_SERVICE_KEY')[0].name}
                          </p>
                          <button type="button" onClick={removeImage}>
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 24 24"
                              className="text-xl"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path fill="none" d="M0 0h24v24H0z"></path>
                              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                            </svg>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* GCP Metrics Url */}
                <div>
                  <InputField
                    label="Metrics Url"
                    type="text"
                    id="GCP_Metrics_Url"
                    placeholder="Enter Metrics Url"
                    registrationProps={register('GCP_Metrics_Url')}
                  />
                  <div className="text-xs font-medium text-gray-20 dark:text-gray-600">
                    {NOTE.MONITORING_METRICS_URL}
                  </div>
                </div>
                {/* Submit & Reset Buttons  */}
                <div className="mt-4 flex items-center justify-end">
                  {isNew ? (
                    <>
                      <button
                        type="reset"
                        onClick={() => reset()}
                        className="active:bg-gray-300dark:bg-gray-400 cursor-pointer rounded-xl bg-gray-300 px-7 py-3 text-sm font-medium capitalize text-black transition duration-200 hover:bg-gray-400 dark:hover:bg-gray-400 dark:active:opacity-90 md:ml-2"
                      >
                        {BUTTON_TEXT.RESET}
                      </button>
                      <button
                        type="submit"
                        className="cursor-pointer rounded-xl bg-blueSecondary px-7 py-3 text-sm font-medium capitalize text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
                      >
                        {BUTTON_TEXT.SAVE}
                      </button>
                    </>
                  ) : (
                    <button
                      type="submit"
                      className="cursor-pointer rounded-xl bg-blueSecondary px-7 py-3 text-sm font-medium capitalize text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
                    >
                      {BUTTON_TEXT.UPDATE}
                    </button>
                  )}
                </div>
              </form>
            </div>
          )}
        </div>
      </Card>
    </>
  )
}

export default Monitoring
