import React, { useState, useEffect } from 'react'
import InputField from '../../../../components/fields/InputField'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { RxCross2 } from 'react-icons/rx'
import { useToaster } from '../../../../common/Toaster'
import appConstants from '../../../../common/config/appConstants'
import { useForm } from 'react-hook-form'

const ModalForCreateNewPassword = ({
  onClose,
  onSubmit,
  sessionExpireTimer = 0,
}) => {
  const { addToast } = useToaster()
  const { AUTH, TOAST } = appConstants

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [otpExpireTimer, setOtpExpireTimer] = useState(sessionExpireTimer)

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    mode: 'onChange', // Validates on change
  })

  const onFormSubmit = async (data, event) => {
    event.preventDefault()
    onSubmit(data?.confirmPassword)
  }

  useEffect(() => {
    let interval
    if (otpExpireTimer > 0) {
      interval = setInterval(() => setOtpExpireTimer((prev) => prev - 1), 1000)
    } else if (otpExpireTimer === 0) {
      onClose()
      addToast({
        title: TOAST.MESSAGES.ERROR.sessionExpiredError,
        type: 'error',
      })
    }

    return () => clearInterval(interval)
  }, [otpExpireTimer])

  const password = watch('password', '')

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="fixed inset-0 bg-black !opacity-30"></div>
      <div className="z-10 flex w-full max-w-[550px] flex-col rounded-[20px] bg-white py-6 shadow-lg dark:bg-navy-900">
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <div className="flex justify-between px-8 pb-2">
            <p className="text-3xl font-bold text-navy-700 dark:text-white">
              Reset Password
            </p>
            <span className="flex items-center justify-center">
              <RxCross2
                size={30}
                className="text-black hover:cursor-pointer dark:text-white"
                onClick={onClose}
              />
            </span>
          </div>
          <div className="my-4 border-b border-gray-200 dark:border-[#6a7489]"></div>
          <div className="px-8">
            <div className="flex flex-col">
              <div className="relative">
                <InputField
                  variant="auth"
                  extra=""
                  label="Password"
                  placeholder="Min. 8 characters"
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  isFieldRequired={true}
                  registrationProps={register('password', {
                    required: AUTH.PASSWORD_REQUIRED_ERROR,
                    pattern: {
                      value: AUTH.PASSWORD_REGEX,
                      message: AUTH.PASSWORD_ERROR,
                    },
                  })}
                />
                <div
                  className="absolute right-4 top-11 cursor-pointer text-black dark:text-white"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                </div>
              </div>
              {errors.password && (
                <span className="text-sm text-red-500">
                  {errors.password.message}
                </span>
              )}
              <div className="relative">
                <InputField
                  variant="auth"
                  extra="mt-3"
                  label="Confirm Password"
                  placeholder="Min. 8 characters"
                  id="confirm-password"
                  type={showConfirmPassword ? 'text' : 'password'}
                  isFieldRequired={true}
                  registrationProps={register('confirmPassword', {
                    required: AUTH.CONFIRM_PASSWORD_REQUIRED_ERROR,
                    validate: (value) =>
                      value === password || AUTH.CONFIRM_PASSWORD_ERROR,
                  })}
                />
                <div
                  className="absolute right-4 top-14 cursor-pointer text-black dark:text-white"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? (
                    <AiOutlineEyeInvisible />
                  ) : (
                    <AiOutlineEye />
                  )}
                </div>
              </div>
              {errors.confirmPassword && (
                <span className="text-sm text-red-500">
                  {errors.confirmPassword.message}
                </span>
              )}
              <div className="mt-3 flex items-center justify-between">
                <div className="justify-items-start">
                  {otpExpireTimer && (
                    <span className="float-right text-sm font-medium text-gray-500">
                      Session Expires in {Math.floor(otpExpireTimer / 60)}:
                      {('0' + (otpExpireTimer % 60)).slice(-2)}
                    </span>
                  )}
                </div>
                <div className="flex items-center justify-center gap-4">
                  <button
                    type="button"
                    className="rounded-md bg-gray-200 px-4 py-2 text-sm text-gray-700 hover:bg-gray-300"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="rounded-md bg-brand-500 px-4 py-2 text-sm font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ModalForCreateNewPassword
