import React from 'react'
import apiConfig from '../config/apiConfig'
import appConstants from '../config/appConstants'
import authImg from '../../assets/img/layers/confixa-logo.png'
import ThemeSwitch from './ThemeSwitch'

const WelcomeCard = (props) => {
  const { setDarkmode, darkmode } = props
  const { SIGNIN } = appConstants

  return (
    <>
      <div
        className={`absolute flex h-full w-full items-end justify-center bg-gradient-to-br from-brand-400 to-brand-600 bg-cover bg-center lg:rounded-bl-[120px] xl:rounded-bl-[200px]`}
      >
        <div className="flex h-full w-full flex-col items-center justify-center gap-32">
          <img src={authImg} className="mb-4 h-20 w-full object-contain" />
          <div className="text-1xl w-fit items-center  rounded-xl border border-gray-200 px-9 py-[30px] dark:border-white/50">
            <h6 className="mb-2 pb-1 text-center font-dm text-xl text-white dark:text-white">
              {SIGNIN.LEARNMORE}
            </h6>
            <div className="text-center">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${apiConfig.CONFIXA_APP_URL}`}
                className="text-xl font-bold  text-white dark:text-white"
              >
                {SIGNIN.DOMAIN}
              </a>
            </div>
          </div>
          <div className="absolute bottom-10 right-10 flex items-center justify-center gap-1 text-white">
            <ThemeSwitch darkmode={darkmode} setDarkmode={setDarkmode} />
          </div>
        </div>
      </div>
    </>
  )
}

export default WelcomeCard
