import InputField from '../../../components/fields/InputField'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { FcGoogle } from 'react-icons/fc'
import ModalForVerifyUser from './components/ModalForVerifyUser'
import ModalForCreateNewPassword from './components/ModalForCreateNewPassword'
import { useNavigate } from 'react-router-dom'
import { useToaster } from '../../../common/Toaster'
import {
  getOrganization,
  handleBillingApiCall,
  encryptPassword,
} from '../../../common/commonFunction'
import appConstants from '../../../common/config/appConstants'
import ApiCaller from '../../../common/services/apiServices'
import apiConfig from '../../../common/config/apiConfig'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import WelcomeCard from 'common/auth/WelcomeCard'

/**
 * Function for signing in with CURD.
 *
 * @return {JSX.Element} The sign in component.
 */
const AuthSignIn = ({
  toggleView,
  handleGoogleClick,
  setLoader,
  setDarkmode,
  darkmode,
}) => {
  const { TOAST, AUTH } = appConstants
  const apiService = ApiCaller()
  const { addToast } = useToaster()

  const [forgotPasswordModal, setForgotPasswordModal] = useState(false)
  const [isVerified, setIsVerified] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [token, setToken] = useState(null)
  const [sessionExpireTimer, setSessionExpireTimer] = useState(0)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit', // Validates on change
  })

  let navigate = useNavigate()

  const handleForgotPassword = () => {
    setForgotPasswordModal(true)
  }

  const getOrganizationData = async () => {
    try {
      setLoader(true)
      const response = await getOrganization('auth')
      if (response?.data?.code === 200) {
        const responseData = response?.data?.data
        const org_Data = {
          org_name: responseData?.name,
          repo_name: responseData?.repoName,
          branch_name: responseData?.env?.map((env) => env.name),
          id: responseData?._id,
          github_token: responseData?.githubToken,
          cloud_type: responseData?.cloudType,
        }
        localStorage.setItem('organizationData', JSON.stringify(org_Data))
      } else {
        navigate(`/`)
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      setLoader(false)
    }
  }

  /**
   * Asynchronously checks the license and handles the sign-in or navigation to the subscription expired page.
   *
   * @return {Promise<void>} Promise that resolves when the function completes.
   */
  const checkLicense = async (data, event) => {
    event.preventDefault()
    try {
      setLoader(true)
      const licenseAPIData = await handleBillingApiCall()
      if (licenseAPIData && licenseAPIData === 'Valid') {
        handleSignIn(data, event)
      } else if (licenseAPIData && licenseAPIData !== 'Valid') {
        navigate('/subscription-expired')
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      setLoader(false)
    }
  }

  const getUserInfo = async () => {
    try {
      setLoader(true)
      const apiUrl = apiConfig.USER_INFO
      const response = await apiService.apiCall('get', apiUrl)

      if (response?.data?.code === 200) {
        const userDetails = response?.data?.data
        localStorage.setItem('userData', JSON.stringify(userDetails))
      }
    } catch (error) {
      console.error('User info error:', error)
    } finally {
      setLoader(false)
    }
  }

  /**
   * A function that handles the sign in process.
   *
   * @param {} - no parameters
   * @return {} - no return value
   */
  const handleSignIn = async (data, event) => {
    event.preventDefault()
    try {
      setLoader(true)
      const { encryptedPassword, ivBase64 } = encryptPassword(data.password)

      if (!encryptedPassword || !ivBase64) {
        addToast({
          title: TOAST.MESSAGES.ERROR.swwError,
          type: 'error',
        })
        return
      }

      let payload = {
        emailId: data.email,
        password: encryptedPassword,
        ivBase64: ivBase64,
      }
      const apiUrl = apiConfig.SIGN_IN_USER
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.code === 200) {
        const token = response?.data?.data?.token
        localStorage.setItem('bearerToken', token)
        const theme = localStorage.getItem('darkMode') || false
        localStorage.setItem('darkMode', theme)
        await getOrganizationData()
        await getUserInfo()
        navigate('/')
        addToast({
          title: TOAST.MESSAGES.SUCCESS.signInSuccess,
          type: 'success',
        })
      } else {
        addToast({
          title: response?.data?.error || TOAST.MESSAGES.ERROR.swwError,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      setLoader(false)
    }
  }

  const handleOnVerfied = (data) => {
    setToken(data?.emailAndToken)
    setSessionExpireTimer(data?.timer?.otpExpireTimer)
    setForgotPasswordModal(false)
  }

  const handlePasswordReset = async (newPass) => {
    setIsVerified(false)
    try {
      setLoader(true)
      const { encryptedPassword, ivBase64 } = encryptPassword(newPass)

      if (!encryptedPassword || !ivBase64) {
        addToast({
          title: TOAST.MESSAGES.ERROR.swwError,
          type: 'error',
        })
        return
      }

      let payload = {
        ...token,
        newPassword: encryptedPassword,
        ivBase64: ivBase64,
      }
      const apiUrl = apiConfig.FORGOT_PASSWORD
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.code === 200) {
        addToast({
          title: TOAST.MESSAGES.SUCCESS.passwordResetSuccess,
          type: 'success',
        })
      } else {
        addToast({
          title:
            response?.data?.error || TOAST.MESSAGES.ERROR.passwordResetError,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      setLoader(false)
    }
  }

  return (
    <>
      <div className="relative flex">
        <div className="mx-auto flex min-h-full w-full flex-col justify-start pt-12 md:max-w-[75%] lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
          <div className="mb-auto flex flex-col pl-5 pr-5 md:pl-12 md:pr-0 lg:max-w-[48%] lg:pl-0 xl:max-w-full">
            <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
              {/* Sign in section */}
              <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
                <h3 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
                  Sign In
                </h3>
                <p className="mb-9 ml-1 text-base text-gray-600">
                  {AUTH.SIGN_IN_TEXT}
                </p>
                <form onSubmit={handleSubmit(checkLicense)}>
                  {/* Email */}
                  <InputField
                    variant="auth"
                    extra=""
                    label="Email"
                    placeholder="mail@simmmple.com"
                    id="email"
                    type="text"
                    isFieldRequired={true}
                    registrationProps={register('email', {
                      required: AUTH.EMAIL_REQUIRED_ERROR,
                      pattern: {
                        value: AUTH.EMAIL_REGEX,
                        message: AUTH.EMAIL_ERROR,
                      },
                    })}
                    // state={errors.email && 'error'}
                  />
                  {errors.email && (
                    <span className="text-sm text-red-500">
                      {errors.email.message}
                    </span>
                  )}

                  {/* Password */}
                  <div className="relative">
                    <InputField
                      variant="auth"
                      extra="mt-3"
                      label="Password"
                      placeholder="Min 8 characters"
                      id="password"
                      type={showPassword ? 'text' : 'password'}
                      isFieldRequired={true}
                      registrationProps={register('password', {
                        required: AUTH.PASSWORD_REQUIRED_ERROR,
                        pattern: {
                          value: AUTH.PASSWORD_REGEX,
                          message: AUTH.PASSWORD_ERROR,
                        },
                      })}
                      // state={errors.password && 'error'}
                    />
                    <div
                      className="absolute right-4 top-11 cursor-pointer text-black dark:text-white"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <AiOutlineEyeInvisible />
                      ) : (
                        <AiOutlineEye />
                      )}
                    </div>
                  </div>
                  {errors.password && (
                    <span className="text-sm text-red-500">
                      {errors.password.message}
                    </span>
                  )}

                  {/* Checkbox */}
                  <div className="mb-4 flex justify-end px-2">
                    {/* <div className="mt-2 flex items-center">
                      <Checkbox className="checked" />
                      <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                        Keep me logged In
                      </p>
                    </div> */}
                    <a
                      className="mt-1 text-sm font-medium text-brand-500 hover:cursor-pointer hover:text-brand-600 dark:text-white"
                      onClick={() => handleForgotPassword()}
                    >
                      Forgot Password?
                    </a>
                  </div>
                  <button
                    type="submit"
                    className="w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  >
                    Sign In
                  </button>
                  <div className="mt-4">
                    <span className="text-sm font-medium text-navy-700 dark:text-gray-500">
                      Not registered yet?
                    </span>
                    <span
                      onClick={toggleView}
                      className="ml-1 text-sm font-medium text-brand-500 hover:cursor-pointer hover:text-brand-600 dark:text-white"
                    >
                      Create an account
                    </span>
                  </div>
                </form>
                <div className="mb-4 mt-6 flex items-center gap-3">
                  <div className="h-px w-full bg-gray-200 dark:!bg-navy-700" />
                  <p className="text-base font-medium text-gray-600"> or </p>
                  <div className="h-px w-full bg-gray-200 dark:!bg-navy-700" />
                </div>
                <div
                  onClick={() => handleGoogleClick()}
                  className="mt-9 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-gray-300 hover:cursor-pointer"
                >
                  <div className="rounded-full text-xl">
                    <FcGoogle />
                  </div>
                  <p className="text-sm font-medium text-navy-700">
                    Sign In with Google
                  </p>
                </div>
              </div>
            </div>
            <div className="fixed right-0 hidden h-full min-h-screen md:block lg:w-[49vw]">
              <WelcomeCard darkmode={darkmode} setDarkmode={setDarkmode} />
            </div>
          </div>
        </div>
      </div>
      {forgotPasswordModal && (
        <ModalForVerifyUser
          setIsVerified={setIsVerified}
          onClose={() => setForgotPasswordModal(false)}
          onVerfied={(data) => handleOnVerfied(data)}
          setLoader={(value) => setLoader(value)}
        />
      )}
      {isVerified && (
        <ModalForCreateNewPassword
          onClose={() => setIsVerified(false)}
          onSubmit={(newPass) => handlePasswordReset(newPass)}
          sessionExpireTimer={sessionExpireTimer}
        />
      )}
    </>
  )
}

export default AuthSignIn
