import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Card from 'components/card'
import { FiRefreshCw } from 'react-icons/fi'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import appConstants from 'common/config/appConstants'
import { useToaster } from 'common/Toaster'
import UptimeKumaLogoSvg from 'assets/svg/UptimeKumaLogoSvg'
import {
  shouldFetchMoreData,
  processResult,
} from 'common/uptimeKumaCommonFunctions'
import InfiniteScrollComponent from './InfiniteScrollComponent'
import { FaAnglesLeft } from 'react-icons/fa6'

const SingleProjectPage = () => {
  const { title, slug } = useParams()
  const apiService = ApiCaller()
  const { addToast } = useToaster()
  const navigate = useNavigate()
  const uptimeKumaSkeletons = Array.from({ length: 1 })
  const {
    TOAST,
    UPTIME_STATUS_PAGE_URL,
    SYSTEM_HEALTH_CHECK_TEXT,
    UPTIME_KUMA_UI,
  } = appConstants

  const [isUptimeKumaAPILoading, setUptimeKumaAPILoading] = useState(false)
  const [isLoadingMoreInitialData, setLoadingMoreInitialData] = useState(false)
  const [requiredRefresh, setRequiredRefresh] = useState(false)
  const [uptimeData, setUptimeData] = useState([])
  const [errorOccurred, setErrorOccurred] = useState(false)
  const [result, setResult] = useState([])
  const [processedArray, setProcessedArray] = useState([])

  const getUptimeKumaDashboard = async (slg, page = 1, loader = false) => {
    try {
      setRequiredRefresh(false)

      if (page === 1) {
        setResult([])
        setProcessedArray([])
        setUptimeKumaAPILoading(true)
      }

      if (loader === true && (page === 2 || page === 3 || page === 4)) {
        setLoadingMoreInitialData(true)
      }

      const payload = { slug: slg, page: page }
      const response = await apiService.apiCall(
        'post',
        apiConfig.GET_UPTIME_KUMA_DASHBOARD,
        payload
      )

      if (response?.data?.code === 200 || response?.data?.code === 204) {
        setUptimeData(response?.data?.data)
      }

      if (response?.data?.code === 400 || response?.data?.code === 500) {
        setUptimeData([])
        setRequiredRefresh(true)
      }

      if (response?.data?.code !== 200 && response?.data?.code !== 204) {
        setErrorOccurred(true)
      } else {
        setErrorOccurred(false)
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      setUptimeKumaAPILoading(false)
      setLoadingMoreInitialData(false)
    }
  }

  const fetchMoreData = async (page, loader = false) => {
    if (page <= uptimeData?.options?.totalPages) {
      try {
        await getUptimeKumaDashboard(slug, page, loader) // Fetch next page of data
      } catch (error) {
        console.error('Error fetching more data:', error)
      }
    }
  }

  const reloadData = () => {
    setResult()
    setProcessedArray([])
    getUptimeKumaDashboard(slug, 1)
  }

  useEffect(() => {
    getUptimeKumaDashboard(slug, 1)
  }, [slug])

  useEffect(() => {
    if (Array.isArray(uptimeData?.result)) {
      setResult((prev) =>
        Array.isArray(prev)
          ? [...prev, ...uptimeData.result]
          : uptimeData.result
      )
    } else {
      setResult([])
    }
  }, [uptimeData?.result])

  useEffect(() => {
    if (!result) {
      setProcessedArray([])
      setResult([])
      return
    }

    const updatedArray = processResult(result)
    setProcessedArray(updatedArray)
  }, [result])

  useEffect(() => {
    if (shouldFetchMoreData(uptimeData)) {
      fetchMoreData(uptimeData?.options?.currentPage + 1, true)
    }
  }, [uptimeData?.options])

  const handleBackBtn = () => {
    navigate(-1)
  }

  return (
    <Card
      extra={
        'p-6 !mt-0 h-[580px] 3xl:h-[780px] w-full overflow-y-hidden overflow-x-scroll 2xl:overflow-x-hidden'
      }
    >
      <button
        onClick={handleBackBtn}
        className="mb-[24px] ml-[6px] flex w-fit items-center gap-2 text-sm font-bold text-blueSecondary transition hover:translate-x-1 dark:text-white"
      >
        <FaAnglesLeft strokeWidth={0.5} />
        <span>Back</span>
      </button>
      <div className="mb-4 flex justify-between font-bold text-[#2B3674] dark:text-white">
        <div
          title={
            slug
              ? `${UPTIME_STATUS_PAGE_URL}/${slug}`
              : SYSTEM_HEALTH_CHECK_TEXT
          }
          className="flex items-center justify-center gap-2 hover:cursor-pointer"
          onClick={() => {
            if (slug) {
              const url = `${UPTIME_STATUS_PAGE_URL}/${slug}`
              window.open(url, '_blank')
            }
          }}
        >
          <UptimeKumaLogoSvg width={40} height={40} />
          <p className="text-2xl font-bold text-navy-700 dark:text-white">
            {title}
          </p>
        </div>
      </div>
      {isUptimeKumaAPILoading ? (
        uptimeKumaSkeletons.map((_data, index) => (
          <div
            key={index}
            className="mr-8 flex h-16 animate-pulse items-center gap-3 pb-4"
          >
            <div className="h-6 w-28 rounded-xl bg-gray-200 dark:bg-gray-800"></div>
            <div className="h-6 w-28 rounded-xl bg-gray-200 dark:bg-gray-800"></div>
            <div className="ml-auto h-6 w-56 rounded-xl bg-gray-200 dark:bg-gray-800"></div>
          </div>
        ))
      ) : !uptimeData?.result ? (
        requiredRefresh ? (
          <div
            className="mr-6 flex h-full flex-col items-center justify-center
           gap-4 text-gray-500 dark:text-gray-400"
          >
            <div>
              <FiRefreshCw
                onClick={reloadData}
                className="inline-block cursor-pointer text-lg transition-transform duration-300 ease-in-out hover:rotate-180"
              />
            </div>
            <p>{UPTIME_KUMA_UI.REFRESH_DATA}</p>
          </div>
        ) : (
          <div className="mr-6 flex h-full items-center justify-center text-gray-500 dark:text-gray-400">
            <p>{UPTIME_KUMA_UI.NO_DATA_AVAILABLE}</p>
          </div>
        )
      ) : uptimeData?.result.length === 0 ? (
        <div className="mr-6 flex h-full items-center justify-center text-gray-500 dark:text-gray-400">
          <p>{UPTIME_KUMA_UI.NO_DATA_AVAILABLE}</p>
        </div>
      ) : (
        <InfiniteScrollComponent
          result={result}
          uptimeData={uptimeData}
          errorOccurred={errorOccurred}
          uptimeKumaSkeletons={uptimeKumaSkeletons}
          processedArray={processedArray}
          fetchMoreData={fetchMoreData}
          isLoadingMoreInitialData={isLoadingMoreInitialData}
        />
      )}
    </Card>
  )
}

export default SingleProjectPage
