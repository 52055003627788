import React, { useMemo, useState, useEffect } from 'react'
import { FiSearch } from 'react-icons/fi'
import CreateProjectsButton from './CreateProjectsButton'
import ProjectCardMenu from 'components/card/ProjectCardMenu'
import { useToaster } from 'common/Toaster'
import debounce from 'lodash.debounce'
import appConstants from 'common/config/appConstants'
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'
import { getAllProjectsData } from 'common/commonFunction'
import { BsArrowDown, BsArrowUp } from 'react-icons/bs'
import PaginationSkeleton from 'components/skeletons/PaginationSkeleton'
import CustomSelect from '../../../dashboards/default/components/CustomSelect'

/**
 * Renders the search table for orders and handles pagination, filtering, and sorting.
 *
 * @param {Object} props - Component props for the search table.
 * @return {JSX.Element} JSX representation of the search table component.
 */
function SearchTableOrders(props) {
  const { addToast } = useToaster()
  const { TOAST, NO_DATA, UPTIME_STATUS_PAGE_URL } = appConstants

  const {
    columnsData,
    tableData,
    totalCount,
    onPageChange,
    itemsPerPage,
    currentPage,
    isProjectLoading,
    setProjectLoading,
  } = props

  const columns = useMemo(() => columnsData, [columnsData])

  const [data, setData] = useState([])
  const [links, setLinks] = useState([])
  const [totalPageCount, setPageCount] = useState(0)
  const [pageIndex, setPageIndex] = useState(currentPage)

  useEffect(() => {
    if (tableData) {
      setData(tableData)
    }
  }, [tableData])

  useEffect(() => {
    if (totalCount) {
      setPageCount(totalCount)
    }
  }, [totalCount])

  useEffect(() => {
    if (data?.length > 0) {
      const temp = data.map((item) => (Array.isArray(item.env) ? item.env : []))
      const temp2 = temp.map((item, index) =>
        item.map((innerItem) => ({
          label: innerItem,
          value:
            `${UPTIME_STATUS_PAGE_URL}/${data[index]?.name}-${innerItem}` || '',
        }))
      )
      setLinks(temp2)
    }
  }, [data])

  // eslint-disable-next-line
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex, itemsPerPage },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  let count = 1
  const tableSkeletons = Array.from({ length: 4 })

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state: { globalFilter },
    gotoPage,
  } = tableInstance

  const page = tableInstance.rows

  const setGlobalFilter = debounce(async (value) => {
    try {
      setProjectLoading(true)
      const response = await getAllProjectsData(1, value)
      if (response?.data?.code === 200) {
        setData(response?.data?.data?.results)
        setPageCount(response?.data?.data?.count)
      } else {
        addToast({
          title: response?.data?.msg || TOAST.MESSAGES.ERROR.swwError,
          type: 'error',
        })
      }
    } catch (err) {
      console.log('err', err)
    } finally {
      setProjectLoading(false)
    }
  }, 500)

  /**
   * Updates the page index and navigates to the specified page.
   *
   * @param {number} page - The page number to navigate to.
   * @return {void} This function does not return anything.
   */
  const changePage = (page) => {
    setPageIndex(page)
    gotoPage(page)
    onPageChange(page + 1)
  }

  const handleLinkChange = (selectedOption) => {
    if (selectedOption.value) {
      window.open(selectedOption.value, '_blank')
    }
  }

  return (
    <>
      <div className="h-full w-full p-2 md:p-4">
        <div className="flex h-full w-full flex-col items-center justify-between !pt-0 md:flex-row md:py-2">
          {/* Search input */}
          <div className="mb-3 flex w-full items-center rounded-full bg-white p-[5px] shadow-2xl shadow-white dark:!bg-navy-800 dark:shadow-none md:mb-0 md:w-9/12">
            <div className="flex h-9 w-full flex-grow items-center rounded-full border-white bg-lightPrimary text-sm text-gray-600 dark:border dark:!bg-navy-900">
              <FiSearch className="mx-2 h-6 w-4 !text-gray-700 dark:!text-white" />
              <input
                type="text"
                placeholder="Search By Project Name"
                className="placeholder:text-lightFourth block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white  sm:w-full"
                value={globalFilter}
                onChange={(e) => setGlobalFilter(e.target.value)}
              />
            </div>
          </div>
          {/* Create Services button */}
          <CreateProjectsButton
            className="mt-4 w-full md:mt-3"
            buttonText={'Create Project'}
            isCreateService={true}
          />
        </div>

        {/* Table */}

        {isProjectLoading ? (
          <div className="flex flex-col">
            {tableSkeletons.map((_item, index) => (
              <div className="flex animate-pulse border-b border-gray-200 py-4 dark:!border-white/10">
                <div className="w-1/7">
                  <div className="ml-4 h-2 w-1/6 rounded bg-gray-200 dark:bg-gray-800"></div>
                </div>
                <div className="w-2/7">
                  <div className="ml-4 h-2 w-1/3 rounded bg-gray-200 dark:bg-gray-800"></div>
                </div>
                <div className="w-2/7">
                  <div className="ml-8 h-2 w-1/4 rounded bg-gray-200 dark:bg-gray-800"></div>
                </div>
                <div className="w-2/7">
                  <div className="ml-8 h-2 w-1/4 rounded bg-gray-200 dark:bg-gray-800"></div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="mt-2 min-h-[150px] w-full overflow-x-scroll xl:overflow-hidden">
            <table {...getTableProps()} className="w-full">
              <thead className="w-full">
                {headerGroups.map((headerGroup, indexRow) => (
                  <tr
                    className="items-center border-b border-gray-200 dark:!border-white/10"
                    {...headerGroup.getHeaderGroupProps()}
                    key={indexRow}
                  >
                    {headerGroup.headers.map((column, index) => {
                      return (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          className="px-4 pb-[10px] text-left text-xs text-gray-600"
                          key={index}
                        >
                          <div className="flex items-center">
                            {column.render('Header')}
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <BsArrowDown className="ml-2" />
                              ) : (
                                <BsArrowUp className="ml-2" />
                              )
                            ) : (
                              ''
                            )}
                          </div>
                        </th>
                      )
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, rowIndex) => {
                  prepareRow(row)
                  return (
                    <tr
                      className="border-b border-gray-200 dark:!border-white/10"
                      {...row.getRowProps()}
                      key={rowIndex}
                    >
                      {row.cells.map((cell, cellIndex) => {
                        let cellClassName = 'px-4'
                        let data = ''
                        if (cell.column.Header === 'PROJECT NAME') {
                          data = (
                            <div className="text-left font-medium text-navy-700 dark:text-white">
                              {cell.value}
                            </div>
                          )
                        } else if (cell.column.Header === 'S.NO') {
                          cellClassName += ' w-24'
                          data = (
                            <div className="w-1 text-left font-medium text-navy-700 dark:text-white">
                              {pageIndex * itemsPerPage + count++}
                            </div>
                          )
                        } else if (cell.column.Header === 'ACTIONS') {
                          data = (
                            <div>
                              <div
                                className="text-left text-sm font-medium text-brand-500 underline hover:cursor-pointer dark:text-white"
                                id={cell.value}
                              >
                                <ProjectCardMenu
                                  tableData={tableData}
                                  cellValue={row.original}
                                />
                              </div>
                            </div>
                          )
                        } else if (
                          cell.column.Header === 'SYSTEM HEALTH LINKS'
                        ) {
                          cellClassName += ' w-64'
                          data = (
                            <div className="text-lightFourth my-[2px] w-full rounded-xl border-[1px] border-solid border-gray-200 px-1.5 text-sm dark:bg-navy-800 dark:text-white">
                              <CustomSelect
                                placeholderText="Select an ENV"
                                options={links[rowIndex]}
                                onChange={handleLinkChange}
                              />
                            </div>
                          )
                        }
                        return (
                          <td
                            className={cellClassName}
                            {...cell.getCellProps()}
                            key={cellIndex}
                          >
                            {data}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
            {/* Display message if no data */}
            {!isProjectLoading && page.length === 0 && (
              <div className="text-md mt-3 flex w-full justify-center text-center text-gray-500 dark:text-gray-400">
                {NO_DATA}
              </div>
            )}
          </div>
        )}

        {/* Pagination */}
        {isProjectLoading ? (
          <PaginationSkeleton />
        ) : (
          page.length > 0 && (
            <div className="mt-2 flex h-12 w-full items-center justify-between px-6">
              <div className="text-sm text-gray-700">
                {/* Display current range of entries */}
                Showing {pageIndex * itemsPerPage + 1} to{' '}
                {totalPageCount < pageIndex * itemsPerPage + data.length
                  ? totalPageCount
                  : pageIndex * itemsPerPage + data.length}{' '}
                of {totalPageCount} entries
              </div>

              <div className="flex items-center">
                {/* Render pagination buttons */}
                <div className="ml-3 flex">
                  {/* First button */}
                  <button
                    className={`h-10 w-10 rounded-full bg-brand-500 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
                      pageIndex === 0 ? 'cursor-not-allowed opacity-50' : ''
                    }`}
                    onClick={() => changePage(0)}
                    disabled={pageIndex === 0}
                  >
                    {'<<'}
                  </button>

                  {/* Previous button */}
                  <button
                    className={`ml-3 h-10 w-10 rounded-full bg-brand-500 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
                      pageIndex === 0 ? 'cursor-not-allowed opacity-50' : ''
                    }`}
                    onClick={() => changePage(pageIndex - 1)}
                    disabled={pageIndex === 0}
                  >
                    {'<'}
                  </button>

                  {/* Pagination buttons */}
                  {Array.from(
                    { length: Math.min(5, Math.ceil(totalPageCount / 10)) }, // Limit to a maximum of 5 pages
                    (_, index) => {
                      const page =
                        Math.max(
                          0,
                          Math.min(
                            Math.ceil(totalPageCount / 10) - 5,
                            pageIndex
                          )
                        ) + index
                      return (
                        <button
                          key={page}
                          className={`ml-3 h-10 w-10 rounded-full bg-brand-500 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
                            pageIndex === page
                              ? 'cursor-not-allowed opacity-50'
                              : ''
                          }`}
                          onClick={() => changePage(page)}
                          disabled={pageIndex === page}
                        >
                          {page + 1}
                        </button>
                      )
                    }
                  )}

                  {/* Next button */}
                  <button
                    className={`ml-3 h-10 w-10 rounded-full bg-brand-500 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
                      pageIndex >= Math.ceil(totalPageCount / 10) - 1
                        ? 'cursor-not-allowed opacity-50'
                        : ''
                    }`}
                    onClick={() => changePage(pageIndex + 1)}
                    disabled={pageIndex >= Math.ceil(totalPageCount / 10) - 1}
                  >
                    {'>'}
                  </button>

                  {/* Last button */}
                  <button
                    className={`ml-3 h-10 w-10 rounded-full bg-brand-500 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
                      pageIndex >= Math.ceil(totalPageCount / 10) - 1
                        ? 'cursor-not-allowed opacity-50'
                        : ''
                    }`}
                    onClick={() =>
                      changePage(Math.ceil(totalPageCount / 10) - 1)
                    }
                    disabled={pageIndex >= Math.ceil(totalPageCount / 10) - 1}
                  >
                    {'>>'}
                  </button>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </>
  )
}

export default SearchTableOrders
