import { useState, useEffect } from 'react'
import ChatIntegration from '../views/chatBot'
import { AiFillWechat } from 'react-icons/ai'
import { useLocation } from 'react-router-dom'
import { useToaster } from 'common/Toaster'
import appConstants from 'common/config/appConstants'

/**
 * ChatAi component that manages the visibility and fullscreen mode.
 *
 * @return {JSX.Element} The JSX element representing the ChatAi component
 */
const ChatAi = () => {
  const [isVisible, SetIsVisible] = useState(false)
  const [fullScreen, setFullScreen] = useState(false)
  const location = useLocation()
  const { addToast } = useToaster()
  const { TOAST } = appConstants
  const showChatBoat = appConstants.SHOW_CHAT_BOAT
  const isOpenAiKey = localStorage.getItem('isOpenAiKey')

  useEffect(() => {
    if (showChatBoat === 'true' && isOpenAiKey === 'true') {
      const value = location.pathname.includes('/chatbot')
      if (value) {
        setFullScreen(true)
      } else {
        setFullScreen(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const handleClick = () => {
    if (isOpenAiKey === 'true') {
      SetIsVisible(!isVisible)
    } else {
      addToast({
        title: TOAST.MESSAGES.INFO.openAIKeyChatbot,
        type: 'info',
      })
    }
  }

  return (
    <>
      {showChatBoat === 'true' && !fullScreen && (
        <div>
          <div
            className="z-1 fixed bottom-14 right-3 h-[40px] w-[40px] cursor-pointer rounded-full bg-blue-700"
            onClick={handleClick}
          >
            <span className="flex h-full cursor-pointer items-center justify-center text-[25px] text-white">
              <AiFillWechat />
            </span>
          </div>
          <ChatIntegration
            isVisible={isVisible}
            onValueChange={() => SetIsVisible(!isVisible)}
          />
        </div>
      )}
    </>
  )
}

export default ChatAi
