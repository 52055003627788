import React, { useState, useRef, useEffect } from 'react'

const OtpInput = ({ length = 6, onComplete, resetKey, isLoading }) => {
  const [otp, setOtp] = useState(Array(length).fill(''))
  const inputRefs = useRef([])

  useEffect(() => {
    setOtp(Array(length).fill(''))
    inputRefs.current[0]?.focus()
  }, [resetKey])

  const handleChange = (value, index) => {
    if (!isLoading && (/^\d$/.test(value) || value === '')) {
      const newOtp = [...otp]
      newOtp[index] = value
      setOtp(newOtp)

      if (value !== '' && index < length - 1) {
        inputRefs.current[index + 1].focus()
      }

      if (newOtp.every((digit) => digit !== '') && onComplete) {
        onComplete(newOtp.join(''))
      }
    }
  }

  const handleKeyDown = (e, index) => {
    if (!isLoading && e.key === 'Backspace' && otp[index] === '') {
      if (index > 0) {
        inputRefs.current[index - 1].focus()
      }
    }
  }

  return (
    <div className="mb-8 flex items-center justify-center gap-4">
      {otp.map((digit, index) => (
        <input
          key={index}
          type="text"
          maxLength="1"
          value={isLoading ? '•' : digit} // Display '•' when loading
          onChange={(e) => handleChange(e.target.value, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          ref={(el) => (inputRefs.current[index] = el)}
          className="h-12 w-12 border-b-4 border-black bg-white text-center align-middle text-xl leading-tight focus:outline-none dark:border-white dark:bg-darkBlue dark:text-white"
          disabled={isLoading} // Lock input interaction when loading
        />
      ))}
    </div>
  )
}

export default OtpInput
