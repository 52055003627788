import React from 'react'
import PropTypes from 'prop-types'
// import Radio from 'components/radio'

const RadioServiceCard = ({
  isDisabled,
  selectedValue,
  value,
  onRadioChange,
  imageSrc,
  label,
}) => {
  return (
    <div
      onClick={isDisabled ? null : () => onRadioChange(value)}
      className={`flex h-[135px] w-[245px] flex-col rounded-xl border-[1px] ${
        isDisabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
      } ${
        selectedValue === value
          ? 'border-brand-400'
          : 'border-gray-20/50 dark:border-white/40'
      }`}
    >
      <div className="pl-5 pt-4">
        {/* <Radio
          checked={selectedValue === value}
          onChange={() => onRadioChange(value)}
          value={value}
          disabled={isDisabled}
          color="purple"
          className={`h-4 w-4 border-2 ${
            isDisabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
          } ${
            selectedValue === value
              ? 'border-brand-500 bg-brandLinear'
              : 'border-gray-400 bg-white'
          }`}
        /> */}
        <div
          className={`bg-transparent relative inline-block h-5 w-5 rounded-full border ${
            isDisabled
              ? 'cursor-not-allowed border-gray-300 opacity-50'
              : 'cursor-pointer'
          } ${
            selectedValue === value ? 'border-brand-400' : 'border-gray-400'
          }`}
          onClick={() => !isDisabled && onRadioChange(value)}
        >
          {/* Inner circle for checked state */}
          {selectedValue && (
            <div
              className={`absolute left-1/2 top-1/2 h-3.5 w-3.5 -translate-x-1/2 -translate-y-1/2 rounded-full ${
                selectedValue === value ? 'bg-brand-400' : 'bg-transparent'
              }`}
            />
          )}
          {/* Hidden input for accessibility */}
          <input
            type="radio"
            checked={selectedValue === value}
            onChange={() => onRadioChange(value)}
            value={value}
            disabled={isDisabled}
            className="pointer-events-none absolute inset-0 h-0 w-0 opacity-0"
          />
        </div>
      </div>
      <div className="flex items-center justify-center gap-2 pl-6 pr-3 pt-4">
        <img
          src={imageSrc}
          alt={label}
          className="h-14 w-14 rounded-full bg-[#D0DCFF]/35 p-2 dark:bg-white"
        />
        <p className="w-[125px] text-[#000] dark:text-white">{label}</p>
      </div>
    </div>
  )
}

// Define PropTypes
RadioServiceCard.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onRadioChange: PropTypes.func.isRequired,
  imageSrc: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

export default RadioServiceCard
