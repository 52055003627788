import { useEffect, useState } from 'react'
import Select, { components } from 'react-select'
import { useTheme } from '../contexts/ThemeContext'

/**
 * Custom select component with dynamic styling based on theme.
 *
 * @param {object} field - Field object for the select component
 * @param {array} options - Array of options for the select component
 * @param {function} onChange - Function to handle onChange event
 * @param {string} placeholderText - Text to display as a placeholder in the select component
 * @param {boolean} isErrorField - Flag to indicate if the field has an error
 * @return {JSX.Element} The custom select component
 */
const CustomSelect = ({
  field,
  options,
  onChange,
  placeholderText = 'Select',
  isErrorField = false,
  value,
}) => {
  const lightStyles = {
    /**
     * Modifies the provided container width to '100%'.
     *
     * @param {Object} provided - The original container object.
     * @return {Object} The modified container object with width set to '100%'.
     */
    container: (provided) => ({
      ...provided,
      width: '100%',
    }),
    /**
     * Generates the styles for the control element.
     *
     * @param {Object} provided - The original styles for the control element.
     * @return {Object} The modified styles for the control element.
     */
    control: (provided) => ({
      ...provided,
      boxShadow: 'none', // Background color for options in dark theme
      background: 'transparent',
      color: '#fff', // Dark background color
      border: 'none', // Dark border
      width: '100%',
      padding: '0',
    }),
    /**
     * Modifies the single value provided with a specific color for the selected value in the light theme.
     *
     * @param {Object} provided - The original single value object.
     * @return {Object} The modified single value object with specified color.
     */
    singleValue: (provided) => ({
      ...provided,
      color: '#333', // Text color for selected value in light theme
    }),
    /**
     * Generates the styles for the option element based on its state in the light theme.
     *
     * @param {Object} provided - The original styles for the option element.
     * @param {Object} state - The state of the option element (selected, focused, etc.).
     * @return {Object} The modified styles for the option element in the light theme.
     */
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected
        ? '#007bff'
        : state.isFocused
        ? '#e9ecef'
        : 'white', // Background color for options in light theme
      color: state.isSelected ? '#fff' : '#333', // Text color for options in light theme
      ':hover': {
        background: '#007bff', // Hover background color in light theme
        color: '#fff', // Hover text color in light theme
      },
    }),
    /**
     * Generates the styles for the placeholder element.
     *
     * @param {Object} provided - The original styles for the placeholder.
     * @return {Object} The modified styles for the placeholder.
     */
    placeholder: (provided) => ({
      ...provided,
      color: isErrorField === true ? '#f53939' : '#b1b5c4',
    }),
    /**
     * Generates the styles for the menu element.
     *
     * @param {Object} provided - The original styles for the menu.
     * @return {Object} The modified styles for the menu with a zIndex of 10.
     */
    menu: (provided) => ({
      ...provided,
      zIndex: 10,
    }),
  }

  const darkStyles = {
    /**
     * Modifies the provided container object to set its width to '100%'.
     *
     * @param {Object} provided - The original container object.
     * @return {Object} The modified container object with width set to '100%'.
     */
    container: (provided) => ({
      ...provided,
      width: '100%',
    }),
    /**
     * Generates the styles for the control element in the dark theme.
     *
     * @param {Object} provided - The original styles for the control element.
     * @return {Object} The modified styles for the control element in the dark theme.
     */
    control: (provided) => ({
      ...provided,
      boxShadow: 'none', // Background color for options in dark theme
      background: 'transparent',
      color: '#fff', // Dark background color
      border: 'none', // Dark border
      width: '100%',
      padding: '0',
    }),
    /**
     * Text color for selected value in dark theme
     *
     * @param {Object} provided - The original styles for the single value
     * @return {Object} The modified styles for the single value with text color set to '#fff'
     */
    singleValue: (provided) => ({
      ...provided,
      color: '#fff', // Text color for selected value in dark theme
    }),
    /**
     * Generates the styles for the placeholder element.
     *
     * @param {Object} provided - The original styles for the placeholder.
     * @return {Object} The modified styles for the placeholder.
     */
    placeholder: (provided) => ({
      ...provided,
      color: isErrorField === true ? '#f87171' : '#b1b5c4',
    }),
    /**
     * Generates the styles for the input element in the dark theme.
     *
     * @param {Object} provided - The original styles for the input element.
     * @return {Object} The modified styles for the input element in the dark theme.
     */
    input: (provided) => ({
      ...provided,
      color: '#ffffff', // Text color for selected value in dark theme
    }),
    /**
     * Generates the styles for the menu list element.
     *
     * @param {Object} provided - The original styles for the menu list.
     * @return {Object} The modified styles for the menu list with padding set to '0'.
     */
    menuList: (provided) => ({
      ...provided,
      padding: '0',
    }),
    /**
     * Generates the styles for the option element based on its state in the dark theme.
     *
     * @param {Object} provided - The original styles for the option element.
     * @param {Object} state - The state of the option element (selected, focused, etc.).
     * @return {Object} The modified styles for the option element in the dark theme.
     */
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected
        ? '#7551ff'
        : state.isFocused
        ? '#666'
        : '#444', // Background color for options in dark theme
      color: state.isSelected ? '#fff' : '#fff', // Text color for options in dark theme
      ':hover': {
        background: '#7551ff', // Hover background color in dark theme
        color: '#fff', // Hover text color in dark theme
      },
    }),
    /**
     * Generates the styles for the menu element.
     *
     * @param {Object} provided - The original styles for the menu.
     * @return {Object} The modified styles for the menu with a zIndex of 10.
     */
    menu: (provided) => ({
      ...provided,
      zIndex: 10,
    }),
  }
  const { darkmode } = useTheme()
  const [selectedStyle, SetSelectedStyle] = useState(lightStyles)
  useEffect(() => {
    const selectedStyles =
      (localStorage?.darkMode !== 'false') === true ? darkStyles : lightStyles

    SetSelectedStyle(selectedStyles)
    //eslint-disable-next-line
  }, [darkmode])

  // Custom option component to handle divider
  const CustomOption = (props) => {
    const { data } = props
    if (data.divider) {
      return <div style={{ borderBottom: '1px solid #ccc', margin: '8px 0' }} />
    }
    return <components.Option {...props} />
  }

  return (
    <Select
      {...field}
      options={options}
      onChange={onChange}
      placeholder={placeholderText}
      styles={selectedStyle}
      value={value}
      components={{ Option: CustomOption }}
    />
  )
}

export default CustomSelect
