import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import ApiCaller from '../../common/services/apiServices'
import appConstants from '../config/appConstants'
import { useToaster } from '../../common/Toaster'
import apiConfig from '../../common/config/apiConfig'

const OauthRedirect = () => {
  const { TOAST, AUTH } = appConstants
  const navigate = useNavigate()
  const { addToast } = useToaster()
  const location = useLocation()
  const apiService = ApiCaller()
  const baseUrl = apiConfig.API_URL

  const [isButtonEnabled, setIsButtonEnabled] = useState(false) // State for button and countdown
  const [countdown, setCountdown] = useState(5) // 5-second countdown
  const [darkMode] = useState(localStorage.darkMode === 'true')

  const verifyUser = async () => {
    const queryParams = new URLSearchParams(location.search)
    const redirectType = queryParams.get('type')
    const accessToken = queryParams.get('accessToken')
    const user = queryParams.get('user')
    const refreshToken = queryParams.get('refreshToken')
    const expiresIn = queryParams.get('expiresIn')
    const platformType = queryParams.get('platformType')

    try {
      // Clear verificationSuccess from sessionStorage immediately after retrieval
      if (!redirectType || redirectType !== 'redirect') return // Exit if no success status found

      const frontendUrl = window.location.origin + '/settings/organization'
      const backendUrl = baseUrl.replace(/\/$/, '')
      if (
        platformType === 'github' &&
        redirectType === 'redirect' &&
        accessToken &&
        user
      ) {
        const apiUrl = apiConfig.GITHUB_OAUTH_REDIRECT_URL.replace(
          ':accessToken',
          accessToken
        )
          .replace(':user', user)
          .replace(':frontendUrl', frontendUrl)
          .replace(':backendUrl', backendUrl)
        const response = await apiService.apiCall('get', apiUrl)

        if (response?.status === 200) {
          navigate('/settings/organization?type=success')
        } else {
          addToast({
            title:
              'fetchTokenError from catch' ||
              TOAST.MESSAGES.ERROR.oauthFailedError,
            type: 'error',
          })
          navigate('/settings/organization?type=failed')
        }
      } else if (
        platformType === 'bitbucket' &&
        redirectType === 'redirect' &&
        accessToken &&
        refreshToken &&
        expiresIn &&
        user
      ) {
        const apiUrl = apiConfig.BITBUCKET_OAUTH_REDIRECT_URL.replace(
          ':accessToken',
          accessToken
        )
          .replace(':user', user)
          .replace(':frontendUrl', frontendUrl)
          .replace(':backendUrl', backendUrl)
          .replace(':refreshToken', refreshToken)
          .replace(':expiresIn', expiresIn)
        const response = await apiService.apiCall('get', apiUrl)

        if (response?.status === 200) {
          navigate('/settings/organization?type=success')
        } else {
          addToast({
            title:
              'fetchTokenError from catch' ||
              TOAST.MESSAGES.ERROR.oauthFailedError,
            type: 'error',
          })
          navigate('/settings/organization?type=failed')
        }
      } else {
        addToast({
          title: TOAST.MESSAGES.ERROR.oauthFailedError,
          type: 'error',
        })
        navigate('/settings/organization?type=failed')
      }
    } catch (error) {
      addToast({
        title:
          'fetchTokenError from catch' || TOAST.MESSAGES.ERROR.oauthFailedError,
        type: 'error',
      })
      navigate('/settings/organization?type=failed')
    }
  }

  useEffect(() => {
    verifyUser()
  }, [])

  useEffect(() => {
    // Start countdown timer
    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev === 1) {
          clearInterval(timer) // Stop the timer
          setIsButtonEnabled(true) // Enable the button
        }
        return prev - 1
      })
    }, 1000)

    return () => {
      clearInterval(timer) // Cleanup timer on unmount
    }
  }, [location.search])

  useEffect(() => {
    if (darkMode) document.body.classList.add('dark')
    else document.body.classList.remove('dark')
  }, [darkMode])

  const handleNavigation = () => {
    navigate('/settings/organization')
  }

  return (
    <div className="flex h-screen w-full flex-col items-center justify-start gap-2 bg-white pt-8 dark:bg-navy-900">
      <p className="text-2xl font-semibold text-navy-700 dark:text-white">
        {AUTH.PLEASE_WAIT_TEXT}
      </p>
      <p className="text-lg text-navy-700 dark:text-white">
        {AUTH.DO_NOT_NAVIGATE_TEXT}
      </p>
      <p className="text-navy-700 dark:text-white">
        If the site doesn't redirect in {countdown} seconds, click the button
        below.
      </p>
      <button
        className={`mt-2 rounded-xl bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
          isButtonEnabled ? 'cursor-pointer' : 'cursor-not-allowed opacity-50'
        }`}
        onClick={handleNavigation}
        disabled={!isButtonEnabled}
      >
        Try Again
      </button>
    </div>
  )
}

export default OauthRedirect
