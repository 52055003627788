import Invite from './components/Invite'
import DeployGitRepository from './components/DeployGitRepository'
import { FaAnglesLeft } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'

/**
 * Function that renders the Referrals component.
 *
 * @return {JSX.Element} The rendered Referrals component
 */
const Referrals = () => {
  const navigate = useNavigate()
  const handleBackBtn = () => {
    navigate(-1)
  }
  return (
    <div className="h-full w-full gap-5 rounded-[20px]">
      <button
        onClick={handleBackBtn}
        className="mt-[24px] flex w-fit items-center gap-2 text-sm font-bold text-blueSecondary transition hover:translate-x-1 dark:text-white"
      >
        <FaAnglesLeft strokeWidth={0.5} />
        <span>Back</span>
      </button>
      <div className="col-span-12 mt-3 w-full md:mt-4 3xl:col-span-4">
        <DeployGitRepository />
      </div>
      <hr className="col-span-12 my-8" />
      <div className="col-span-12 w-full 3xl:col-span-4">
        <Invite />
      </div>
    </div>
  )
}

export default Referrals
