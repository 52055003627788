import constants from '../common/config/appConstants'

/**
 * Dashboard note component that display the note when all tools are missing.
 *
 * @return {JSX.Element} The JSX element representing the DashboardNote component
 */
const DashboardNote = () => {
  const note = constants.NOTE.DASHBOARD
  return (
    <>
      <div className="z-1 note-font-size fixed bottom-0 left-0 right-0 h-[50px] w-full cursor-pointer bg-blue-700 p-3 text-center text-white">
        <span className="font-semibold">Note:</span> {note}
      </div>
    </>
  )
}

export default DashboardNote
