import React, { useState, useCallback, useEffect } from 'react'
import InputFieldSub from 'components/fields/InputFieldSub'
import { useForm, Controller } from 'react-hook-form'
import ApiCaller from 'common/services/apiServices'
import { useNavigate, useParams } from 'react-router-dom'
import apiConfig from 'common/config/apiConfig'
import { useToaster } from 'common/Toaster'
import appConstants from 'common/config/appConstants'
import { useSpinner } from 'common/SpinnerLoader'
import CustomSelect from 'common/CustomSelect'
import { HiPlus } from 'react-icons/hi'
import {
  getRepoName,
  isOnlyService,
  toKebabCase,
  getOrganization,
} from 'common/commonFunction'
import { RxCrossCircled } from 'react-icons/rx'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { isValidCron } from 'cron-validator'
import Stepper from 'react-stepper-horizontal'
import TooltipHorizon from '../../../../components/tooltip'
import { RiDeleteBinLine } from 'react-icons/ri'
import { FaAnglesLeft } from 'react-icons/fa6'
import debounce from 'lodash.debounce'
import { checkDomainName } from 'common/commonFunction'

const {
  TOAST,
  BUTTON_TEXT,
  NOTE,
  LABEL,
  GITHUB_URL,
  BITBUCKET_URL,
  MAX_LENGTH,
  TOOLTIPS,
  APPLICATION_CREATE_STEPS,
  STEPPER_SERVICE,
  STEPPER_TITLE,
  VALIDATION,
} = appConstants

const apiService = ApiCaller()

const Product = () => {
  let navigate = useNavigate()
  const { addToast } = useToaster()
  const { id, applicationType } = useParams()
  const { showSpinner, hideSpinner } = useSpinner()
  const [branches, setBranches] = useState([])
  const [versions, setVersions] = useState([])
  const [isCreationAvailable, setIsCreationAvailable] = useState(false)
  const [appName, setAppName] = useState('')
  const checkOnlyService = isOnlyService(applicationType)
  const [isLoading, setIsLoading] = useState(false)
  const [isValid, setIsValid] = useState(true)
  const [organizationBranches, setOrganizationBranches] = useState([])
  const [cloudType, setCloudType] = useState()
  const [domainName, setDomainName] = useState([])
  const [urlAddress, setUrlAddress] = useState([])
  const [artifactRepoName, setArtifactRepoName] = useState([])
  const [branchesDomain, setBranchesDomain] = useState([])
  const [domainErrors, setErrors] = useState([])
  const [urlErrors, setUrlErrors] = useState([])
  const [configErrors, setConfigErrors] = useState({})
  const [isPortNotValid, setIsPortNotValid] = useState(false)
  const [platformType, setPlatformType] = useState()
  const [port, setPort] = useState(3000)
  const initialLength = domainName.length
  const [domainCheck, setDomainCheck] = useState(
    Array(initialLength).fill(false)
  )
  const [isLoadingDomainCheck, setIsLoadingDomainCheck] = useState(
    Array(initialLength).fill(false)
  ) // Initialize as an array
  const [domainExistErrors, setDomainExistErrors] = useState([])
  const [domainErrorsFlag, setDomainErrorsFlag] = useState(false)
  const [urlErrorsFlag, setUrlErrorsFlag] = useState(false)
  const [stepperData, setStepperData] = useState({
    page: APPLICATION_CREATE_STEPS.FIRST_STEP,
  })
  const [inputFields, setInputFields] = useState([{ key: '', value: '' }])
  const [validity, setValidity] = useState([{ key: false, value: false }])
  const [configMapData, setConfigMapData] = useState([])
  const [configMapBranch, setConfigMapBranch] = useState()
  const [validationError, setValidationError] = useState()
  const [fetchingBranches, setFetchingBranches] = useState(false)
  const branchesList = validationError?.join(', ')
  const steps = STEPPER_TITLE

  const handleClearKeyField = (index) => {
    const values = [...inputFields]
    values[index] = { ...values[index], key: '' }
    setInputFields(values)
    // Update validity state
    const validities = [...validity]
    validities[index] = { ...validities[index], key: false }
    setValidity(validities)
  }

  const handleClearValueField = (index) => {
    const values = [...inputFields]
    values[index] = { ...values[index], value: '' }
    setInputFields(values)
    // Update validity state
    const validities = [...validity]
    validities[index] = { ...validities[index], value: false }
    setValidity(validities)
  }

  const handleDeleteField = (index) => {
    const values = [...inputFields]
    //eslint-disable-next-line
    const deletedRow = values.splice(index, 1)[0]
    setInputFields(values)
  }

  /**
   * A description of the entire function.
   *
   * @param {type} e - description of parameter
   * @return {type} description of return value
   */

  const handleCronScheduleChange = (e) => {
    const { value } = e.target
    setIsValid(
      isValidCron(value, {
        seconds: true,
        alias: true,
        allowBlankDay: true,
        allowSevenAsSunday: true,
      })
    )
  }

  const handlePortChange = (e) => {
    setIsPortNotValid(false)
    const { value } = e.target
    setPort(value)
    if (value && (Number(value) < 0 || Number(value) > 65535)) {
      setIsPortNotValid(true)
    }
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      application_name: '',
      port: '3000',
      branches: '',
    },
  })
  const getOrganizationDetails = async () => {
    try {
      const response = await getOrganization()
      if (response?.data?.code === 200) {
        const organizationData = response?.data?.data
        setCloudType(organizationData?.cloudType)
        setPlatformType(organizationData?.platformType)
      } else {
        addToast({
          title: TOAST.MESSAGES.ERROR.fetchVersionError,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    }
  }

  useEffect(() => {
    // if (applicationType === 'webApp' || applicationType === 'frontend') {
    //   getOrganizationDetails()
    // }
    getOrganizationDetails()
    getAllBranches()
    getSelectedEnv()
    if (['redis', 'postgresql'].includes(applicationType)) {
      getAllVersionBasedOnServiceType(applicationType)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Converts an array of strings into an array of objects with 'value' and 'label' keys,
   * where the 'value' and 'label' are the same as the input string.
   *
   * @param {string[]} inputArray - The array of strings to be converted.
   * @return {Object[]} An array of objects with 'value' and 'label' keys.
   */
  const convertToOptions = (inputArray) => {
    // Initialize an empty array to store the transformed objects
    const options = []

    // Loop through each string in the input array
    inputArray?.forEach((item) => {
      // Create an object with 'value' and 'label' keys, using the string for both
      const optionObject = {
        value: item,
        label: item,
      }

      // Push the created object into the options array
      options.push(optionObject)
    })

    // Return the array of objects
    return options
  }

  /* getAllVersion function is to get all the version lists
   * It contains the version where we get the version and we pass it as a payload.
   * the respose is being stored in setVersions array.
   */
  const getAllVersionBasedOnServiceType = async (applicationType) => {
    try {
      const apiUrl = apiConfig.GET_ALL_APPLICATION_VERSION_DATA.replace(
        ':serviceType',
        applicationType
      )
      const response = await apiService.apiCall('get', apiUrl)
      if (response?.data?.code === 200) {
        setVersions(
          convertToOptions(response?.data?.data.map((ver) => ver.version))
        )
      } else {
        addToast({
          title: TOAST.MESSAGES.ERROR.fetchVersionError,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    }
  }

  /* getAllBranches function is to get all the branch lists
   * It contains the fullnameBranch where we get the org name and org repo and we pass it as a payload.
   * the respose is being stored in setBranches array.
   */
  const getAllBranches = async () => {
    const organizationData = JSON.parse(
      localStorage.getItem('organizationData')
    )
    try {
      setFetchingBranches(true)
      let FullBranchName = getRepoName()
      if (FullBranchName) {
        const fullBranchName = FullBranchName.replace(/"/g, '')
        const [orgName, orgRepo] = fullBranchName
          .split('/')
          .map((part) => part.trim())
        const apiUrl = apiConfig.GET_ALL_BRANCHES.replace(
          ':organization_id',
          organizationData?.id
        )
          .replace(':owner_name', orgName)
          .replace(':repo_name', orgRepo)
        const response = await apiService.apiCall('get', apiUrl)
        if (response?.data?.code === 200) {
          const branchList = response?.data?.data.map((branch) => ({
            label: branch.name,
            value: branch.name,
          }))
          setBranches(branchList)
          setValue('branches', branchList[0])
        } else {
          addToast({
            title: TOAST.MESSAGES.ERROR.fetchBranchError,
            type: 'error',
          })
        }
      } else {
        if (checkOnlyService?.isNotService) {
          navigate(`/new-service/${id}/${applicationType}`)
        } else {
          navigate(`/deploying-service/${id}/${applicationType}`)
        }
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      setFetchingBranches(false)
    }
  }

  /* getSelectedEnv function is to get all the branch lists
   * It contains the fullnameBranch where we get the org name and org repo and we pass it as a payload.
   * the respose is being stored in setBranches array.
   */
  const getSelectedEnv = async () => {
    try {
      let FullBranchName = getRepoName()
      if (FullBranchName) {
        const apiUrl = apiConfig.GET_SELECTED_ENV.replace(':projectId', id)
        const response = await apiService.apiCall('get', apiUrl)
        if (response?.data?.code === 200) {
          const responseData = response?.data?.data
          setConfigMapBranch(responseData[0])
          setOrganizationBranches(responseData)
        } else {
          addToast({
            title: TOAST.MESSAGES.ERROR.fetchBranchError,
            type: 'error',
          })
        }
      } else {
        if (checkOnlyService?.isNotService) {
          navigate(`/new-service/${id}/${applicationType}`)
        } else {
          navigate(`/deploying-service/${id}/${applicationType}`)
        }
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    }
  }

  /* createFormData function is to append all the fields into form-data
   * It takes params as a formValue
   * This whole data is being passed as a payload for create new application api
   */
  const createServiceObj = (applicationType, formValue, branchesDomain) => {
    switch (applicationType) {
      case 'webApp':
      case 'frontend':
        return {
          name: formValue.application_name,
          projectId: id,
          appType: applicationType,
          [applicationType]: {
            gitRepo:
              `${platformType === LABEL.GITHUB ? GITHUB_URL : BITBUCKET_URL}` +
              getRepoName(),
            headBranch: formValue?.branches?.value,
            branchesDomain: branchesDomain,
            port: port ? parseInt(port) : undefined,
          },
          configMap: configMapData,
        }
      case 'cron':
        return {
          name: formValue.application_name,
          projectId: id,
          appType: applicationType,
          cron: {
            gitRepo:
              `${platformType === LABEL.GITHUB ? GITHUB_URL : BITBUCKET_URL}` +
              getRepoName(),
            headBranch: formValue?.branches?.value,
            schedular: formValue.schedule,
            shellCommand: formValue.command,
          },
        }
      case 'redis':
      case 'postgresql':
        return {
          name: formValue.application_name,
          projectId: id,
          appType: 'helm',
          helm: {
            name: applicationType,
            version: formValue?.version?.value,
          },
        }
      default:
      // code block
    }
  }
  /* onFormSubmit Function is used to submit the whole form
   * @params used here is formValue
   * createWebServiceFunc function is being called and createFormData data is being as a params further.
   */
  const onFormSubmit = (formValue) => {
    const data = createServiceObj(applicationType, formValue, branchesDomain)
    organizationBranches?.forEach((branch) => {
      const branchName = branch
      const branchExists = data?.configMap?.some(
        (config) => config.env === branchName
      )
      if (!branchExists) {
        data?.configMap?.push({
          env: branchName,
          data: [
            {
              key: '',
              value: '',
            },
          ],
        })
      }
    })
    const { hasErrors, errors } = validateFields(
      configMapBranch,
      data?.configMap
    )
    const result = checkAllValidaton(data?.configMap)

    if (hasErrors || result.validate) {
      setConfigErrors(errors)
      if (Object.keys(errors).length === 0) {
        setValidationError(result.envsWithErrors)
      }
    } else {
      setValidationError([])
      createWebServiceFunc(data)
    }
  }

  const checkAllValidaton = (inputFields) => {
    let validate = false
    const errors = {}
    const envsWithErrors = []

    inputFields?.forEach((branch) => {
      const { env, data } = branch

      if (data.length === 1 && !data[0].key && !data[0].value) {
        return { validate, errors, envsWithErrors } // Skip showing errors if both key and value are empty for the only object
      }

      data?.forEach((field, index) => {
        if (!field.key || !field.value) {
          if (!errors[env]) errors[env] = []
          const fieldErrors = {}
          if (!field.key) {
            fieldErrors.keyError = 'Key is required'
          }
          if (!field.value) {
            fieldErrors.valueError = 'Value is required'
          }
          // Store errors for the specific environment and data index
          errors[env][index] = fieldErrors
          validate = true

          // Add env to envsWithErrors if it isn't already included
          if (!envsWithErrors.includes(env)) {
            envsWithErrors.push(env)
          }
        }
      })
    })
    return { validate, errors, envsWithErrors }
  }

  const validateFields = (selectedBranch, inputFields) => {
    let hasErrors = false
    const errors = {}
    // Find the branch data for the selected branch
    const branchData = inputFields?.find((item) => item.env === selectedBranch)
    if (branchData) {
      const { data } = branchData
      // Check if the data contains only one object and both key and value are empty
      if (data.length === 1 && !data[0].key && !data[0].value) {
        return { hasErrors, errors } // Skip showing errors if both key and value are empty for the only object
      }
      // Perform the regular validation if the condition above is not met
      data?.forEach((field, index) => {
        if (!field.key) {
          if (!errors[index]) errors[index] = {}
          errors[index].keyError = 'Key is required'
          hasErrors = true
        }
        if (!field.value) {
          if (!errors[index]) errors[index] = {}
          errors[index].valueError = 'Value is required'
          hasErrors = true
        }
      })
    }
    return { hasErrors, errors }
  }

  const handleCheckDomain = useCallback(
    debounce(async (index, data) => {
      try {
        const payload = {
          domainName: data,
        }
        const response = await checkDomainName(payload)
        if (response?.data?.code === 200) {
          setDomainCheck((prevState) => {
            const newDomainCheck = [...prevState]
            newDomainCheck[index] = response.data.data?.isAvailableForCreation
            return newDomainCheck
          })

          setDomainExistErrors((prevState) => {
            const newErrors = [...prevState]
            if (!response.data.data?.isAvailableForCreation) {
              newErrors[index] = 'Domain already exists'
              setDomainErrorsFlag(true)
            } else {
              newErrors[index] = ''
              setDomainErrorsFlag(false)
            }
            return newErrors
          })
        } else {
          addToast({
            title: response.data.msg,
            type: 'error',
          })
        }
      } catch (error) {
        addToast({
          title: TOAST.MESSAGES.ERROR.swwError,
          type: 'error',
        })
      } finally {
        setIsLoadingDomainCheck((prevState) => {
          const newIsLoading = [...prevState]
          newIsLoading[index] = false
          return newIsLoading
        })
      }
    }, 500),
    []
  )

  const handleDomainChange = (index, e) => {
    const value = e.target.value
    const regex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    const newErrors = [...domainErrors]

    if (value && !regex.test(value)) {
      newErrors[index] = 'Invalid domain name'
      setDomainErrorsFlag(true)
    } else {
      newErrors[index] = ''
      setDomainErrorsFlag(false)
    }
    const newDomains = { ...domainName, [index]: value.toLowerCase() }
    setDomainName(newDomains)
    setErrors(newErrors)
    setIsLoadingDomainCheck((prevState) => {
      const newIsLoading = [...prevState]
      newIsLoading[index] = value.length !== 0
      return newIsLoading
    })

    if (value.length !== 0 && !newErrors[index]?.length) {
      handleCheckDomain(index, value)
    } else {
      setDomainCheck((prevState) => {
        const newDomainCheck = [...prevState]
        newDomainCheck[index] = false
        return newDomainCheck
      })
    }
  }

  const handleUrlChange = (index, e) => {
    const value = e?.target?.value
    const urlRegex =
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
    const newErrors = [...urlErrors]

    if (value && !urlRegex.test(value)) {
      newErrors[index] = 'Invalid url address'
      setUrlErrorsFlag(true)
    } else {
      newErrors[index] = ''
      setUrlErrorsFlag(false)
    }

    const newUrls = { ...urlAddress, [index]: value.toLowerCase() }
    setUrlAddress(newUrls)
    setUrlErrors(newErrors)
  }

  const handleArtifactRepoChange = (index, e) => {
    const regex = /^[a-z-]*$/
    const value = e.target.value
    if (regex.test(value)) {
      const newArtifactName = { ...artifactRepoName, [index]: value }
      setArtifactRepoName(newArtifactName)
    }
  }

  useEffect(() => {
    const combinedData = organizationBranches.map((item, index) => ({
      branchName: item,
      domain: domainName[index] || '',
      url: urlAddress[index] || '',
      artifactRepo: artifactRepoName[index] || '',
    }))
    setBranchesDomain(combinedData)
  }, [organizationBranches, domainName, urlAddress, artifactRepoName])

  /* createWebServiceFunc function is used to submit the api to create new application
   * @params formData is being used
   * further on success it navigate to /web-services page.
   */

  /**
   * Creates a new web service using the provided form data.
   *
   * @param {Object} formData - The data to be submitted for creating the web service.
   * @return {Promise<void>} A promise that resolves when the web service is created.
   */
  const createWebServiceFunc = async (formData) => {
    try {
      showSpinner()
      const cwsApiUrl = apiConfig.CREATE_NEW_SERVICE
      const response = await apiService.apiCall('post', cwsApiUrl, formData)
      if (response?.data?.code === 200) {
        localStorage.removeItem('repoFullName')
        addToast({
          title: TOAST.MESSAGES.SUCCESS.cwsSuccess,
          type: 'success',
        })
        reset() // Reset Form
        navigate(`/applications/${id}`)
      } else if (response?.data?.code === 3003) {
        addToast({
          title: response?.data?.data?.message
            ? TOAST.MESSAGES.ERROR.artifactNotAddedError
            : '',
          type: 'error',
        })
        navigate('/settings/artifact-repository')
      } else {
        addToast({
          title: response?.data?.msg || TOAST.MESSAGES.ERROR.swwError,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      hideSpinner()
    }
  }

  /**
   * Handles the application name input event.
   *
   * @param {Event} event - The event object triggered by the input.
   * @return {void} This function does not return anything.
   */
  const handleApplicationName = useCallback(
    debounce(async (event) => {
      if (event.target.value.trim() === '') return
      try {
        setIsLoading(true)
        const payload = {
          applicationName: toKebabCase(event.target.value),
          projectId: id,
        }
        const cwsApiUrl = apiConfig.GET_APPLICATION_CHECKNAME
        const response = await apiService.apiCall('post', cwsApiUrl, payload)
        if (response?.data?.code === 200) {
          setIsCreationAvailable(response?.data?.data?.isAvailableForCreation)
        } else {
          setIsCreationAvailable(false)
          addToast({
            title:
              response?.data?.error ||
              response?.data?.msg ||
              TOAST.MESSAGES.ERROR.swwError,
            type: 'error',
          })
        }
      } catch (error) {
        addToast({
          title: TOAST.MESSAGES.ERROR.swwError,
          type: 'error',
        })
      } finally {
        setIsLoading(false)
      }
    }, 500),
    []
  )

  const handleBranchChange = (e) => {
    const selectedRepo = e.target.value
    setConfigMapBranch(selectedRepo)
    setConfigErrors({})
  }

  /**
   * Creates a debounced version of the given function.
   *
   * @param {Function} func - The function to be debounced.
   * @param {number} wait - The time to wait before invoking the function.
   * @return {Function} - The debounced version of the function.
   */

  const handleAddFields = () => {
    setInputFields([...inputFields, { key: '', value: '' }])
    setValidity([...validity, { key: false, value: false }])
  }

  /**
   * Handles the input change event.
   *
   * @param {Event} e - The input change event object.
   * @return {void}
   */
  const handleInputChange = (e) => {
    const value = e.target.value
    setAppName(toKebabCase(value))

    if (value.length !== 0) {
      handleApplicationName(e)
    } else {
      setIsCreationAvailable(false)
    }
  }

  useEffect(() => {
    const existingData = configMapData?.find(
      (item) => item.env === configMapBranch
    )
    if (existingData) {
      setInputFields(existingData.data)
    } else {
      setInputFields([{ key: '', value: '' }])
    }
  }, [configMapBranch])

  useEffect(() => {
    const filteredInputFields = inputFields.filter(
      (field) => field.key !== '' || field.value !== ''
    )
    if (configMapBranch && filteredInputFields.length) {
      let newObj = {
        env: configMapBranch,
        data: filteredInputFields,
      }
      setConfigMapData((prevConfigMapData) => {
        const index = prevConfigMapData.findIndex(
          (item) => item.env === configMapBranch
        )

        if (index !== -1) {
          const updatedConfigMapData = [...prevConfigMapData]
          updatedConfigMapData[index] = newObj
          return updatedConfigMapData
        }
        return [...prevConfigMapData, newObj]
      })
    }
  }, [inputFields])

  const handleKeyChange = (index, event) => {
    const { value } = event.target
    const updatedInputFields = [...inputFields]
    updatedInputFields[index].key = value
      .replace(VALIDATION.SPACE_PATTERN, ' ')
      .trim()
    // Remove the keyError if the user starts typing
    const updatedErrors = { ...configErrors }
    if (value && updatedErrors[index]?.keyError) {
      delete updatedErrors[index].keyError
      if (Object.keys(updatedErrors[index]).length === 0) {
        delete updatedErrors[index]
      }
    }
    setInputFields(updatedInputFields)
    setConfigErrors(updatedErrors)
  }

  const handleKeyDown = (event) => {
    const allowedKeys = VALIDATION.ALLOWED_KEYS_PATTERN
    if (
      !event.key.match(allowedKeys) &&
      event.key !== 'Backspace' &&
      event.key !== 'Delete' &&
      event.key !== 'ArrowLeft' &&
      event.key !== 'ArrowRight'
    ) {
      event.preventDefault()
    }
  }

  const handleValueChange = (index, event) => {
    const { value } = event.target
    const updatedInputFields = [...inputFields]
    updatedInputFields[index].value = value
    // Remove the valueError if the user starts typing
    const updatedErrors = { ...configErrors }
    if (value && updatedErrors[index]?.valueError) {
      delete updatedErrors[index].valueError
      if (Object.keys(updatedErrors[index]).length === 0) {
        delete updatedErrors[index]
      }
    }
    setInputFields(updatedInputFields)
    setConfigErrors(updatedErrors)
  }

  const handleStepChange = (direction) => {
    setStepperData((prevData) => ({
      page: Math.max(prevData.page + direction),
    }))
  }

  const handleBackBtn = () => {
    navigate(`/application/${id}?createService=1`)
  }

  return (
    <div className="h-full w-full rounded-[20px]">
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <button
          onClick={handleBackBtn}
          className="ml-[20px] mt-[20px] flex w-fit items-center gap-2 text-sm font-bold text-blueSecondary transition hover:translate-x-1 dark:text-white"
        >
          <FaAnglesLeft strokeWidth={0.5} />
          <span>Back</span>
        </button>

        {(applicationType === 'webApp' || applicationType === 'frontend') && (
          <div className="stepper">
            <Stepper
              activeStep={stepperData.page}
              steps={steps}
              activeColor="#4318FF"
              completeColor="#4318FF"
              defaultColor="#6B7280"
              activeTitleColor={'#FFFFFF dark:#000000'}
              completeTitleColor={'dark:#000000'}
            />
          </div>
        )}
        <div className="mt-2">
          {/* step 1 */}
          {stepperData.page === APPLICATION_CREATE_STEPS.FIRST_STEP && (
            <div className="px-3 py-3 md:px-8 md:py-6">
              <div className="col-span-12 grid items-center md:col-span-12 md:flex">
                <div className="relative w-full">
                  <InputFieldSub
                    extra="mb-2 w-full"
                    label={LABEL.APP_NAME}
                    sublabel={LABEL.APP_NAME_SUB_LBL}
                    placeholder="Enter Unique Name"
                    id="application_name"
                    type="text"
                    isFieldRequired={true}
                    value={appName}
                    maxLength={MAX_LENGTH.APP_NAME}
                    onChange={handleInputChange}
                    registrationProps={register('application_name', {
                      required: 'Name is Required',
                      maxLength: {
                        value: MAX_LENGTH.APP_NAME,
                        message: MAX_LENGTH.application_maxlengthError.replace(
                          'app_maxLength',
                          MAX_LENGTH.APP_NAME
                        ),
                      },
                      onChange: handleInputChange,
                    })}
                    state={errors.application_name && 'error'}
                  />
                  {appName.trim() !== '' ? (
                    isLoading ? (
                      <span className="logs-loader absolute right-3 top-1/2 -translate-y-1/2 transform text-xl"></span>
                    ) : isCreationAvailable &&
                      appName.length <= MAX_LENGTH.APP_NAME ? (
                      <BsFillCheckCircleFill className="success-message text-inherit absolute right-3 top-1/2 h-6 w-6 -translate-y-1/2 transform" />
                    ) : (
                      <RxCrossCircled className="error-message text-inherit absolute right-3 top-1/2 h-6 w-6 -translate-y-1/2 transform" />
                    )
                  ) : null}
                </div>
              </div>
              <div className="text-right">
                <p className="text-xs font-medium text-gray-20 dark:text-gray-600">
                  {NOTE.KEBAB_CASE}
                </p>
              </div>
              {appName.trim() !== '' &&
                (isLoading ? (
                  <div className="mb-2 text-right text-sm text-gray-250">
                    Checking availability...
                  </div>
                ) : (
                  <div
                    className={`${
                      isCreationAvailable ? 'success-message' : 'error-message'
                    } mb-2 text-right`}
                  >
                    {appName.length <= MAX_LENGTH.APP_NAME && (
                      <span>
                        {isCreationAvailable
                          ? 'The application name is available'
                          : 'The application name is already in use.'}
                      </span>
                    )}
                  </div>
                ))}
              {errors?.application_name?.message && (
                <div className="error-message mb-2 text-right">
                  {errors?.application_name?.message}
                </div>
              )}
              {!checkOnlyService?.isNotService &&
                (applicationType === 'postgresql' ||
                  applicationType === 'redis') && (
                  <>
                    <div className="gird col-span-12 mt-2 items-center text-sm md:col-span-12 md:flex">
                      <label
                        htmlFor="color"
                        className="mr-3 flex-shrink-0 text-left md:w-1/3"
                      >
                        <span className="font-bold">
                          {LABEL.VERSION}{' '}
                          <span className="text-red-500">*</span>
                        </span>
                        <div className="text-xs">{`Please add version of ${applicationType}`}</div>
                      </label>
                      <div
                        className={`relative w-full rounded-xl border border-gray-200 p-1 text-sm font-normal text-black outline-none sm:w-full md:w-full ${
                          errors.flavor
                            ? 'border-red-500 dark:!border-red-400'
                            : 'border-gray-200 dark:!border-white/10'
                        }`}
                      >
                        <Controller
                          name="version"
                          control={control}
                          rules={{ required: 'Version is required' }}
                          render={({ field }) => (
                            <CustomSelect
                              {...field}
                              options={versions}
                              placeholderText="Select version"
                              onChange={(value) => {
                                field.onChange(value)
                              }}
                              isErrorField={errors.version ? true : false}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="error-message mb-2 mt-2 text-right">
                      {errors.version && <p>{errors.version.message}</p>}
                    </div>
                  </>
                )}
              {checkOnlyService?.isNotService && (
                <>
                  <div className="gird col-span-12 mt-2 items-center text-sm md:col-span-12 md:flex">
                    <label
                      htmlFor="color"
                      className="mr-3 flex-shrink-0 text-left md:w-1/3"
                    >
                      <span className="font-bold">
                        {LABEL.HEAD_BRANCH}{' '}
                        <span className="text-red-500">*</span>
                      </span>
                      <div className="text-xs">{LABEL.BRANCH_SUB_LBL}</div>
                    </label>
                    <div
                      className={`relative w-full rounded-xl border border-gray-200 p-1 text-sm font-normal text-black outline-none sm:w-full md:w-full ${
                        errors.flavor
                          ? 'border-red-500 dark:!border-red-400'
                          : 'border-gray-200 dark:!border-white/10'
                      }`}
                    >
                      {fetchingBranches ? (
                        <div className="flex h-10 w-full items-center pl-2 text-black hover:cursor-not-allowed dark:text-white">
                          <p className="mr-2">Loading Branches</p>
                          <span className="logs-loader -translate-y-1/2 transform text-xl"></span>
                        </div>
                      ) : (
                        <Controller
                          name="branches"
                          control={control}
                          rules={{ required: 'Head branch is required' }}
                          render={({ field }) => (
                            <CustomSelect
                              {...field}
                              options={branches}
                              value={field.value}
                              placeholderText="Select head branch"
                              onChange={(selectedOption) => {
                                setValue('branches', selectedOption)
                                field.onChange(selectedOption)
                              }}
                              isErrorField={errors.branches ? true : false}
                            />
                          )}
                        />
                      )}
                    </div>
                  </div>
                  <div className="error-message mb-2 mt-2 text-right">
                    {errors.branches && <p>{errors.branches.message}</p>}
                  </div>
                  {(applicationType === 'webApp' ||
                    applicationType === 'frontend') && (
                    <div className="col-span-12 grid items-center md:col-span-12 md:flex">
                      <div className="relative w-full">
                        <InputFieldSub
                          extra="mb-2 w-full"
                          label={LABEL.PORT}
                          sublabel={LABEL.PORT_SUB_LBL}
                          placeholder="Enter Port (e.g., 3000)"
                          id="port"
                          type="number"
                          value={port}
                          isFieldRequired={true}
                          registrationProps={register('port', {
                            onChange: (e) => {
                              if (e.target?.value?.length !== 0) {
                                handlePortChange(e)
                              }
                            },
                          })}
                          state={errors.port && 'error'}
                        />
                        <div className="error-message mb-2 mt-2 text-right">
                          {errors.port && <p>{errors.port.message}</p>}
                        </div>
                        {isPortNotValid && !errors.port && (
                          <div className="error-message mb-2 mt-2 text-right">
                            <p>Invalid port.</p>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
              {checkOnlyService?.isNotService && applicationType === 'cron' && (
                <>
                  <div className="col-span-12 grid items-center md:col-span-12 md:flex">
                    <div className="relative w-full">
                      <InputFieldSub
                        extra="mb-2 w-full"
                        label={LABEL.SCHEDULE}
                        sublabel={LABEL.SCHEDULE_SUB_LBL}
                        placeholder="Enter cron schedule (e.g., 0 * * * *)"
                        id="schedule"
                        type="text"
                        isFieldRequired={true}
                        infoIcon={true}
                        infoData={TOOLTIPS.SCHEDULE_INFO}
                        registrationProps={register('schedule', {
                          required: 'Schedule is Required',
                          onChange: (e) => {
                            if (e.target.value.length !== 0) {
                              handleCronScheduleChange(e)
                            }
                          },
                        })}
                        state={errors.schedule && 'error'}
                      />
                      <div className="error-message mb-2 mt-2 text-right">
                        {errors.schedule && <p>{errors.schedule.message}</p>}
                      </div>
                      {!isValid && !errors.schedule && (
                        <div className="error-message mb-2 mt-2 text-right">
                          <p>Invalid cron spec.</p>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-span-12 grid items-center md:col-span-12 md:flex">
                    <div className="relative w-full">
                      <InputFieldSub
                        extra="mb-2 w-full"
                        label={LABEL.COMMAND}
                        sublabel={LABEL.COMMAND_SUB_LBL}
                        placeholder="Enter command"
                        id="command"
                        type="text"
                        isFieldRequired={true}
                        registrationProps={register('command', {
                          required: 'Command is Required',
                        })}
                        state={errors.command && 'error'}
                      />
                      <div className="error-message mb-2 mt-2 text-right">
                        {errors.command && <p>{errors.command.message}</p>}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}

          {/* step 2 */}
          {stepperData.page === APPLICATION_CREATE_STEPS.SECOND_STEP && (
            <div className="px-3 py-3 md:px-8 md:py-6">
              {(applicationType === 'webApp' ||
                applicationType === 'frontend') && (
                <>
                  <div className="">
                    <p className="text-sm font-bold">{LABEL.MAPPING_HEADING}</p>
                    <p className="text-xs">
                      {cloudType === 'aws'
                        ? LABEL.MAPPING_ARTIFACT_LBL
                        : LABEL.MAPPING_SUB_LBL}
                    </p>
                  </div>
                  <div className="mt-4">
                    {organizationBranches.map((item, index) => (
                      <div className="mt-6" key={index}>
                        <div className="flex-shrink-0">
                          <div className="font-bold text-blueSecondary">
                            {item}
                          </div>
                        </div>
                        <div
                          className={`my-1 mt-[10px] flex ${
                            cloudType === 'aws' ? 'grid grid-cols-2 gap-4' : ''
                          } `}
                        >
                          <div className="relative flex w-full flex-row gap-4 md-max:flex-col">
                            <div className="w-full">
                              <div className="mb-2">{LABEL.DOMAIN_NAME}</div>
                              <div className="relative flex w-full items-center gap-3 rounded-xl border border-gray-200 outline-none dark:!border-white/10 dark:!bg-navy-800">
                                <input
                                  type="text"
                                  value={domainName[index] || ''}
                                  onChange={(e) => handleDomainChange(index, e)}
                                  className="placeholder:text-lightFourth md:text-md h-full w-full rounded-xl p-3 text-sm text-navy-700 outline-none dark:!bg-navy-800 dark:text-white"
                                  placeholder="Enter Domain Name"
                                  pattern="^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                                />
                              </div>
                              {domainExistErrors[index] ? (
                                <p className="text-right text-sm text-red-500">
                                  {domainExistErrors[index]}
                                </p>
                              ) : null}
                              {domainErrors[index] ? (
                                <p className="text-right text-sm text-red-500">
                                  {domainErrors[index]}
                                </p>
                              ) : domainName[index]?.length > 0 ? (
                                isLoadingDomainCheck[index] ? (
                                  <span className="logs-loader absolute right-3 top-11 -translate-y-1/2 transform text-xl"></span>
                                ) : domainCheck[index] ? (
                                  <BsFillCheckCircleFill className="success-message text-inherit absolute bottom-0 right-3 h-6 w-6 -translate-y-1/2 transform" />
                                ) : (
                                  <RxCrossCircled className="error-message text-inherit absolute bottom-4 right-3 h-6 w-6 -translate-y-1/2 transform" />
                                )
                              ) : null}
                            </div>
                            <div className="w-full">
                              <div className="mb-2">{LABEL.URL}</div>
                              <div className="relative flex w-full items-center gap-3 rounded-xl border border-gray-200 outline-none dark:!border-white/10 dark:!bg-navy-800">
                                <input
                                  type="text"
                                  value={urlAddress[index] || ''}
                                  onChange={(e) => handleUrlChange(index, e)}
                                  className="placeholder:text-lightFourth md:text-md h-full w-full rounded-xl p-3 text-sm text-navy-700 outline-none dark:!bg-navy-800 dark:text-white"
                                  placeholder="Enter URL Address"
                                />
                              </div>
                              {urlErrors[index] && (
                                <p className="text-right text-sm text-red-500">
                                  {urlErrors[index]}
                                </p>
                              )}
                            </div>
                          </div>
                          {cloudType === 'aws' && (
                            <div className="ml-4">
                              <div className="mb-2">
                                {LABEL.ARTIFACTS_REPOSITORY_NAME}
                              </div>
                              <div className="flex w-full items-center gap-3 rounded-xl border border-gray-200 outline-none dark:!border-white/10 dark:!bg-navy-800">
                                <input
                                  type="text"
                                  value={artifactRepoName[index] || ''}
                                  onChange={(e) =>
                                    handleArtifactRepoChange(index, e)
                                  }
                                  className="placeholder:text-lightFourth md:text-md h-full w-full rounded-xl p-3 text-sm text-navy-700 outline-none dark:!bg-navy-800 dark:text-white"
                                  placeholder="Enter Artifact Repository Name"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          )}

          {/* step 3 */}
          {stepperData.page === APPLICATION_CREATE_STEPS.THIRD_STEP && (
            <>
              <div className="h-full w-full p-2 md:p-4 ">
                {/* Branch Selector */}
                <div className="flex justify-end">
                  <div>
                    {validationError?.length > 0 && (
                      <div className="mt-4 text-sm text-red-500">
                        {NOTE.UPDATE_VALUES}{' '}
                        {validationError.length > 1 ? 'branches' : 'branch'}:{' '}
                        {branchesList}.
                      </div>
                    )}
                  </div>
                  <div className="mt-[1%] flex items-center justify-end md:ml-4">
                    <select
                      className="text-lightFourth w-30 border-gray mr-4 rounded-xl border-2 border-solid p-1.5 pr-4 text-sm dark:bg-navy-800 dark:text-white"
                      onChange={handleBranchChange}
                      value={configMapBranch}
                    >
                      {organizationBranches.map((branch, index) => (
                        <option key={index} value={branch}>
                          {branch}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {/* Key-Value Fields */}
                <div className="my-1">
                  <div className="mb-2 flex gap-4">
                    <div className="ml-2 w-[47%] text-start font-medium">
                      {STEPPER_SERVICE.KEY_LABEL}
                    </div>
                    <div className="ml-2 w-[47%] text-start font-medium">
                      {STEPPER_SERVICE.VALUE_LABEL}
                    </div>
                  </div>
                  {inputFields.map((inputField, index) => (
                    <div key={index} className="relative mb-4 flex gap-4">
                      {/* Key Input */}
                      <div className="relative flex w-1/2 flex-col">
                        <div className="relative flex w-full items-center gap-3 rounded-2xl border border-gray-200 pl-[8px] outline-none dark:!border-white/10 dark:!bg-navy-800">
                          <input
                            type="text"
                            className="placeholder:text-lightFourth md:text-md h-full w-full rounded-2xl p-3 text-sm text-navy-700 outline-none dark:!bg-navy-800 dark:text-white"
                            placeholder="Enter NAME_OF_VARIABLE"
                            name="key"
                            value={inputField.key}
                            autoComplete="off"
                            onChange={(event) => handleKeyChange(index, event)}
                            onKeyDown={handleKeyDown}
                          />
                          {inputField.key && (
                            <button
                              className="absolute right-2 top-2 rounded-full p-1 text-gray-500 hover:text-gray-700"
                              type="button"
                              onClick={() => handleClearKeyField(index)}
                            >
                              <RxCrossCircled />
                            </button>
                          )}
                        </div>
                        {configErrors[index] &&
                          configErrors[index]?.keyError && (
                            <span className="text-sm text-red-500">
                              {configErrors[index]?.keyError}
                            </span>
                          )}
                      </div>

                      {/* Value Input */}
                      <div className="relative flex w-1/2 flex-col">
                        <div className="relative flex w-full items-center gap-3 rounded-2xl border border-gray-200 pl-[8px] outline-none dark:!border-white/10 dark:!bg-navy-800">
                          <input
                            type="text"
                            className="placeholder:text-lightFourth md:text-md h-full w-full rounded-2xl p-3 text-sm text-navy-700 outline-none dark:!bg-navy-800 dark:text-white"
                            placeholder="Enter Value"
                            name="value"
                            value={inputField.value}
                            title={inputField.value}
                            autoComplete="off"
                            onChange={(event) =>
                              handleValueChange(index, event)
                            }
                          />
                          {inputField.value && (
                            <button
                              className="absolute right-2 top-2 rounded-full p-1 text-gray-500 hover:text-gray-700"
                              type="button"
                              onClick={() => handleClearValueField(index)}
                            >
                              <RxCrossCircled />
                            </button>
                          )}
                        </div>
                        {configErrors[index] &&
                          configErrors[index]?.valueError && (
                            <span className="text-sm text-red-500">
                              {configErrors[index]?.valueError}
                            </span>
                          )}
                      </div>

                      {/* Delete Button */}
                      <div className="flex items-center">
                        {inputFields?.length > 1 && (
                          <button
                            type="button"
                            onClick={() => handleDeleteField(index)}
                          >
                            <TooltipHorizon
                              extra="border border-gray-200 dark:border-gray-700"
                              trigger={
                                <p className="flex cursor-pointer items-center rounded-full border p-2 font-bold text-[#000] hover:font-medium hover:text-black dark:text-white">
                                  <span>
                                    <RiDeleteBinLine className="text-xl" />
                                  </span>
                                </p>
                              }
                              content="Delete"
                              placement="top"
                            />
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>

                {/* Add Field Button */}
                <div className="flex justify-end gap-4 pt-5">
                  <button
                    type="button"
                    className={`type-interface-01 text-default focus-visible:outline-focus-action flex h-10  items-center rounded-xl border border-solid border-gray-800 px-3 py-2.5 outline-2 outline-offset-2 dark:border-white  ${
                      inputFields.some(
                        (inputField) => !inputField.key || !inputField.value
                      )
                        ? 'cursor-not-allowed opacity-50'
                        : 'cursor-pointer hover:bg-brand-400 hover:text-white focus-visible:outline active:bg-gray-900 active:text-white'
                    }`}
                    onClick={() => handleAddFields()}
                    disabled={inputFields.some(
                      (inputField) => !inputField.key || !inputField.value
                    )}
                  >
                    {LABEL.ADD_LABEL}{' '}
                    <span className="text-md ml-1">
                      <HiPlus />
                    </span>
                  </button>
                </div>
              </div>
            </>
          )}
          <div className="px-3 py-3 md:px-8 md:py-6">
            {applicationType === 'webApp' || applicationType === 'frontend' ? (
              <div className="grid md:flex lg:flex">
                <div className="flex w-full items-center justify-end">
                  <button
                    onClick={() => handleStepChange(-1)}
                    disabled={
                      stepperData.page === APPLICATION_CREATE_STEPS.FIRST_STEP
                    }
                    type="button"
                    className={`${
                      stepperData.page === APPLICATION_CREATE_STEPS.FIRST_STEP
                        ? 'cursor-not-allowed opacity-50'
                        : ''
                    } rounded-xl bg-blueSecondary px-4 py-2 text-sm font-medium capitalize text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 md:ml-2`}
                  >
                    {STEPPER_SERVICE.PREVIOUS_STEP}
                  </button>
                  {stepperData.page !== APPLICATION_CREATE_STEPS.THIRD_STEP ? (
                    <button
                      onClick={() => handleStepChange(1)}
                      type="button"
                      disabled={!isCreationAvailable || !branches || !port}
                      className={`${
                        !isCreationAvailable || !branches || !port
                          ? 'cursor-not-allowed opacity-50'
                          : ''
                      } rounded-xl bg-blueSecondary px-4 py-2 text-sm font-medium capitalize text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 md:ml-2`}
                    >
                      {STEPPER_SERVICE.NEXT_STEP}
                    </button>
                  ) : (
                    <div className="grid md:flex lg:flex">
                      <div className="flex w-full items-center justify-end">
                        <button
                          className={`rounded-xl bg-blueSecondary px-4 py-2 text-sm font-medium capitalize text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 md:ml-2 ${
                            !isCreationAvailable
                              ? 'cursor-not-allowed opacity-50'
                              : ''
                          }`}
                          type="submit"
                          disabled={
                            !isCreationAvailable ||
                            isPortNotValid ||
                            domainErrorsFlag ||
                            urlErrorsFlag
                          }
                        >
                          {BUTTON_TEXT.CREATE_SERVICE}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="mt-7 grid md:flex lg:flex">
                <div className="flex w-full items-center justify-end">
                  <button
                    className={`rounded-xl bg-blueSecondary px-4 py-2 text-sm font-medium capitalize text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 md:ml-2 ${
                      !isCreationAvailable
                        ? 'cursor-not-allowed opacity-50'
                        : ''
                    }`}
                    type="submit"
                    disabled={
                      !isCreationAvailable || isPortNotValid || domainErrorsFlag
                    }
                  >
                    {BUTTON_TEXT.CREATE_SERVICE}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  )
}

export default Product
