import Card from 'components/card'
import React, { useState, useEffect } from 'react'
import { AiOutlineGlobal } from 'react-icons/ai'
import { FiSearch } from 'react-icons/fi'
import appConstants from 'common/config/appConstants'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import CustomSelect from 'common/CustomSelect'
import { useToaster } from 'common/Toaster'
import ApiCaller from 'common/services/apiServices'
import apiConfig from 'common/config/apiConfig'
import PaginationSkeleton from 'components/skeletons/PaginationSkeleton'
import moment from 'moment'

const SupportTickets = () => {
  const { addToast } = useToaster()
  const apiService = ApiCaller()
  const {
    SUPPORT_SORTBY_LIST,
    SUPPORT_PRIORITY_LIST,
    SUPPORT_STATUS_LIST,
    TOAST,
    NO_DATA_AVAIL,
  } = appConstants

  const { control } = useForm({
    defaultValues: {
      status: {
        label: 'All Status',
        value: 'All Status',
        defaultValues: true,
      },
      priority: {
        label: 'All Priority',
        value: 'All Priority',
        defaultPriority: true,
      },
    },
  })

  let navigate = useNavigate()
  const [pageIndex, setPageIndex] = useState(0)
  const itemsPerPage = 10
  const tableSkeletons = Array.from({ length: 4 })
  const [globalFilter, setGlobalFilter] = useState('')
  const priority = [
    { label: 'All Priority', value: 'All Priority', defaultPriority: true },
    ...SUPPORT_PRIORITY_LIST,
  ]
  const status = SUPPORT_STATUS_LIST
  const [sortByList] = useState(SUPPORT_SORTBY_LIST)
  const [selectedSortBy, setSelectedSortBy] = useState({
    label: 'Status',
    value: 'Status',
  })
  const [selectedStatus, setSelectedStatus] = useState(status[0])
  const [selectedPriority, setSelectedPriority] = useState(priority[0])
  const [ticketsList, setTicketsList] = useState([])
  const [initaltTicketsList, setInitaltTicketsList] = useState([])
  const [isTicketsListLoading, setIsTicketsListLoading] = useState(false)

  const changePage = (page) => {
    setPageIndex(page)
  }

  const fetchData = async (payload) => {
    setIsTicketsListLoading(true)
    try {
      const apiUrl = apiConfig.SUPPORT_TICKET_LIST
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.code === 200) {
        const updatedData = response?.data?.data.map((item) => {
          let statusLabel = ''
          switch (item.status) {
            case 2:
              statusLabel = 'Open'
              break
            case 3:
              statusLabel = 'Pending'
              break
            case 4:
              statusLabel = 'Resolved'
              break
            case 5:
              statusLabel = 'Closed'
              break
            default:
              statusLabel = 'Unknown'
          }
          return { ...item, statusLabel }
        })
        setInitaltTicketsList(updatedData)
        setTicketsList(updatedData)
      } else if (
        response?.data?.code === 204 ||
        (response?.data?.code === 500 &&
          response?.data?.error[0].field === 'email' &&
          response?.data?.error[0].code === 'invalid_value')
      ) {
        setTicketsList([])
      } else {
        addToast({
          title: response?.data?.msg || TOAST.MESSAGES.ERROR.swwError,
          type: 'error',
        })
      }
    } catch (err) {
      console.log('Error fetching data:', err)
    } finally {
      setIsTicketsListLoading(false)
    }
  }

  useEffect(() => {
    const payload = {}
    fetchData(payload)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handleFilter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPriority, selectedStatus])

  const handleFilter = () => {
    const { defaultPriority, priorityValue } = selectedPriority
    const { defaultValues, statusValue } = selectedStatus

    const filteredTickets = initaltTicketsList.filter((item) => {
      const priorityMatch = defaultPriority || item.priority === priorityValue
      const statusMatch = defaultValues || item.status === statusValue

      return priorityMatch && statusMatch
    })

    setTicketsList(filteredTickets)
  }

  const handleSearch = (event) => {
    setGlobalFilter(event.target.value)
    setPageIndex(0)
  }

  const [selectedSortOption, setSelectedSortOption] = useState('Ascending')

  const filteredTickets = ticketsList.filter(
    (ticket) =>
      ticket.subject.toLowerCase().includes(globalFilter.toLowerCase()) ||
      ticket.id.toString().includes(globalFilter)
  )
  // Function to filter and sort tickets
  const getPaginatedTickets = () => {
    let sortedTickets = [...filteredTickets] // Create a copy for sorting
    const { label } = selectedSortBy

    // Sorting logic
    switch (label) {
      case 'Date Created':
        sortedTickets.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        )
        break
      case 'Last Modified':
        sortedTickets.sort(
          (a, b) => new Date(a.updated_at) - new Date(b.updated_at)
        )
        break
      case 'Priority':
        sortedTickets.sort((a, b) => a.priority - b.priority)
        break
      case 'Status':
        sortedTickets.sort((a, b) => a.status - b.status)
        break

      default:
        break
    }

    if (selectedSortOption === 'Descending') {
      sortedTickets.reverse() // Reverse for descending
    }

    // Return paginated tickets after sorting
    return sortedTickets.slice(
      pageIndex * itemsPerPage,
      (pageIndex + 1) * itemsPerPage
    )
  }

  // Call the function to get the current paginated tickets
  const paginatedTickets = getPaginatedTickets()

  // Function to handle sort option change
  const handleSortByList = (newSortOption) => {
    if (
      newSortOption.value !== 'Descending' &&
      newSortOption.value !== 'Ascending'
    ) {
      setSelectedSortBy(newSortOption)
    } else {
      setSelectedSortOption(newSortOption.label)
    }
  }

  return (
    <Card extra={`flex flex-col w-full h-full !p-4 3xl:p-![18px] bg-white`}>
      <div className="h-full w-full p-2 md:p-4">
        <div className="flex">
          {/* Search Bar */}
          <div className="mb-3 flex w-[600px] items-center rounded-full bg-white p-[5px] shadow-2xl shadow-white dark:!bg-navy-800 dark:shadow-none md:mb-0">
            <div className="flex h-9 w-full flex-grow items-center rounded-full border-white bg-lightPrimary text-sm text-gray-600 dark:border dark:!bg-navy-900">
              <FiSearch className="mx-2 h-6 w-4 !text-gray-700 dark:!text-white" />
              <input
                type="text"
                placeholder="Search by subject or ticket ID"
                className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-800 dark:text-white dark:placeholder:!text-white sm:w-full"
                value={globalFilter}
                onChange={handleSearch}
              />
            </div>
          </div>

          {/* Status Filter */}
          <div className="p-[5px]">
            <div className="w-[200px] items-center rounded-2xl border border-gray-200 outline-none dark:!border-white/10 dark:!bg-navy-800">
              <Controller
                name="status"
                control={control}
                value={selectedStatus}
                render={({ field }) => (
                  <CustomSelect
                    options={status}
                    value={field.value}
                    placeholderText="Select Status"
                    onChange={(value) => {
                      field.onChange(value)
                      setSelectedStatus(value)
                      // handleFilter(value)
                    }}
                  />
                )}
              />
            </div>
          </div>

          {/* Priority Filter */}
          <div className="p-[5px]">
            <div className="w-[200px] items-center rounded-2xl border border-gray-200 outline-none dark:!border-white/10 dark:!bg-navy-800">
              <Controller
                name="priority"
                control={control}
                render={({ field }) => (
                  <CustomSelect
                    {...field}
                    value={selectedPriority}
                    options={priority}
                    placeholderText="Select Priority"
                    onChange={(value) => {
                      field.onChange(value)
                      setSelectedPriority(value)
                      // handleFilter(value)
                    }}
                  />
                )}
              />
            </div>
          </div>

          <div className="p-[5px]">
            <div className="w-[200px] items-center rounded-2xl border border-gray-200 outline-none dark:!border-white/10 dark:!bg-navy-800">
              <Controller
                name="sortByList"
                control={control}
                render={({ field }) => (
                  <CustomSelect
                    options={sortByList}
                    placeholderText="Sort by"
                    value={selectedSortBy}
                    onChange={(value) => {
                      field.onChange(value)
                      handleSortByList(value)
                    }}
                  />
                )}
              />
            </div>
          </div>
        </div>

        {isTicketsListLoading ? (
          <div className="flex flex-col">
            {tableSkeletons.map((_item, index) => (
              <div
                key={index}
                className={
                  'my-1 mt-2 flex animate-pulse items-center justify-between px-[33px] py-4'
                }
              >
                <div className="rounded-full bg-gray-200 p-3 dark:bg-gray-800"></div>
                <div className="ml-4 flex w-full flex-col gap-y-2">
                  <div className="flex gap-x-16">
                    <div className="mr-auto h-2 basis-1/2 rounded bg-gray-200 dark:bg-gray-800"></div>
                    <div className="h-2 basis-1/12 rounded bg-gray-200 dark:bg-gray-800"></div>
                  </div>
                  <div className="h-2 w-1/6 rounded bg-gray-200 dark:bg-gray-800"></div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div>
            {paginatedTickets.map((item) => (
              <div
                key={item.id}
                onClick={() => navigate(`/ticket-details/${item.id}`)}
                className="!dark:bg-white mt-2 block cursor-pointer pl-[33px] pr-[28px] transition-colors hover:bg-gray-100 dark:hover:bg-navy-600"
              >
                <div className="flex justify-between">
                  <div className="flex py-3">
                    <div>
                      <AiOutlineGlobal className="mr-2 mt-1 h-6 w-5 text-lightBlack dark:text-white" />
                    </div>
                    <div>
                      <div className="text-fixaText dark:text-white">
                        {item.subject} #{item.id}
                      </div>
                      <div className="text-gray-250">
                        Created on{' '}
                        {moment(item.created_at).format(
                          'ddd, D MMM, YYYY [at] h:mm A'
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`my-5 flex inline-flex items-center rounded border px-1 text-sm ${
                      item?.statusLabel === 'Open'
                        ? 'border-lightOrangeRed bg-OrangeRed text-darkOrangeRed'
                        : 'border-gray-400 bg-gray-200 text-gray-800'
                    }`}
                  >
                    {item?.statusLabel}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        {!isTicketsListLoading && paginatedTickets.length === 0 && (
          <div className="text-md mt-3 flex w-full justify-center text-left text-gray-500 dark:text-gray-400">
            {NO_DATA_AVAIL}
          </div>
        )}

        {isTicketsListLoading ? (
          <PaginationSkeleton />
        ) : (
          paginatedTickets.length > 0 && (
            <div className="mt-2 flex h-12 w-full items-center justify-between px-6">
              <div className="text-sm text-gray-700">
                {/* Display current range of entries */}
                Showing {pageIndex * itemsPerPage + 1} to{' '}
                {Math.min(
                  filteredTickets.length,
                  (pageIndex + 1) * itemsPerPage
                )}{' '}
                of {filteredTickets.length} entries
              </div>

              <div className="flex items-center">
                {/* Render pagination buttons */}
                <div className="ml-3 flex">
                  {/* First button */}
                  <button
                    className={`h-10 w-10 rounded-full bg-brand-500 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
                      pageIndex === 0 ? 'cursor-not-allowed opacity-50' : ''
                    }`}
                    onClick={() => changePage(0)}
                    disabled={pageIndex === 0}
                  >
                    {'<<'}
                  </button>

                  {/* Previous button */}
                  <button
                    className={`ml-3 h-10 w-10 rounded-full bg-brand-500 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
                      pageIndex === 0 ? 'cursor-not-allowed opacity-50' : ''
                    }`}
                    onClick={() => changePage(pageIndex - 1)}
                    disabled={pageIndex === 0}
                  >
                    {'<'}
                  </button>

                  {/* Pagination buttons */}
                  {Array.from(
                    {
                      length: Math.min(
                        5,
                        Math.ceil(filteredTickets.length / itemsPerPage)
                      ),
                    }, // Limit to a maximum of 5 pages
                    (_, index) => {
                      const page =
                        Math.max(
                          0,
                          Math.min(
                            Math.ceil(filteredTickets.length / itemsPerPage) -
                              5,
                            pageIndex
                          )
                        ) + index
                      return (
                        <button
                          key={page}
                          className={`ml-3 h-10 w-10 rounded-full bg-brand-500 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
                            pageIndex === page
                              ? 'cursor-not-allowed opacity-50'
                              : ''
                          }`}
                          onClick={() => changePage(page)}
                          disabled={pageIndex === page}
                        >
                          {page + 1}
                        </button>
                      )
                    }
                  )}

                  {/* Next button */}
                  <button
                    className={`ml-3 h-10 w-10 rounded-full bg-brand-500 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
                      pageIndex >=
                      Math.ceil(filteredTickets.length / itemsPerPage) - 1
                        ? 'cursor-not-allowed opacity-50'
                        : ''
                    }`}
                    onClick={() => changePage(pageIndex + 1)}
                    disabled={
                      pageIndex >=
                      Math.ceil(filteredTickets.length / itemsPerPage) - 1
                    }
                  >
                    {'>'}
                  </button>

                  {/* Last button */}
                  <button
                    className={`ml-3 h-10 w-10 rounded-full bg-brand-500 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
                      pageIndex >=
                      Math.ceil(filteredTickets.length / itemsPerPage) - 1
                        ? 'cursor-not-allowed opacity-50'
                        : ''
                    }`}
                    onClick={() =>
                      changePage(
                        Math.ceil(filteredTickets.length / itemsPerPage) - 1
                      )
                    }
                    disabled={
                      pageIndex >=
                      Math.ceil(filteredTickets.length / itemsPerPage) - 1
                    }
                  >
                    {'>>'}
                  </button>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </Card>
  )
}

export default SupportTickets
