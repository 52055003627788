import React, { useState, useEffect } from 'react'
import RadioServiceCard from 'components/radioServiceCard'
import KubecostSvg from 'assets/svg/kubecost.svg'
import CastAiSvg from 'assets/svg/cast-ai.svg'

const RadioServices = ({ setParentSelectedValue, previousCostOps }) => {
  const [selectedValue, setSelectedValue] = useState('')

  const [isDisabled, setIsDisabled] = useState({
    kubecost: true, // Default values to avoid null errors
    castai: true,
  })

  const handleRadioChange = (value) => {
    setSelectedValue(value)
    setParentSelectedValue(value)
  }

  useEffect(() => {
    const defaultService = previousCostOps || 'kubecost'
    setSelectedValue(defaultService === 'none' ? 'kubecost' : defaultService)
    setParentSelectedValue(
      defaultService === 'none' ? 'kubecost' : defaultService
    )

    const updatedDisabledState =
      defaultService === 'none'
        ? {
            kubecost: false,
            castai: false,
          }
        : {
            kubecost: defaultService !== 'kubecost',
            castai: defaultService !== 'castai',
          }

    setIsDisabled(updatedDisabledState)
  }, [previousCostOps])

  const services = [
    {
      value: 'kubecost',
      imageSrc: KubecostSvg,
      label: 'KubeCost',
    },
    {
      value: 'castai',
      imageSrc: CastAiSvg,
      label: 'CastAI',
    },
  ]

  return (
    <div className="">
      <p className="text-xl font-bold text-navy-900 dark:text-white">
        Choose Your Service <span className="text-sm text-red-500">*</span>
      </p>
      <div className="col-span-2 my-3 flex flex-wrap md:gap-4 lg:col-span-1">
        {services.map((service) => (
          <RadioServiceCard
            key={service.value}
            isDisabled={isDisabled[service.value]}
            selectedValue={selectedValue}
            value={service.value}
            onRadioChange={handleRadioChange}
            imageSrc={service.imageSrc}
            label={service.label}
          />
        ))}
      </div>
    </div>
  )
}

export default RadioServices
