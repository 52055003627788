import React, { useState, useEffect } from 'react'
import { HiPlus } from 'react-icons/hi'
import { RiDeleteBinLine } from 'react-icons/ri'
import { useDialogContext } from 'contexts/DialogProvider'
import { RxCrossCircled } from 'react-icons/rx'
import TooltipHorizon from '../../../../components/tooltip'
import appConstants from 'common/config/appConstants'

function SearchTableOrders(props) {
  const {
    toggleState,
    secretsData,
    onSelectedSecretsRowsChange,
    handleSecretsVerify,
    onSecretsRepoNameChange,
    branchNameList,
    loading,
  } = props

  const [inputFields, setInputFields] = useState([{ key: '', value: '' }])
  const [secret_is_Branch_name, setIsSecretBranchName] = useState([])
  const [secretMapRepoName, setSecretMapRepoName] = useState('')
  const [validity, setValidity] = useState([{ key: false, value: false }])
  const [hasChanges, setHasChanges] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  const [secretsBranchName, setSecretsBranchNames] = useState([])
  const { openDialog, onClose, setModalSubmitHandler, setLoading } =
    useDialogContext()
  const { WEB_SERVICES, BUTTON_TEXT, LABEL, VALIDATION } = appConstants
  const secretsSkeletons = Array.from({ length: 3 })

  useEffect(() => {
    if (secretsData && Array.isArray(secretsData) && secretsData.length > 0) {
      const initialInputFields = secretsData.map(({ key, value }) => ({
        key,
        value,
      }))
      setInputFields(initialInputFields)
      setValidity(initialInputFields.map(() => ({ key: true, value: true })))
    } else {
      setInputFields([{ key: '', value: '' }])
      setValidity([{ key: false, value: false }])
    }
  }, [secretsData])

  /**
   * Updates the inputFields state and validity state based on the input change event.
   *
   * @param {number} index - The index of the input field.
   * @param {Event} event - The input change event.
   * @return {void}
   */
  const handleInputChange = (index, event) => {
    const values = [...inputFields]
    const isValid = event.target.value.trim() !== ''
    if (event.target.name === 'key') {
      values[index] = {
        ...values[index],
        key: event.target.value.replace(VALIDATION.SPACE_PATTERN, ' ').trim(),
      }
    } else {
      values[index] = { ...values[index], value: event.target.value }
    }
    setInputFields(values)

    // Update validity state
    const validities = [...validity]
    validities[index] = {
      key: event.target.name === 'key' ? isValid : validities[index].key,
      value: event.target.name === 'value' ? isValid : validities[index].value,
    }
    setValidity(validities)
    setHasChanges(true)
  }
  const handleKeyDown = (event) => {
    const allowedKeys = VALIDATION.ALLOWED_KEYS_PATTERN
    if (
      !event.key.match(allowedKeys) &&
      event.key !== 'Backspace' &&
      event.key !== 'Delete' &&
      event.key !== 'ArrowLeft' &&
      event.key !== 'ArrowRight'
    ) {
      event.preventDefault()
    }
  }
  /**
   * Deletes a field at the specified index from the inputFields array and updates the state.
   *
   * @param {number} index - The index of the field to delete.
   * @return {void} This function does not return anything.
   */
  const handleDeleteField = (index) => {
    const values = [...inputFields]
    //eslint-disable-next-line
    const deletedRow = values.splice(index, 1)[0]
    setInputFields(values)
    setHasChanges(true)
  }
  useEffect(() => {
    if (branchNameList) {
      setSecretsBranchNames(branchNameList)
      setIsSecretBranchName(branchNameList)
      if (branchNameList && branchNameList.length > 0) {
        setSecretMapRepoName(branchNameList[0])
      }
    }
  }, [branchNameList])
  const handleClearKeyField = (index) => {
    const values = [...inputFields]
    values[index] = { ...values[index], key: '' }
    setInputFields(values)

    // Update validity state
    const validities = [...validity]
    validities[index] = { ...validities[index], key: false }
    setValidity(validities)
  }

  /**
   * Clears the value of the input field at the specified index.
   *
   * @param {number} index - The index of the input field to clear.
   * @return {void}
   */
  const handleClearValueField = (index) => {
    const values = [...inputFields]
    values[index] = { ...values[index], value: '' }
    setInputFields(values)

    // Update validity state
    const validities = [...validity]
    validities[index] = { ...validities[index], value: false }
    setValidity(validities)
  }

  /**
   * A function that adds a new set of input fields with default values to the existing input fields state.
   *
   * @return {void}
   */
  const handleAddFields = () => {
    setInputFields([...inputFields, { key: '', value: '' }])
    setValidity([...validity, { key: false, value: false }])
  }

  useEffect(() => {
    if (secretsData) {
      const initialInputFields = secretsData.map(({ key, value }) => ({
        key,
        value,
      }))
      setInputFields(initialInputFields)
      setValidity(initialInputFields.map(() => ({ key: true, value: true })))
    } else {
      setInputFields([{ key: '', value: '' }])
      setValidity([{ key: false, value: false }])
    }
    setIsEmpty(false)
    setHasChanges(false)
  }, [secretsData])

  useEffect(() => {
    if (onSecretsRepoNameChange) {
      onSecretsRepoNameChange(secretMapRepoName)
    }
  }, [secretMapRepoName, onSecretsRepoNameChange])
  useEffect(() => {
    onSelectedSecretsRowsChange(inputFields)
  }, [inputFields, onSelectedSecretsRowsChange])

  useEffect(() => {
    if (secretsData && inputFields.length === 1) {
      const isEmpty = inputFields?.every(
        (field) => field.key === '' && field.value === ''
      )
      const isEmptysecretsData = secretsData?.every(
        (field) => field.key !== '' && field.value !== ''
      )
      if (isEmptysecretsData && isEmpty) {
        setIsEmpty(true)
      } else {
        setIsEmpty(false)
      }
    }
  }, [inputFields])

  /**
   * A function that handles saving changes by opening a dialog box.
   *
   * @return {void}
   */
  const handleSaveChanges = () => {
    openDialog(
      <h2 className="text-3xl font-bold text-navy-700 dark:text-white">
        {LABEL.GENERIC_MODAL_TITLE}
      </h2>,
      <div className="text-lg">
        <div className="mb-4 border-b border-gray-200 dark:border-[#6a7489]"></div>
        <p>{LABEL.GENERIC_MODAL_MESSAGE}</p>
      </div>,
      {},
      'Save',
      'Cancel'
    )
  }

  useEffect(() => {
    /**
     * A function that handles data by setting loading state, verifying secrets, closing, and updating changes.
     *
     * @return {Promise<void>} Promise that resolves once data handling is complete.
     */
    const handleData = async () => {
      try {
        setLoading(true)
        await handleSecretsVerify()
        onClose()
        setHasChanges(false)
      } catch (error) {
        console.error('Error while verifying:', error)
      } finally {
        setLoading(false)
      }
    }
    if (toggleState === WEB_SERVICES.SECRETS) {
      setModalSubmitHandler(() => handleData)
    }
  }, [
    handleSecretsVerify,
    onClose,
    setLoading,
    setModalSubmitHandler,
    toggleState,
    WEB_SERVICES,
  ])

  /**
   * A function that handles branch change by setting the selected repository and updating the secret map repository name.
   *
   * @param {Event} e - The event object triggering the function.
   * @return {void} No return value.
   */
  const handleBranchChange = (e) => {
    const selectedRepo = e.target.value
    setSecretMapRepoName(selectedRepo)
  }
  useEffect(() => {
    if (toggleState !== WEB_SERVICES.CONFIG_MAP) {
      if (secretsBranchName) {
        setSecretMapRepoName(secretsBranchName[0])
      }
    }
    //eslint-disable-next-line
  }, [toggleState, WEB_SERVICES])
  return (
    <>
      {toggleState === WEB_SERVICES.SECRETS &&
        (!loading ? (
          <div className="h-full w-full p-2 md:p-4">
            <div className="mt-[1%] flex items-center justify-end md:ml-4">
              <select
                className="text-lightFourth w-30 border-gray mr-4 rounded-xl border-2 border-solid p-1.5 pr-4 text-sm dark:bg-navy-800 dark:text-white"
                name=""
                id=""
                onChange={handleBranchChange}
                value={secretMapRepoName}
                placeholderText="Select Repository"
              >
                {secret_is_Branch_name.map((branch, index) => (
                  <option key={index} value={branch}>
                    {branch}
                  </option>
                ))}
              </select>
            </div>

            <div className="my-1">
              <div className="mb-2 flex gap-4">
                <div className="ml-2 w-[47%] text-start font-medium">Key</div>
                <div className="ml-2 w-[47%] text-start font-medium">Value</div>
              </div>

              {inputFields.map((inputField, index) => (
                <div key={index} className="relative mb-4 flex gap-4">
                  <div className="relative flex w-1/2 flex-col">
                    <div className="relative flex w-full items-center gap-3 rounded-2xl border border-gray-200 pl-[8px] outline-none dark:!border-white/10 dark:!bg-navy-800">
                      <input
                        type="text"
                        className="placeholder:text-lightFourth md:text-md h-full w-full rounded-2xl p-3 text-sm text-navy-700 outline-none dark:!bg-navy-800 dark:text-white"
                        placeholder="Enter NAME_OF_VARIABLEs"
                        name="key"
                        value={inputField.key}
                        autoComplete="off"
                        onChange={(event) => handleInputChange(index, event)}
                        onKeyDown={handleKeyDown}
                      />
                      {inputField.key && (
                        <button
                          className="absolute right-2 top-2 rounded-full p-1 text-gray-500 hover:text-gray-700"
                          type="button"
                          onClick={() => handleClearKeyField(index)}
                          disabled={!validity[index]?.key}
                        >
                          <RxCrossCircled />
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="relative flex w-1/2 flex-col">
                    <div className="relative flex w-full items-center gap-3 rounded-2xl border border-gray-200 pl-[8px] outline-none dark:!border-white/10 dark:!bg-navy-800">
                      <input
                        type="password"
                        className="placeholder:text-lightFourth md:text-md h-full w-full rounded-2xl p-3 text-sm text-navy-700 outline-none dark:!bg-navy-800 dark:text-white"
                        placeholder="Enter Value"
                        name="value"
                        value={inputField.value}
                        title={inputField.value}
                        autoComplete="off"
                        onMouseEnter={(e) => (e.target.type = 'text')}
                        onMouseLeave={(e) => (e.target.type = 'password')}
                        onChange={(event) => handleInputChange(index, event)}
                      />
                      {inputField.value && (
                        <button
                          className="absolute right-2 top-2 rounded-full p-1 text-gray-500 hover:text-gray-700"
                          type="button"
                          onClick={() => handleClearValueField(index)}
                          disabled={!validity[index]?.value}
                        >
                          <RxCrossCircled />
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center">
                    {inputFields?.length > 1 && (
                      <button
                        type="button"
                        onClick={() => handleDeleteField(index)}
                      >
                        <TooltipHorizon
                          extra="border border-gray-200 dark:border-gray-700"
                          trigger={
                            <p className="flex cursor-pointer items-center rounded-full border p-2 font-bold text-[#000] hover:font-medium hover:text-black dark:text-white">
                              <span>
                                <RiDeleteBinLine className="text-xl" />
                              </span>
                            </p>
                          }
                          content="Delete"
                          placement="top"
                        />
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div className="flex justify-end gap-4 pt-5">
              <button
                className="type-interface-01 text-default focus-visible:outline-focus-action flex h-10 cursor-pointer items-center rounded-xl border border-solid border-gray-800 px-3 py-2.5 outline-2 outline-offset-2 hover:bg-brand-500 hover:text-white focus-visible:outline active:bg-gray-900 active:text-white dark:border-white"
                type="submit"
                onClick={() => handleAddFields()}
              >
                {LABEL.ADD_LABEL}{' '}
                <span className="text-md ml-1">
                  <HiPlus />
                </span>
              </button>
              <button
                className={`cursor-pointer rounded-xl bg-blueSecondary px-4 py-2 text-sm font-medium capitalize text-white transition duration-200 ${
                  !isEmpty &&
                  (!hasChanges ||
                    inputFields.some((field) => !field.key || !field.value))
                    ? 'cursor-not-allowed opacity-50'
                    : 'hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90'
                } md:ml-2`}
                onClick={() => handleSaveChanges()}
                disabled={
                  !isEmpty &&
                  (!hasChanges ||
                    inputFields.some((field) => !field.key || !field.value))
                }
              >
                {BUTTON_TEXT.SAVE_CHNG}
              </button>
            </div>
          </div>
        ) : (
          <div className="flex animate-pulse flex-col gap-y-8 p-6">
            <div className="h-8 w-32 self-end rounded-xl bg-gray-200 dark:bg-gray-800"></div>

            <div className="flex flex-col gap-y-4">
              {secretsSkeletons.map((_item, index) => (
                <div key={index} className="flex gap-x-4">
                  <div className="flex w-full flex-col gap-y-2">
                    <div className="h-2 w-1/4 rounded bg-gray-200 dark:bg-gray-800"></div>
                    <div className="h-8 rounded-xl bg-gray-200 dark:bg-gray-800"></div>
                  </div>
                  <div className="flex w-full flex-col gap-y-2">
                    <div className="h-2 w-1/4 rounded bg-gray-200 dark:bg-gray-800"></div>
                    <div className="h-8 rounded-xl bg-gray-200 dark:bg-gray-800"></div>
                  </div>
                </div>
              ))}
            </div>

            <div className="flex justify-end gap-x-6">
              <div className="h-8 w-64 rounded-xl bg-gray-200 dark:bg-gray-800"></div>
              <div className="h-8 w-28 rounded-xl bg-gray-200 dark:bg-gray-800"></div>
            </div>
          </div>
        ))}
    </>
  )
}

export default SearchTableOrders
