import axios from 'axios'

const handle401Error = () => {
  const theme = localStorage.getItem('darkMode') || false
  localStorage.clear()
  localStorage.setItem('darkMode', theme)
}

const ApiCaller = () => {
  const apiCall = async (httpType, url, data, options) => {
    let headers
    if (localStorage.getItem('bearerToken')) {
      headers = {
        Authorization: localStorage.getItem('bearerToken'),
      }
    }
    try {
      let response
      switch (httpType.trim().toLowerCase()) {
        case 'get':
          response = await axios.get(url, { headers })
          break
        case 'post':
          response = await axios.post(url, data, { headers })
          break
        case 'put':
          response = await axios.put(url, data, { ...options, headers })
          break
        case 'patch':
          response = await axios.patch(url, data, { ...options, headers })
          break
        case 'delete':
          response = await axios.delete(url, { ...options, headers, data })
          break
        default:
          throw new Error('Invalid HTTP method provided')
      }
      if (response?.data?.code === 401) {
        handle401Error()
      }
      return response
    } catch (error) {
      if (error?.response?.status === 401) {
        handle401Error()
      }
      return error.response
    }
  }

  return { apiCall }
}

export default ApiCaller
