import Card from 'components/card'
import TooltipHorizon from 'components/tooltip'
import { useEffect, useState } from 'react'
import { cn } from 'utils/cn'
import { FiRefreshCw } from 'react-icons/fi'
import InfiniteScroll from 'react-infinite-scroll-component'
import CustomSelect from './CustomSelect'
import appConstansts from 'common/config/appConstants'
import UptimeKumaLogoSvg from 'assets/svg/UptimeKumaLogoSvg'
import {
  timeAgo,
  formatDate,
  processResult,
} from 'common/uptimeKumaCommonFunctions'

const ServiceUptimeStatusCard = ({
  uptimeData,
  uptimeKumaPages,
  onDropDownChange,
  isUptimeKumaAPILoading,
  uptimeKumaSkeletons,
  selectedSlug,
  requiredRefresh,
  setSelectedSlug,
  hasError,
}) => {
  const dropdownOptions = uptimeKumaPages?.map((page) => ({
    label: page?.title,
    value: page?.slug,
  }))

  const { UPTIME_STATUS_PAGE_URL, SYSTEM_HEALTH_CHECK_TEXT, UPTIME_KUMA_UI } =
    appConstansts

  const [selectedData, setSelectedData] = useState(dropdownOptions[0])
  const [result, setResult] = useState([])
  const [processedArray, setProcessedArray] = useState([])
  const [isSameSlug, setIsSameSlug] = useState(false)

  const handleUptimeKuma = (selectedOption) => {
    const selectedSlug = selectedOption?.slug || selectedOption?.value
    const selectedPage = uptimeKumaPages.find(
      (page) => page.slug === selectedSlug
    )
    const selectedTitle = selectedPage?.title
    uptimeData.projectName = selectedTitle
    setSelectedSlug(selectedSlug)
    onDropDownChange(selectedSlug)
    setSelectedData(selectedOption)
    setIsSameSlug(false)
    setResult()
    setProcessedArray([])
  }

  const reloadData = () => {
    onDropDownChange(selectedSlug)
    setIsSameSlug(false)
    setResult()
    setProcessedArray([])
  }

  const fetchMoreData = async (page) => {
    if (page <= uptimeData?.options?.totalPages) {
      setIsSameSlug(true) // Indicating more data is being fetched
      try {
        await onDropDownChange(selectedSlug, page) // Fetch next page of data
      } catch (error) {
        console.error('Error fetching more data:', error)
        setIsSameSlug(false) // Stop loader
      }
    }
  }

  useEffect(() => {
    if (isSameSlug && uptimeData?.result?.length > 0) {
      setResult((prev) => [...prev, ...uptimeData.result])
    } else if (!isSameSlug) {
      setResult(uptimeData?.result)
    }
  }, [uptimeData?.result])

  useEffect(() => {
    if (!result) {
      setProcessedArray([])
      setResult([])
      return
    }

    const updatedArray = processResult(result)
    setProcessedArray(updatedArray)
  }, [result])

  return (
    <Card
      extra={
        'p-6 !mt-0 h-[400px] w-full overflow-y-hidden overflow-x-scroll 2xl:overflow-x-hidden'
      }
    >
      <div className="mb-4 flex justify-between font-bold text-[#2B3674] dark:text-white">
        <div
          title={
            selectedSlug
              ? `${UPTIME_STATUS_PAGE_URL}/${selectedSlug}`
              : SYSTEM_HEALTH_CHECK_TEXT
          }
          className="flex items-center justify-center gap-1 hover:cursor-pointer"
          onClick={() => {
            if (selectedSlug) {
              const url = `${UPTIME_STATUS_PAGE_URL}/${selectedSlug}`
              window.open(url, '_blank')
            }
          }}
        >
          <UptimeKumaLogoSvg width={40} height={40} />
          <p className="text-xl font-bold text-navy-700 dark:text-white">
            {uptimeData?.projectName}
          </p>
        </div>
        <div className="mr-6 flex items-center">
          {uptimeKumaPages.length > 0 && (
            <div className="flex w-[250px] items-center justify-end md:ml-4">
              <div className="text-lightFourth w-full rounded-xl border-2 border-solid border-gray-200 p-1.5 text-sm dark:bg-navy-800 dark:text-white">
                <CustomSelect
                  options={dropdownOptions}
                  onChange={handleUptimeKuma}
                  placeholderText="Select Status Page"
                  value={selectedData}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {isUptimeKumaAPILoading ? (
        uptimeKumaSkeletons.map((_data, index) => (
          <div
            key={index}
            className="flex h-16 animate-pulse items-center gap-3 pb-4"
          >
            <div className="h-6 w-28 rounded-xl bg-gray-200 dark:bg-gray-800"></div>
            <div className="h-6 w-28 rounded-xl bg-gray-200 dark:bg-gray-800"></div>
            <div className="ml-auto h-6 w-56 rounded-xl bg-gray-200 dark:bg-gray-800"></div>
          </div>
        ))
      ) : !uptimeData?.result ? (
        requiredRefresh ? (
          <div
            className="flex h-36 flex-col items-center justify-center
           gap-4 text-gray-500 dark:text-gray-400"
          >
            <div>
              <FiRefreshCw
                onClick={reloadData}
                className="inline-block cursor-pointer text-lg transition-transform duration-300 ease-in-out hover:rotate-180"
              />
            </div>
            <p>{UPTIME_KUMA_UI.REFRESH_DATA}</p>
          </div>
        ) : (
          <div className="flex h-36 items-center justify-center text-gray-500 dark:text-gray-400">
            <p>{UPTIME_KUMA_UI.NO_DATA_AVAILABLE}</p>
          </div>
        )
      ) : uptimeData?.result.length === 0 ? (
        <div className="flex h-36 items-center justify-center text-gray-500 dark:text-gray-400">
          <p>{UPTIME_KUMA_UI.NO_DATA_AVAILABLE}</p>
        </div>
      ) : (
        // Adjust height and overflow for scrollable container
        <div id="infiniteScrollable" className="max-h-[300px] overflow-auto">
          <InfiniteScroll
            // Use the correct length of the data
            dataLength={result?.length || 0} // This should reflect the total number of items loaded so far
            next={() => fetchMoreData(uptimeData?.options?.currentPage + 1)}
            hasMore={
              uptimeData?.options?.currentPage < uptimeData?.options?.totalPages
            }
            endMessage={
              <p
                className={`text-md mt-2 text-center text-gray-600 ${
                  result?.length > 4 ? '' : 'hidden'
                }`}
              >
                {UPTIME_KUMA_UI.REACHED_END}
              </p>
            }
            loader={
              !hasError &&
              uptimeKumaSkeletons?.map((_data, index) => (
                <div
                  key={index}
                  className="flex h-16 animate-pulse items-center gap-3 pb-4"
                >
                  <div className="h-6 w-28 rounded-xl bg-gray-200 dark:bg-gray-800"></div>
                  <div className="h-6 w-28 rounded-xl bg-gray-200 dark:bg-gray-800"></div>
                  <div className="ml-auto h-6 w-56 rounded-xl bg-gray-200 dark:bg-gray-800"></div>
                </div>
              ))
            }
          >
            {/* Render the data */}
            {processedArray?.map((item, index) =>
              item.data.length > 0 ? (
                <div className="flex h-16 items-center gap-3" key={index}>
                  <div
                    className={cn(
                      'h-fit min-w-14 whitespace-nowrap rounded-[7px] px-2 text-center text-sm font-medium',
                      {
                        'bg-[#5CDD8B] text-[#020B05]':
                          item?.data[item.data.length - 1].status === 'up',
                        'bg-[#DC3545] text-white':
                          item?.data[item.data.length - 1].status === 'down',
                        'bg-[#F8A306] text-white':
                          item?.data[item.data.length - 1].status === 'pending',
                        'bg-gray-250 text-white': item?.isActive === false,
                      }
                    )}
                  >
                    {`${item.uptimePercentage} %`}
                  </div>

                  <div
                    className={cn('min-w-72 grow whitespace-nowrap font-bold', {
                      'text-[#2B3674] dark:text-white': item?.isActive === true,
                      'text-gray-250': item?.isActive === false,
                    })}
                  >
                    {item.serviceName}
                  </div>

                  <div className="border-1 flex w-48p flex-col">
                    <div className="flex w-full justify-around gap-1">
                      {item.data.map((tick, tickIndex) => (
                        <TooltipHorizon
                          key={tickIndex}
                          content={!tick?.isEmpty && formatDate(tick.date)}
                          placement="top"
                          extra={`border ${
                            tick.status === 'up'
                              ? 'border-[#5CDD8B]'
                              : tick.status === 'down'
                              ? 'border-[#DC3545]'
                              : 'border-[#F8A306]'
                          } ${item.isActive === false && 'border-gray-250'} ${
                            item.isActive &&
                            item.isEmpty &&
                            'border-black dark:border-white'
                          }`}
                          trigger={
                            <div
                              className={cn('h-6 w-2 rounded-sm', {
                                'bg-[#5CDD8B] hover:bg-[#5CDD8B]/60':
                                  tick.status === 'up',
                                'bg-[#DC3545] hover:bg-[#DC3545]/60':
                                  tick.status === 'down',
                                'bg-[#F8A306] hover:bg-[#F8A306]/60':
                                  tick.status === 'pending',
                                'bg-gray-250 hover:bg-gray-250':
                                  item?.isActive === false || tick?.isDummy,
                                'border-[.5px] border-solid border-[#55565919] bg-[#55565919] hover:bg-[#55565919] dark:border-[#55565939] dark:bg-[#55565939] dark:hover:bg-[#55565939]':
                                  item?.isActive && tick?.isEmpty,
                                'cursor-pointer':
                                  item?.isActive && !tick?.isEmpty,
                              })}
                            />
                          }
                        />
                      ))}
                    </div>

                    <div className="flex justify-between">
                      <div className="text-xs font-medium text-[#A3AED0]">
                        {timeAgo(item?.data[0].date)}
                      </div>
                      <div className="text-xs font-medium text-[#A3AED0]">
                        {timeAgo(item?.data[item.data.length - 1].date)}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null
            )}
          </InfiniteScroll>
        </div>
      )}
    </Card>
  )
}

export default ServiceUptimeStatusCard
