import React from 'react'

const UptimeKumaLogoSvg = ({ width, height }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width ? width.toString() : '50'}
        height={height ? height.toString() : '50'}
        viewBox="0 0 640 640"
      >
        <g transform="matrix(1 0 0 1 320 320)">
          <linearGradient
            id="S3"
            gradientUnits="userSpaceOnUse"
            gradientTransform="matrix(1 0 0 1 -319.99875 -320.0001577393)"
            x1="259.78"
            y1="261.15"
            x2="463.85"
            y2="456.49"
          >
            <stop stopColor="#5CDD8B" />
            <stop offset="1" stopColor="#86E6A9" />
          </linearGradient>
          <path
            style={{
              stroke: 'rgb(242,242,242)',
              strokeOpacity: 0.51,
              strokeWidth: 200,
              strokeDasharray: 'none',
              strokeLinecap: 'butt',
              strokeDashoffset: 0,
              strokeLinejoin: 'miter',
              strokeMiterlimit: 4,
              fill: 'url(#S3)',
              fillRule: 'nonzero',
              opacity: 1,
            }}
            transform="translate(0, 0)"
            d="M 170.40125 -84.36016 C 224.09125 38.37984 224.09125 115.33984 170.40125 146.49984 C 89.85125000000001 193.23984000000002 -120.03875 207.48984000000002 -180.45875 135.63984 C -220.73875 87.73983999999999 -220.73875 14.399839999999998 -180.45875 -84.36016000000001 C -139.49875 -151.82016 -81.28875000000001 -185.55016 -5.828750000000014 -185.55016 C 69.64124999999999 -185.55016 128.38125 -151.82016000000002 170.40124999999998 -84.36016000000001 z"
            strokeLinecap="round"
          />
        </g>
      </svg>
    </>
  )
}

export default UptimeKumaLogoSvg
