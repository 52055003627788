import React from 'react'
import { RxCross2 } from 'react-icons/rx'

const ModalForConfirmDelete = ({ onCancel, onConfirm, message, title }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="fixed inset-0 bg-black !opacity-30"></div>
      <div className="z-10 flex w-full max-w-[550px] flex-col rounded-[20px] bg-white py-6 shadow-lg dark:bg-navy-800">
        <div className="flex justify-between px-8 pb-2">
          <h2 className="text-3xl font-bold text-navy-700 dark:text-white">
            {title}
          </h2>
          <span className="flex items-center justify-center">
            <RxCross2
              size={30}
              className="text-black hover:cursor-pointer dark:text-white"
              onClick={onCancel}
            />
          </span>
        </div>
        <div className="my-4 border-b border-gray-200 dark:border-[#6a7489]"></div>
        <div className="px-8">
          <p className="mb-4">{message}</p>
          <div className="flex items-center justify-end gap-4">
            <button
              onClick={onCancel}
              className="rounded-xl bg-gray-300 px-2 py-2 text-black hover:cursor-pointer hover:bg-gray-200"
            >
              Cancel
            </button>
            <button
              onClick={onConfirm}
              className="rounded-xl bg-red-500 px-2 py-2 text-white hover:cursor-pointer hover:bg-red-600"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalForConfirmDelete
