import React from 'react'
import DOMPurify from 'dompurify'
import { CiCircleQuestion } from 'react-icons/ci'
import TooltipHorizon from '../../components/tooltip'
/**
 * React component for an input field.
 *
 * @param {Object} props - the properties passed to the component
 * @return {JSX.Element} the input field component
 */
function InputField(props) {
  const {
    label,
    id,
    extra,
    type,
    placeholder,
    variant,
    state,
    disabled,
    noBorder,
    onClick,
    onChange,
    registrationProps,
    isFieldRequired,
    infoIcon,
    infoData,
    onKeyDown,
  } = props
  const sanitizedInfoData = DOMPurify.sanitize(infoData)
  /**
   * Handle input change event.
   *
   * @param {event} event - input change event
   * @return {void} no return value
   */
  return (
    <div className={`${extra}`}>
      <div className="flex">
        <label
          htmlFor={id}
          className={`text-sm text-navy-700 dark:text-white ${
            variant === 'auth' ? 'ml-1.5 font-medium' : 'font-bold'
          }`}
        >
          {label} {isFieldRequired && <span className="text-red-500">*</span>}
        </label>
        {infoIcon && (
          <div className="ml-1">
            <TooltipHorizon
              extra="border border-gray-200 dark:border-gray-700"
              trigger={
                <p className="flex cursor-pointer hover:font-medium hover:text-black dark:text-white">
                  <span>
                    <CiCircleQuestion />
                  </span>
                </p>
              }
              content={
                <div dangerouslySetInnerHTML={{ __html: sanitizedInfoData }} />
              }
              placement="top"
            />
          </div>
        )}
      </div>
      <input
        onClick={onClick}
        onChange={onChange}
        disabled={disabled}
        type={type}
        id={id}
        onKeyDown={onKeyDown}
        autoComplete="off"
        placeholder={placeholder}
        className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none ${
          disabled === true
            ? 'border-1 cursor-not-allowed border-gray-300 !bg-gray-100 placeholder:!text-[rgba(0,0,0,0.7)] dark:border-white/50 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.8)]'
            : state === 'error'
            ? 'border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400'
            : state === 'success'
            ? 'border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400'
            : 'border-gray-200 dark:!border-white/25 dark:text-white'
        } ${noBorder ? 'w-11/12  border-none' : ''}`}
        {...registrationProps}
      />
    </div>
  )
}

export default InputField
