import { useState, useEffect } from 'react'
import { FiSearch } from 'react-icons/fi'
import { MdEdit, MdOutlineDeleteForever } from 'react-icons/md'
import TooltipHorizon from '../../components/tooltip'
import Card from 'components/card'
import appConstants from 'common/config/appConstants'
import { useSpinner } from 'common/SpinnerLoader'
import { useToaster } from 'common/Toaster'
import ApiCaller from 'common/services/apiServices'
import apiConfig from 'common/config/apiConfig'
import ModalForUserDetails from './components/ModalForUserDetails'
import ModalForConfirmDelete from './components/ModalForConfirmDelete'
import Pagination from './components/Pagination'
import debounce from 'lodash.debounce'
import { RxCross2 } from 'react-icons/rx'
import { RxCheck } from 'react-icons/rx'

const UserInfo = () => {
  const apiService = ApiCaller()
  const { NO_DATA, TOAST, USER_INFO } = appConstants

  const { addToast } = useToaster()
  const { showSpinner, hideSpinner } = useSpinner()

  const [globalFilter, setGlobalFilter] = useState('')
  const [userLists, setUserLists] = useState([])
  const [paginationData, setPaginationData] = useState({})
  const [isUserSelected, setIsUserSelected] = useState(null)
  const [rowToDelete, setRowToDelete] = useState('')

  const TABLE_HEADER = {
    SR_NO: 'S.NO',
    FIRST_NAME: 'FIRST NAME',
    LAST_NAME: 'LAST NAME',
    EMAIL: 'EMAIL',
    IS_EMAIL_VERIFIED: 'EMAIL VERIFIED',
    EDIT: 'EDIT',
    DELETE: 'DELETE',
  }

  // Debounced function
  const debouncedFetch = debounce((filter) => {
    getAllUsers(1, filter)
  }, 500)

  // Effect to trigger debounced fetch whenever globalFilter changes
  useEffect(() => {
    debouncedFetch(globalFilter)

    // Cleanup debounce on unmount
    return () => {
      debouncedFetch.cancel()
    }
  }, [globalFilter])

  const getAllUsers = async (page = 1, term = '') => {
    showSpinner()
    try {
      let apiUrl = apiConfig.GET_ALL_USERS.replace(':page', page)

      // Only add the term parameter if it's not empty
      if (term) {
        apiUrl = apiUrl.replace(':term', term)
      } else {
        apiUrl = apiUrl.replace('?term=:term', '') // Adjust this depending on your API URL structure
      }

      const response = await apiService.apiCall('get', apiUrl)
      setUserLists(response?.data?.data?.usersList || [])
      setPaginationData(response?.data?.data?.pagination || {})
    } catch (error) {
      console.error('Failed to fetch all users', error)
    } finally {
      hideSpinner()
    }
  }

  // const getUserById = async (user) => {
  //   showSpinner()
  //   try {
  //     const apiUrl = apiConfig.GET_USER_BY_ID.replace(':userId', user._id)
  //     const response = await apiService.apiCall('get', apiUrl)
  //     setIsUserSelected(response?.data?.data)
  //   } catch (error) {
  //     console.error('Failed to fetch all users', error)
  //   } finally {
  //     hideSpinner()
  //   }
  // }

  const updateUserById = async (objToSend) => {
    showSpinner()
    try {
      const apiUrl = apiConfig.UPDATE_USER_BY_ID.replace(
        ':userId',
        isUserSelected?._id
      )
      const response = await apiService.apiCall('post', apiUrl, objToSend)

      if (response?.status === 200) {
        await getAllUsers()
        addToast({
          title: response?.data?.msg || USER_INFO.UPDATE_SUCCESS,
          type: 'success',
        })
      } else {
        addToast({
          title: response?.data?.error || USER_INFO.UPDATE_FAILED,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      hideSpinner()
      setIsUserSelected(null)
    }
  }

  const deleteUserById = async (user) => {
    try {
      showSpinner()

      const apiUrl = apiConfig.DELETE_USER_BY_ID.replace(':userId', user._id)
      const response = await apiService.apiCall('delete', apiUrl)

      if (response?.status === 200) {
        await getAllUsers()
        addToast({
          title: response?.data?.msg || USER_INFO.DELETE_SUCCESS,
          type: 'success',
        })
      } else {
        addToast({
          title: response?.data?.error || USER_INFO.DELETE_FAILED,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      setRowToDelete('')
      hideSpinner()
    }
  }

  const handlePageChange = async (newPage) => {
    await getAllUsers(newPage, globalFilter)
  }

  useEffect(() => {
    getAllUsers()
  }, [])

  return (
    <>
      <Card>
        <div className="h-full w-full p-2 md:p-4">
          <div className="flex h-full w-full flex-col items-center justify-between !pt-0 md:flex-row md:py-2">
            {/* Search input */}
            <div className="mb-3 flex w-full items-center rounded-full bg-white p-[5px] shadow-2xl shadow-white dark:!bg-navy-800 dark:shadow-none md:mb-0 md:w-9/12">
              <div className="flex h-9 w-full flex-grow items-center rounded-full border-white bg-lightPrimary text-sm text-gray-600 dark:border dark:!bg-navy-900">
                <FiSearch className="mx-2 h-6 w-4 !text-gray-700 dark:!text-white" />
                <input
                  type="text"
                  placeholder="Search by First Name, Last Name, or Email"
                  className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-full"
                  value={globalFilter}
                  onChange={(e) => setGlobalFilter(e.target.value)}
                />
              </div>
            </div>
          </div>

          {/* Table */}
          <div className="mt-2 min-h-[150px] w-full overflow-x-scroll xl:overflow-hidden">
            <table className="w-full">
              <thead className="w-full">
                <tr className="items-start border-b border-gray-200 dark:!border-white/10">
                  <th className="w-24 px-4 pb-[10px] text-start text-xs text-gray-600">
                    {TABLE_HEADER.SR_NO}
                  </th>
                  <th className="px-4 pb-[10px] text-start text-xs text-gray-600">
                    {TABLE_HEADER.FIRST_NAME}
                  </th>
                  <th className="px-4 pb-[10px] text-start text-xs text-gray-600">
                    {TABLE_HEADER.LAST_NAME}
                  </th>
                  <th className="px-4 pb-[10px] text-start text-xs text-gray-600">
                    {TABLE_HEADER.EMAIL}
                  </th>
                  <th className="px-4 pb-[10px] text-start text-xs text-gray-600">
                    {TABLE_HEADER.IS_EMAIL_VERIFIED}
                  </th>
                  <th className="px-4 pb-[10px] text-start text-xs text-gray-600">
                    {TABLE_HEADER.EDIT}
                  </th>
                  <th className="px-4 pb-[10px] text-start text-xs text-gray-600">
                    {TABLE_HEADER.DELETE}
                  </th>
                </tr>
              </thead>
              <tbody>
                {userLists?.map((row, index) => (
                  <tr
                    className="border-b border-gray-200 dark:!border-white/10"
                    key={index}
                  >
                    <td className="w-24 px-4 text-start font-medium text-navy-700 dark:text-white">
                      {index +
                        ((paginationData?.pageNumber - 1) *
                          paginationData?.limit +
                          1)}
                    </td>
                    <td className="px-4 text-start font-medium text-navy-700 dark:text-white">
                      {row.firstName}
                    </td>
                    <td className="px-4 text-start font-medium text-navy-700 dark:text-white">
                      {row.lastName}
                    </td>
                    <td className="px-4 text-start font-medium text-navy-700 dark:text-white">
                      {row.emailId}
                    </td>
                    <td
                      className="px-4 text-start
                     font-medium text-navy-700 dark:text-white"
                    >
                      {row.isEmailVerified ? (
                        <RxCheck
                          className="font-black text-green-500 dark:text-green-400"
                          size={25}
                        />
                      ) : (
                        <RxCross2
                          className="font-black text-red-500 dark:text-red-400"
                          size={25}
                        />
                      )}
                    </td>
                    <td className="px-4">
                      <div className="flex justify-start text-sm font-medium text-brand-500 underline hover:cursor-pointer dark:text-white">
                        <div className="w-max rounded-xl py-1 text-sm">
                          <TooltipHorizon
                            extra="border border-gray-200 dark:border-gray-700"
                            trigger={
                              <p
                                className="flex cursor-pointer items-start rounded-full border p-1 font-bold text-[#000] hover:font-medium hover:text-black dark:text-white"
                                onClick={() => setIsUserSelected(row)}
                              >
                                <span>
                                  <MdEdit className="text-xl" />
                                </span>
                              </p>
                            }
                            content="Edit"
                            placement="top"
                          />
                        </div>
                      </div>
                    </td>
                    <td className="px-4">
                      <div className="flex justify-start text-sm font-medium text-brand-500 underline hover:cursor-pointer dark:text-white">
                        <div className="w-max rounded-xl py-1 text-sm">
                          <TooltipHorizon
                            extra="border border-gray-200 dark:border-gray-700"
                            trigger={
                              <p
                                className="flex cursor-pointer items-start rounded-full border p-1 font-bold text-[#000] hover:font-medium hover:text-black dark:text-white"
                                onClick={() => setRowToDelete(row)}
                              >
                                <span>
                                  <MdOutlineDeleteForever className="text-xl" />
                                </span>
                              </p>
                            }
                            content="Delete"
                            placement="top"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Display message if no data */}
            {userLists?.length === 0 && (
              <div className="text-md mt-3 flex w-full justify-center text-center text-gray-500 dark:text-gray-400">
                {NO_DATA}
              </div>
            )}

            {userLists?.length > 0 ? (
              <Pagination
                usersData={userLists}
                paginationData={paginationData}
                onPageChange={(newPage) => handlePageChange(newPage)}
              />
            ) : null}
          </div>
        </div>

        {isUserSelected && (
          <ModalForUserDetails
            data={isUserSelected}
            onClose={() => setIsUserSelected(null)}
            onUpdate={(objToSend) => updateUserById(objToSend)}
          />
        )}
        {rowToDelete && (
          <ModalForConfirmDelete
            onCancel={() => setRowToDelete('')}
            onConfirm={() => deleteUserById(rowToDelete)}
            message={USER_INFO.DELETE_MESSAGE_FOR_MODAL}
            title={USER_INFO.DELETE_TITLE_FOR_MODAL}
          />
        )}
      </Card>
    </>
  )
}

export default UserInfo
