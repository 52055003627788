import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import SystemHealth from 'views/systemHealth'

import Card from 'components/card'

const UrlConfigurationInfoComponent = ({ url, title, text, img }) => {
  const navigate = useNavigate()

  const isElastic = localStorage.getItem('isElastic')
  const kibanaUrl = localStorage.getItem('kibanaUrl')
  const isDashboard = localStorage.getItem('isDashboard')

  const isMonitoring = localStorage.getItem('isMonitoring')
  const metricUrl = localStorage.getItem('metricUrl')

  useEffect(() => {
    switch (title) {
      case 'Log Monitoring':
      case 'Tracing for Distributed Systems':
        if (isElastic === 'true' && kibanaUrl) {
          let uri = kibanaUrl + url
          redirectUrl(uri, title)
        }
        break
      case 'Metrics Monitoring':
        if (isElastic === 'true' && kibanaUrl && isDashboard === 'true') {
          let uri = kibanaUrl + url
          redirectUrl(uri, title)
        } else if (
          isMonitoring === 'true' &&
          metricUrl &&
          metricUrl !== 'null'
        ) {
          redirectUrl(metricUrl, title)
        }
        break
      default:
        redirectUrl(url, title)
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url])

  const redirectUrl = (url, title) => {
    if (url) {
      if (title !== 'System Health Monitoring') {
        window.open(url, '_blank')
        navigate(`/`)
      }
    }
  }

  const shouldShowCard = (
    url,
    title,
    isElastic,
    kibanaUrl,
    isDashboard,
    isMonitoring,
    metricUrl
  ) => {
    if (!url) {
      return true
    } else {
      return (
        title !== 'System Health Monitoring' &&
        (isElastic !== 'true' ||
          (kibanaUrl && isDashboard !== 'true') ||
          (isMonitoring !== 'true' && (!metricUrl || metricUrl === '')))
      )
    }
  }

  return (
    <div>
      {shouldShowCard(
        url,
        title,
        isElastic,
        kibanaUrl,
        isDashboard,
        isMonitoring,
        metricUrl
      ) ? (
        <Card extra={'w-full h-full bg-white mt-3 px-4'}>
          <div className="mt-[25px]">
            <p className="px-4 text-xl font-bold text-navy-700 dark:text-white">
              {title}
            </p>
          </div>
          <div className="px-4 pt-2">
            <div className="text-lg">
              <h1>{text}</h1>
            </div>
          </div>
          <div className="align-items-center flex justify-center py-8">
            <img className="w-[700px]" src={img} alt="Url configuration" />
          </div>
        </Card>
      ) : (
        <SystemHealth />
      )}
    </div>
  )
}

export default UrlConfigurationInfoComponent
