import React, { useState, useEffect } from 'react'
import ApiCaller from 'common/services/apiServices'
import apiConfig from 'common/config/apiConfig'
import { useSpinner } from 'common/SpinnerLoader'
import Card from 'components/card'
import { useToaster } from 'common/Toaster'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import appConstants from '../../common/config/appConstants'
import { encryptPassword } from '../../common/commonFunction'

const ResetPassword = () => {
  const apiService = ApiCaller()
  const { TOAST, AUTH, KEYCLOAK_INIT } = appConstants

  const { addToast } = useToaster()
  const { showSpinner, hideSpinner } = useSpinner()
  const navigate = useNavigate()

  const [showOldPassword, setShowOldPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
  })

  useEffect(() => {
    if (localStorage.getItem('toGoogle') || KEYCLOAK_INIT === 'true') {
      navigate('/')
    }
  })

  const onFormSubmit = async (formValue) => {
    if (formValue.oldPassword === formValue.newPassword) {
      addToast({
        title: TOAST.MESSAGES.ERROR.samePasswordsError,
        type: 'error',
      })
      return
    }

    try {
      showSpinner()
      const encryptedOldData = encryptPassword(formValue.oldPassword)
      const encryptedNewData = encryptPassword(formValue.newPassword)

      if (
        !encryptedOldData.encryptedPassword ||
        !encryptedOldData.ivBase64 ||
        !encryptedNewData.encryptedPassword ||
        !encryptedNewData.ivBase64
      ) {
        addToast({
          title: TOAST.MESSAGES.ERROR.swwError,
          type: 'error',
        })
        return
      }

      let payload = {
        oldPassword: encryptedOldData.encryptedPassword,
        newPassword: encryptedNewData.encryptedPassword,
        ivBase64Old: encryptedOldData.ivBase64,
        ivBase64New: encryptedNewData.ivBase64,
      }

      const apiUrl = apiConfig.RESET_PASSWORD
      const response = await apiService.apiCall('post', apiUrl, payload)

      if (response?.data?.code === 200) {
        addToast({
          title: response?.data?.msg,
          type: 'success',
        })
        navigate('/')
      } else {
        addToast({ title: response?.data?.error, type: 'error' })
      }
    } catch (error) {
      console.error('Error resetting password:', error)
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      hideSpinner()
    }
  }

  const newPassword = watch('newPassword', '')

  return (
    <>
      <Card extra="w-full pb-[35px] pt-[3px]">
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <div className="pl-[33px] pr-[28px]">
            {/* old password */}
            <div className="mb-2 mt-[31px]">
              Old Password <span className="text-red-500">*</span>
            </div>
            <div className="relative flex w-full items-center gap-3 rounded-2xl border border-gray-200 outline-none dark:!border-white/10 dark:!bg-navy-800">
              <input
                type={showOldPassword ? 'text' : 'password'}
                id="oldPassword"
                autoComplete="off"
                placeholder="Enter Old Password"
                className="placeholder:text-lightFourth md:text-md h-full w-full rounded-2xl p-3 text-sm text-navy-700 outline-none dark:!bg-navy-800 dark:text-white"
                {...register('oldPassword', {
                  required: AUTH.OLD_PASSWORD_REQUIRED_ERROR,
                })}
              />
              <div
                className="absolute right-4 top-3.5 cursor-pointer"
                onClick={() => setShowOldPassword(!showOldPassword)}
              >
                {showOldPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
              </div>
            </div>
            {errors.oldPassword && (
              <span className="error-message">
                {errors.oldPassword.message}
              </span>
            )}

            {/* new password */}
            <div className="mb-2 mt-[31px]">
              New Password <span className="text-red-500">*</span>
            </div>
            <div className="relative flex w-full items-center gap-3 rounded-2xl border border-gray-200 outline-none dark:!border-white/10 dark:!bg-navy-800">
              <input
                type={showNewPassword ? 'text' : 'password'}
                id="newPassword"
                autoComplete="off"
                placeholder="Enter New Password"
                className="placeholder:text-lightFourth md:text-md h-full w-full rounded-2xl p-3 text-sm text-navy-700 outline-none dark:!bg-navy-800 dark:text-white"
                {...register('newPassword', {
                  required: AUTH.NEW_PASSWORD_REQUIRED_ERROR,
                  pattern: {
                    value: AUTH.PASSWORD_REGEX,
                    message: AUTH.PASSWORD_ERROR,
                  },
                })}
              />
              <div
                className="absolute right-4 top-3.5 cursor-pointer"
                onClick={() => setShowNewPassword(!showNewPassword)}
              >
                {showNewPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
              </div>
            </div>
            {errors.newPassword && (
              <span className="error-message">
                {errors.newPassword.message}
              </span>
            )}

            {/* confirm password */}
            <div className="mb-2 mt-[31px]">
              Confirm Password <span className="text-red-500">*</span>
            </div>
            <div className="relative flex w-full items-center gap-3 rounded-2xl border border-gray-200 outline-none dark:!border-white/10 dark:!bg-navy-800">
              <input
                type={showConfirmPassword ? 'text' : 'password'}
                id="confirmPassword"
                autoComplete="off"
                placeholder="Enter Confirm Password"
                className="placeholder:text-lightFourth md:text-md h-full w-full rounded-2xl p-3 text-sm text-navy-700 outline-none dark:!bg-navy-800 dark:text-white"
                {...register('confirmPassword', {
                  required: AUTH.CONFIRM_PASSWORD_REQUIRED_ERROR,
                  validate: (value) =>
                    value === newPassword || AUTH.CONFIRM_PASSWORD_ERROR,
                })}
              />
              <div
                className="absolute right-4 top-3.5 cursor-pointer"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? (
                  <AiOutlineEyeInvisible />
                ) : (
                  <AiOutlineEye />
                )}
              </div>
            </div>
            {errors.confirmPassword && (
              <span className="error-message">
                {errors.confirmPassword.message}
              </span>
            )}

            {/* cancel and submit button */}
            <div className="mt-7 grid md:flex lg:flex">
              <div className="flex w-full items-center justify-end">
                <button
                  type="button"
                  className="rounded-xl bg-gray-400 px-8 py-2 text-sm font-medium capitalize text-black transition duration-200 hover:bg-gray-600 active:bg-gray-700 dark:bg-gray-400 dark:hover:bg-gray-300 md:ml-2"
                  onClick={() => navigate('/')}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  // disabled={isEnvDisable}
                  className={`rounded-xl bg-blueSecondary px-8 py-2 text-sm font-medium capitalize text-white transition duration-200 dark:bg-brand-400 md:ml-2 `}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      </Card>
    </>
  )
}

export default ResetPassword
