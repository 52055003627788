import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import appConstants from '../config/appConstants'
import { useToaster } from '../../common/Toaster'
import {
  getOrganization,
  handleBillingApiCall,
} from '../../common/commonFunction'
import ApiCaller from '../../common/services/apiServices'
import apiConfig from '../../common/config/apiConfig'

const UserVerify = () => {
  const { TOAST, AUTH } = appConstants
  const navigate = useNavigate()
  const location = useLocation()
  const { addToast } = useToaster()
  const apiService = ApiCaller()

  const [isButtonEnabled, setIsButtonEnabled] = useState(false) // State for button and countdown
  const [countdown, setCountdown] = useState(5) // 5-second countdown
  const [darkMode] = useState(localStorage.darkMode === 'true')
  const checkLicense = async () => {
    try {
      const licenseAPIData = await handleBillingApiCall()
      if (licenseAPIData && licenseAPIData === 'Valid') {
        return true
      } else if (licenseAPIData && licenseAPIData !== 'Valid') {
        navigate('/subscription-expired')
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    }
  }

  const getOrganizationData = async () => {
    try {
      const response = await getOrganization('auth')
      if (response?.data?.code === 200) {
        const responseData = response?.data?.data
        const org_Data = {
          org_name: responseData?.name,
          repo_name: responseData?.repoName,
          branch_name: responseData?.env?.map((env) => env.name),
          id: responseData?._id,
          github_token: responseData?.githubToken,
          cloud_type: responseData?.cloudType,
        }
        localStorage.setItem('organizationData', JSON.stringify(org_Data))
      } else {
        navigate(`/`)
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    }
  }

  const getUserInfo = async () => {
    try {
      const apiUrl = apiConfig.USER_INFO
      const response = await apiService.apiCall('get', apiUrl)

      if (response?.data?.code === 200) {
        const userDetails = response?.data?.data
        localStorage.setItem('userData', JSON.stringify(userDetails))
      }
    } catch (error) {
      console.error('User info error:', error)
    }
  }

  const verifyUser = async () => {
    const queryParams = new URLSearchParams(location.search)
    const success = queryParams.get('success')
    const userId = queryParams.get('userId')

    // Clear verificationSuccess from sessionStorage immediately after retrieval
    if (!success) return // Exit if no success status found

    if (success === 'true') {
      if (userId) {
        try {
          const isLicensed = await checkLicense()
          if (!isLicensed) return

          const apiUrl = apiConfig.GET_AUTH_TOKEN.replace(
            ':verificationId',
            userId
          )
          const response = await apiService.apiCall('get', apiUrl)

          if (response?.status === 200) {
            localStorage.setItem('bearerToken', response?.data?.data) // Store token
            const theme = localStorage.getItem('darkMode') || false
            localStorage.setItem('darkMode', theme)

            await getOrganizationData()
            await getUserInfo()

            addToast({
              title: TOAST.MESSAGES.SUCCESS.signInSuccess,
              type: 'success',
            })
            navigate('/')
          } else {
            addToast({
              title:
                response?.data?.error ||
                'fetchTokenError from else' ||
                TOAST.MESSAGES.ERROR.fetchTokenError,
              type: 'error',
            })
            navigate('/sign-in')
          }
        } catch (error) {
          addToast({
            title:
              'fetchTokenError from catch' ||
              TOAST.MESSAGES.ERROR.fetchTokenError,
            type: 'error',
          })
          navigate('/sign-in')
        }
      } else {
        addToast({
          title: TOAST.MESSAGES.ERROR.userExistButNotVerifiedError,
          type: 'error',
        })
        navigate('/sign-in')
      }
    } else {
      addToast({
        title: TOAST.MESSAGES.ERROR.signInFailedError,
        type: 'error',
      })
      navigate('/sign-in')
    }
  }

  useEffect(() => {
    verifyUser()
  }, [navigate, location.search])

  useEffect(() => {
    // Start countdown timer
    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev === 1) {
          clearInterval(timer) // Stop the timer
          setIsButtonEnabled(true) // Enable the button
        }
        return prev - 1
      })
    }, 1000)

    // Attempt auto-navigation after 5 seconds
    // const autoNavigateTimeout = setTimeout(() => {
    //   if (!isButtonEnabled) {
    //     handleNavigation()
    //   }
    // }, 5000)

    return () => {
      clearInterval(timer) // Cleanup timer on unmount
    }
  }, [location.search])

  useEffect(() => {
    if (darkMode) document.body.classList.add('dark')
    else document.body.classList.remove('dark')
  }, [darkMode])

  const handleNavigation = () => {
    localStorage.removeItem('toGoogle')
    navigate('/sign-in')
  }

  return (
    <div className="flex h-screen w-full flex-col items-center justify-start gap-2 bg-white pt-8 dark:bg-navy-900">
      <p className="text-2xl font-semibold text-navy-700 dark:text-white">
        {AUTH.PLEASE_WAIT_TEXT}
      </p>
      <p className="text-lg text-navy-700 dark:text-white">
        {AUTH.DO_NOT_NAVIGATE_TEXT}
      </p>
      <p className="text-navy-700 dark:text-white">
        If the site doesn't redirect in {countdown} seconds, click the button
        below.
      </p>
      <button
        className={`mt-2 rounded-xl bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
          isButtonEnabled ? 'cursor-pointer' : 'cursor-not-allowed opacity-50'
        }`}
        onClick={handleNavigation}
        disabled={!isButtonEnabled}
      >
        Sign In
      </button>
    </div>
  )
}

export default UserVerify
