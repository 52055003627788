import React, { useState, useEffect } from 'react'
import RadioServiceCard from 'components/radioServiceCard'
import OpenAISvg from 'assets/svg/chatgpt-icon.svg'
import GeminiSvg from 'assets/svg/google-gemini-icon.svg'
import ClaudeSvg from 'assets/svg/claude-ai-icon.svg'
import LlamaSvg from 'assets/svg/meta-icon.svg'
import BedrockSvg from 'assets/svg/bedrock.svg'

const RadioForAIModel = ({ setParentSelectedValue, initialLanguageModel }) => {
  console.log('initialLanguageModel', initialLanguageModel)
  const [selectedValue, setSelectedValue] = useState('')

  const [isDisabled, setIsDisabled] = useState({
    OpenAI: true, // Default values to avoid null errors
    Gemini: true,
    Claude: true,
    Bedrock: true,
    Llama: true,
  })

  const handleRadioChange = (value) => {
    setSelectedValue(value)
    setParentSelectedValue(value)
  }

  useEffect(() => {
    const defaultService = initialLanguageModel || 'OpenAI'
    setSelectedValue(defaultService === 'none' ? 'OpenAI' : defaultService)
    setParentSelectedValue(
      defaultService === 'none' ? 'OpenAI' : defaultService
    )

    const updatedDisabledState =
      defaultService === 'none'
        ? {
            OpenAI: false,
            Gemini: false,
            Claude: false,
            Bedrock: false,
            Llama: false,
          }
        : {
            OpenAI: defaultService !== 'OpenAI',
            Gemini: defaultService !== 'Gemini',
            Claude: defaultService !== 'Claude',
            Bedrock: defaultService !== 'Bedrock',
            Llama: defaultService !== 'Llama',
          }
    setIsDisabled(updatedDisabledState)
  }, [initialLanguageModel])

  const services = [
    {
      value: 'OpenAI',
      imageSrc: OpenAISvg,
      label: 'OpenAI',
    },
    {
      value: 'Gemini',
      imageSrc: GeminiSvg,
      label: 'Gemini',
    },
    {
      value: 'Claude',
      imageSrc: ClaudeSvg,
      label: 'Claude',
    },
    {
      value: 'Bedrock',
      imageSrc: BedrockSvg,
      label: 'Bedrock',
    },
    {
      value: 'Llama',
      imageSrc: LlamaSvg,
      label: 'Llama',
    },
  ]

  return (
    <div className="">
      <p className="text-navy-900 dark:text-white">Choose Your Model</p>
      <div className="col-span-2 my-3 flex flex-wrap md:gap-4 lg:col-span-1">
        {services.map((service) => (
          <RadioServiceCard
            key={service.value}
            isDisabled={isDisabled[service.value]}
            selectedValue={selectedValue}
            value={service.value}
            onRadioChange={handleRadioChange}
            imageSrc={service.imageSrc}
            label={service.label}
          />
        ))}
      </div>
    </div>
  )
}

export default RadioForAIModel
