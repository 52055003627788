import React, { useEffect, useState } from 'react'
import Card from 'components/card'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import appConstants from 'common/config/appConstants'
import { useToaster } from 'common/Toaster'
import { useSpinner } from 'common/SpinnerLoader'
import ListProjects from './components/listProjects'
import { FiRefreshCw } from 'react-icons/fi'

const SystemHealth = () => {
  const apiService = ApiCaller()
  const { TOAST, UPTIME_KUMA_UI } = appConstants
  const { addToast } = useToaster()
  const { showSpinner, hideSpinner } = useSpinner()

  const [errorOccurred, setErrorOccurred] = useState(false)
  const [statusData, setStatusData] = useState([])

  const getStatusPages = async () => {
    try {
      showSpinner()
      const response = await apiService.apiCall(
        'get',
        apiConfig.GET_UPTIME_KUMA_PAGES
      )

      if (response?.data?.code === 200 || response?.data?.code === 204) {
        const uptimeKumaPages = response?.data?.data
        setStatusData(uptimeKumaPages)
        setErrorOccurred(false)
      }
    } catch (error) {
      setErrorOccurred(true)
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      hideSpinner()
    }
  }

  useEffect(() => {
    getStatusPages()
  }, [])

  return (
    <Card extra="w-full h-full bg-white mt-3">
      {errorOccurred ? (
        <div className="flex h-[580px] w-full flex-col items-center justify-center gap-2 text-gray-500 dark:text-gray-400">
          <FiRefreshCw
            onClick={getStatusPages}
            className="inline-block cursor-pointer text-lg transition-transform duration-300 ease-in-out hover:rotate-180"
          />
          <p>{UPTIME_KUMA_UI.REFRESH_DATA}</p>
        </div>
      ) : statusData?.length > 0 ? (
        <div className="h-full w-full p-2 md:px-4">
          <ListProjects statusData={statusData} />
        </div>
      ) : (
        <div className="flex h-[580px] w-full items-center justify-center">
          <p className="text-gray-500 dark:text-gray-400">
            {UPTIME_KUMA_UI.NO_DATA_AVAILABLE}
          </p>
        </div>
      )}
    </Card>
  )
}

export default SystemHealth
