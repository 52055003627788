import appConstants from 'common/config/appConstants'

const { UPTIME_KUMA_UI } = appConstants

const dummyTick = UPTIME_KUMA_UI.DUMMY_TICKS

const emptyTick = UPTIME_KUMA_UI.EMPTY_TICKS

const totalTicks = UPTIME_KUMA_UI.TOTAL_TICKS

function timeAgo(givenDateStr) {
  // Parse the given GMT date string
  const givenDate = new Date(givenDateStr)

  // Convert givenDate to IST by adding 5 hours and 30 minutes
  const ISTOffset = 5 * 60 + 30 // IST is UTC+5:30, so calculate in minutes
  const gmtOffset = givenDate.getTimezoneOffset() // Current offset of the given date in minutes
  const ISTDate = new Date(
    givenDate.getTime() + (ISTOffset + gmtOffset) * 60 * 1000
  ) // Adjust to IST

  const currentDate = new Date()

  // Calculate the time difference in milliseconds
  const timeDifference = currentDate - ISTDate

  // Convert the difference to minutes
  const minutesDifference = Math.floor(timeDifference / (1000 * 60))

  // If the difference is less than 5 minutes, return "now"
  if (minutesDifference < 5) {
    return 'now'
  }

  // Convert the difference to hours
  const hoursDifference = Math.floor(minutesDifference / 60)
  if (hoursDifference === 0) {
    return `${minutesDifference} min ago`
  }

  return `${hoursDifference} hr ago`
}

function formatDate(dateString) {
  // Create a Date object from the input GMT string
  const date = new Date(dateString)

  // Convert the GMT date to IST by adding 5 hours and 30 minutes
  const ISTOffset = 5 * 60 + 30 // IST is UTC+5:30, so calculate in minutes
  const gmtOffset = date.getTimezoneOffset() // Current offset of the date in minutes
  const ISTDate = new Date(date.getTime() + (ISTOffset + gmtOffset) * 60 * 1000) // Adjust to IST

  // Extract year, month, day, hours, minutes, and seconds from ISTDate
  const year = ISTDate.getFullYear()
  const month = String(ISTDate.getMonth() + 1).padStart(2, '0') // Months are zero-indexed
  const day = String(ISTDate.getDate()).padStart(2, '0')
  const hours = String(ISTDate.getHours()).padStart(2, '0')
  const minutes = String(ISTDate.getMinutes()).padStart(2, '0')
  const seconds = String(ISTDate.getSeconds()).padStart(2, '0')

  // Use a string with special character to ensure spacing is preserved
  const formattedDate = `${year}-${month}-${day}  ${hours}:${minutes}:${seconds}`

  // Return the formatted date string in 'YYYY-MM-DD  HH:mm:ss' format
  return formattedDate.replace(/ /g, '\u00A0') // Replace spaces with non-breaking spaces
}

function shouldFetchMoreData(uptimeData) {
  const currentPage = uptimeData?.options?.currentPage || 0
  const screenWidth = window.screen.width

  // Conditions for fetching more data
  if (currentPage < 4) {
    if (currentPage === 3 && screenWidth < 1600) {
      return false // Skip fetching for page 3 on smaller screens
    }
    return true
  }
  return false
}

function processResult(result) {
  if (!result) return []

  return result.map((subArray) => {
    // Check if the length is more than totalTicks
    if (subArray?.data.length > totalTicks) {
      const mainTickArray = subArray?.data.slice(-48)
      return {
        ...subArray,
        data: mainTickArray, // Keep only the last 48 data points
      }
    }

    // Check if the length is less than totalTicks
    if (!subArray?.isActive && subArray?.data?.length < totalTicks) {
      // Get the last real data point's time
      const lastRealDate = new Date(
        subArray?.data[subArray?.data?.length - 1]?.date
      )

      // Fill the remaining data with dummy ticks
      const dummyData = Array(totalTicks - subArray.data.length)
        .fill(0)
        .map((_, index) => {
          const newDate = new Date(lastRealDate)
          newDate.setMinutes(newDate.getMinutes() + 30 * (index + 1))
          return {
            ...dummyTick, // Use the dummyTick object
            date: newDate.toISOString(), // Set the incremented date
          }
        })

      // Return the updated subArray with dummy data added
      return {
        ...subArray,
        data: [...subArray.data, ...dummyData], // Append the dummy data
      }
    } else if (subArray?.isActive && subArray?.data?.length < totalTicks) {
      // Get the first real data point's time
      const firstRealDate = new Date(subArray?.data[0]?.date)

      // Create the required number of dummy ticks and prepend them one by one
      const dummyCount = totalTicks - subArray.data.length

      const updatedData = Array(dummyCount)
        .fill(0)
        .map(() => ({
          ...emptyTick, // Use the emptyTick object
          date: firstRealDate.toISOString(), // Set the decremented date
        }))
        .concat(subArray.data)

      // Return the updated subArray with the new dummy data prepended
      return {
        ...subArray,
        data: updatedData,
      }
    }

    return subArray // Return the original array if length is already sufficient
  })
}

export { timeAgo, formatDate, shouldFetchMoreData, processResult }
