import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

// Custom IAM Component
const IAMRedirectHandler = ({ keycloakUrl }) => {
  const navigate = useNavigate()

  useEffect(() => {
    window.open(keycloakUrl, '_blank') // Open Keycloak URL in a new tab
    navigate('/', { replace: true }) // Navigate to '/' in the same tab
  }, [])

  return null // Return nothing, just handle the redirects
}

export default IAMRedirectHandler
